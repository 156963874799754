import React from "react";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";
import CreateProduct from "../../components/Shop/CreateProduct";

const ShopCreateProduct = () => {
  return (
    <div>
      <div className="fixed ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
        <DashboardHeader />
      </div>
      <div className="flex justify-between w-full">
        <div className="w-[80px] 800px:w-[250px] fixed">
          <DashboardSideBar active={3} />
        </div>
        <div
          className="w-full justify-center flex ml-[250px] bg-white mt-[70px]"
          style={{ width: "calc(100% - 250px)" }}
        >
          <CreateProduct />
        </div>
      </div>
    </div>
  );
};

export default ShopCreateProduct;
