import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import SmoothList from "react-smooth-list";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/styles";
import Loader from "../Layout/Loader";
import { server } from "../../server";
import { toast } from "react-toastify";
import { IoClose, IoTrashOutline } from "react-icons/io5";

const AllCoupons = () => {
  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [coupouns, setCoupouns] = useState([]);
  const [minAmount, setMinAmout] = useState(null);
  const [maxAmount, setMaxAmount] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [value, setValue] = useState(null);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${server}/coupon/get-coupon/${seller._id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setIsLoading(false);
        setCoupouns(res.data.couponCodes);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const handleDelete = async (id) => {
    axios
      .delete(`${server}/coupon/delete-coupon/${id}`, { withCredentials: true })
      .then((res) => {
        toast.success("Coupon code deleted succesfully!");
      });
    window.location.reload();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .post(
        `${server}/coupon/create-coupon-code`,
        {
          name,
          minAmount,
          maxAmount,
          selectedProducts,
          value,
          shopId: seller._id,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Coupon code created successfully!");
        setOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setClosing(false);
    }, 300);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
      flex: 0.4,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            #{params.row.id.slice(0, 8).toUpperCase()}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Coupon Code",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "price",
      headerName: "Percentage",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <IoTrashOutline size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  coupouns &&
    coupouns.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        price: item.value + " %",
        sold: 10,
      });
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-4 bg-white px-5">
          <div className="flex justify-between">
            <h3 className="text-[24px] font-SplineSans pb-10 font-semibold">
              Discounts
            </h3>
            <button
              onClick={() => setOpen(true)}
              className="group relative w-[200px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
            >
              Create Promo Code
            </button>
          </div>
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            style={{ fontFamily: "Inter" }}
          />
          {open && (
            <div
              className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
                closing ? "pointer-events-none" : ""
              }`}
            >
              <div
                className={`side-menu ${closing ? "closed" : ""}`}
                style={{
                  marginTop: "10px",
                  marginRight: "15px",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <div className="w-full flex justify-end p-4">
                  <IoClose
                    size={24}
                    className="cursor-pointer"
                    onClick={closeMenu}
                  />
                </div>
                <h5 className="text-[24px] font-Inter text-left pl-4 font-medium">
                  Create Promo Code
                </h5>

                <form
                  onSubmit={handleSubmit}
                  aria-required={true}
                  className="p-4"
                >
                  <SmoothList delay={300} transitionDuration={600} visible>
                    <div>
                      <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                        Promo Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        required
                        value={name}
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your coupon code name..."
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={400} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Discount Percentage{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="value"
                        value={value}
                        required
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Enter your promo code value..."
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={500} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                        Min Amount
                      </label>
                      <input
                        type="number"
                        name="value"
                        value={minAmount}
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setMinAmout(e.target.value)}
                        placeholder="Enter your promo code min amount..."
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={600} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                        Max Amount
                      </label>
                      <input
                        type="number"
                        name="value"
                        value={maxAmount}
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setMaxAmount(e.target.value)}
                        placeholder="Enter your promo code max amount..."
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={700} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                        Selected Product
                      </label>
                      <select
                        className="w-full mt-2 appearance-none font-Inter block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        value={selectedProducts}
                        onChange={(e) => setSelectedProducts(e.target.value)}
                      >
                        <option
                          value="Choose your selected products"
                          className="font-Inter text-[0.9rem]"
                        >
                          Choose product to apply
                        </option>
                        {products &&
                          products.map((i) => (
                            <option value={i.name} key={i.name}>
                              {i.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </SmoothList>
                  <SmoothList delay={800} transitionDuration={600} visible>
                    <div className="pt-8">
                      <button
                        type="submit"
                        className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter"
                      >
                        Create Code
                      </button>
                    </div>
                  </SmoothList>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AllCoupons;
