import React from "react";
import AdminHeader from "../../components/Admin/Layout/AdminHeader.jsx";
import AdminSideBar from "../../components/Admin/Layout/AdminSideBar.jsx";
import AdminDashboardMain from "../../components/Admin/AdminDashboardMain.jsx";

const AdminDashboardPage = () => {
  return (
    <div>
      <div className="fixed ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
        <AdminHeader />
      </div>

      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[250px] fixed">
          <AdminSideBar active={1} />
        </div>
        <div className="ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
          <AdminDashboardMain />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardPage;
