import React, { useEffect, useState } from "react";
import { AiOutlineLogin, AiOutlineMessage } from "react-icons/ai";
import {
  IoPersonCircleOutline,
  IoPersonCircle,
  IoBagHandle,
  IoBagHandleOutline,
  IoReceipt,
  IoReceiptOutline,
  IoTimer,
  IoTimerOutline,
  IoSpeedometer,
  IoSpeedometerOutline,
  IoLogOut,
  IoLogOutOutline,
  IoIdCard,
  IoIdCardOutline,
  IoChatbubbles,
  IoChatbubblesOutline,
  IoGlobeOutline,
  IoGlobe,
} from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import {
  MdOutlineAdminPanelSettings,
  MdOutlinePassword,
  MdOutlineTrackChanges,
} from "react-icons/md";
import { TbAddressBook } from "react-icons/tb";
import axios from "axios";
import { server } from "../../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Intercom from "@intercom/messenger-js-sdk";

const UserSidebar = ({ active }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [name, setName] = useState(user && user.name);
  const [email, setEmail] = useState(user && user.email);
  const [intercomLoaded, setIntercomLoaded] = useState(false);

  Intercom({
    app_id: "fnjwlwrl",
  });

  // const loadIntercom = async () => {
  //   try {
  //     const response = await axios.get(`${server}/user/intercom-data`, {
  //       withCredentials: true,
  //     });
  //     const { userId, email, name, createdAt, userHash } = response.data;

  //     Intercom("boot", {
  //       app_id: "fnjwlwrl",
  //       user_id: userId,
  //       name: name,
  //       email: email,
  //       created_at: createdAt,
  //       user_hash: userHash,
  //     });
  //     setIntercomLoaded(true);
  //     window.Intercom("show");
  //   } catch (error) {
  //     console.error("Failed to fetch Intercom data", error);
  //   }
  // };

  const logoutHandler = () => {
    axios
      .get(`${server}/user/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload(true);
        navigate("/");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  return (
    <div className="w-full h-[100vh] bg-[#130f0c] top-0 left-0 z-10">
      <div className="h-[80px] flex items-center ml-8">
        <Link to="/dashboard">
          <img
            style={{ height: "35px", paddingLeft: "10px" }}
            src="../lipable-logo-light.png"
            alt="Lipable"
          />
        </Link>
      </div>
      {/* single item */}
      <div className="h-screen flex flex-col justify-between">
        <div>
          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/dashboard" className="w-full flex items-center">
              {active === 1 ? (
                <IoSpeedometer size={20} color={"#FFFFFF"} />
              ) : (
                <IoSpeedometerOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 1 ? "text-[#fff]" : "text-[#787471]"
                }`}
              >
                Dashboard
              </h5>
            </Link>
          </div>

          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/orders" className="w-full flex items-center">
              {active === 2 ? (
                <IoBagHandle size={20} color={"#FFFFFF"} />
              ) : (
                <IoBagHandleOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 2 ? "text-[#FFFFFF]" : "text-[#787471]"
                }`}
              >
                Orders
              </h5>
            </Link>
          </div>

          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/refunds" className="w-full flex items-center">
              {active === 3 ? (
                <IoReceipt size={20} color={"#FFFFFF"} />
              ) : (
                <IoReceiptOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 3 ? "text-[#FFFFFF]" : "text-[#787471]"
                }`}
              >
                Refunds
              </h5>
            </Link>
          </div>

          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/track-orders" className="w-full flex items-center">
              {active === 4 ? (
                <IoTimer size={20} color={"#FFFFFF"} />
              ) : (
                <IoTimerOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 4 ? "text-[#FFFFFF]" : "text-[#787471]"
                }`}
              >
                Track Orders
              </h5>
            </Link>
          </div>

          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/settings" className="w-full flex items-center">
              {active === 5 ? (
                <IoIdCard size={20} color={"#FFFFFF"} />
              ) : (
                <IoIdCardOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 5 ? "text-[#FFFFFF]" : "text-[#787471]"
                }`}
              >
                Profile
              </h5>
            </Link>
          </div>

          <div
            className="w-full flex items-center p-4 ml-6 cursor-pointer"
            onClick={() => window.Intercom("show")}
          >
            {active === 6 ? (
              <IoChatbubbles size={20} color={"#FFFFFF"} />
            ) : (
              <IoChatbubblesOutline size={20} color={"#787471"} />
            )}
            <span
              className={`pl-2 text-[14px] font-[400] font-Inter ${
                active === 6 ? "text-[#fff]" : "text-[#787471]"
              } 800px:block hidden`}
            >
              Support
            </span>
          </div>

          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/" className="w-full flex items-center">
              {active === 7 ? (
                <IoGlobe size={20} color={"#FFFFFF"} />
              ) : (
                <IoGlobeOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 7 ? "text-[#FFFFFF]" : "text-[#787471]"
                }`}
              >
                Go to website
              </h5>
            </Link>
          </div>
        </div>

        <div
          className="w-full flex items-center p-4 ml-6 mb-28 cursor-pointer"
          onClick={logoutHandler}
        >
          {active === 8 ? (
            <IoLogOut size={20} color={"#FFFFFF"} />
          ) : (
            <IoLogOutOutline size={20} color={"#787471"} />
          )}
          <span
            className={`pl-2 text-[14px] font-[400] font-Inter ${
              active === 8 ? "text-[#fff]" : "text-[#787471]"
            } 800px:block hidden`}
          >
            Logout
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserSidebar;
