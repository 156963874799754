import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { formatCurrency } from "../../Utils/formatCurrency";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { backend_url } from "../../server";
import { DataGrid } from "@material-ui/data-grid";

const AllRefundOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const eligibleOrders =
    orders && orders.filter((item) => item.status === "Processing refund");

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      minWidth: 150,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            #{params.row.id.slice(0, 8).toUpperCase()}
          </div>
        );
      },
    },

    // {
    //   field: "user",
    //   headerName: "Customer",
    //   minWidth: 150,
    //   type: "string",
    //   flex: 0.7,
    //   renderCell: (params) => {
    //     return <div className="rowitem">{params.row.user.name}</div>;
    //   },
    // },

    {
      field: "cart",
      headerName: "Product",
      minWidth: 150,
      type: "string",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.cart.map((item, index) => (
              <img
                key={index}
                src={`${backend_url}${item.images[0]}`} // assuming you want to display the first image
                alt={item.name}
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "10px",
                  borderRadius: "8px",
                }}
              />
            ))}
          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.5,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <span
            className={
              status === "Delivered"
                ? "color: text-green-600"
                : "color: text-red-600"
            }
          >
            {status}
          </span>
        );
      },
    },

    {
      field: "paidAt",
      headerName: "Date Purchased",
      type: "string",
      minWidth: 100,
      flex: 0.6,
    },

    {
      field: "itemsQty",
      headerName: "Qty",
      type: "number",
      minWidth: 80,
      flex: 0.3,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 100,
      flex: 0.6,
    },
  ];

  const row = [];

  eligibleOrders &&
    eligibleOrders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: formatCurrency(item.totalPrice),
        status: item.status,
        user: item.user,
        cart: item.cart,
        paidAt: format(parseISO(item.paidAt), "dd MMM yyyy"),
      });
    });

  return (
    <div className="w-full p-8 bg-white pt-[80px]">
      <div className="w-full bg-white">
        <h3 className="text-[24px] font-SplineSans pb-10 font-semibold px-5">
          Refunds
        </h3>
        <div className="w-full min-h-[45vh] bg-white rounded-lg px-5">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            style={{ fontFamily: "Inter" }}
          />
        </div>
      </div>
    </div>
  );
};

export default AllRefundOrders;
