import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Use axios or any other HTTP client
import { brandingData, categoriesData } from "../../../static/data";
import styles from "../../../styles/styles";
import { Image, Shimmer } from "react-shimmer";
import { server } from "../../../server";

const Categories = () => {
  const navigate = useNavigate();
  const [productCounts, setProductCounts] = useState({});

  useEffect(() => {
    // Fetch product counts from the backend
    const fetchProductCounts = async () => {
      try {
        // Adjust the URL to match the correct API endpoint
        const response = await axios.get(`${server}/product/count`);
        setProductCounts(response.data);
      } catch (error) {
        console.error("Error fetching product counts:", error);
      }
    };

    fetchProductCounts();
  }, []);

  return (
    <>
      {/* Categories */}
      <div className={`mt-10 ${styles.section}`}>
        <div className={`${styles.heading}`}>
          <h1 className="font-SplineSans">Categories on Lipable</h1>
        </div>
      </div>

      <div
        className={`${styles.section} bg-white p-6 rounded-md my-12`}
        id="categories"
      >
        <div>
          <div className="grid grid-cols-1 gap-[5px] md:grid-cols-2 md:gap-[10px] lg:grid-cols-4 lg:gap-[20px] xl:grid-cols-5 xl:gap-[30px]">
            {categoriesData &&
              categoriesData.map((i) => {
                const handleSubmit = () => {
                  navigate(`/products?category=${i.title}`);
                };

                // Get the count for the current category
                const count = productCounts[i.title] || 0;

                return (
                  <div className="flex items-center flex-col" key={i.id}>
                    <div
                      className="relative w-[150px] h-[150px] cursor-pointer overflow-hidden font-Inter rounded-full bg-[#f2f0ea]"
                      onClick={() => handleSubmit(i)}
                    >
                      <div className="zoom">
                        <Image
                          src={`${i.image_Url}`}
                          fallback={<Shimmer width={150} height={150} />}
                        />
                      </div>
                    </div>
                    <h5 className="text-[18px] leading-[1.3] font-semibold pt-3 text-[#333333]">
                      {i.title}
                    </h5>
                    <p className="text-[14px] leading-[1.3] font-normal pt-2 text-[#777777]">
                      {count} products
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
