import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  saccos: [],
};

export const saccoReducer = createReducer(initialState, {
  saccoCreateRequest: (state) => {
    state.isLoading = true;
  },
  saccoCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.product = action.payload;
    state.success = true;
  },
  saccoCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  LoadSaccoRequest: (state) => {
    state.isLoading = true;
  },
  LoadSaccoSuccess: (state, action) => {
    state.isSacco = true;
    state.isLoading = false;
    state.sacco = action.payload;
  },
  LoadSaccoFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.isSacco = false;
  },
  // get all saccos ---admin
  getAllSaccosRequest: (state) => {
    state.isLoading = true;
  },
  getAllSaccosSuccess: (state, action) => {
    state.isLoading = false;
    state.saccos = action.payload;
  },
  getAllSaccosFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});

// reducer -> logic (state change)
