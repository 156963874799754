import { useState } from "react";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { toast } from "react-toastify";
import {
  IoTrashOutline,
  IoClose,
  IoCloudUploadOutline,
  IoEyeOutline,
  IoOpenOutline,
  IoStarOutline,
  IoStar,
  IoCloseOutline,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SmoothList from "react-smooth-list";
import { Link } from "react-router-dom";
import {
  getAllProducts,
  getAllProductsShop,
  updateProduct,
} from "../../redux/actions/product";
import { deleteProduct } from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import { formatCurrency } from "../../Utils/formatCurrency";
import styles from "../../styles/styles";
import { createProduct } from "../../redux/actions/product";
import { categoriesData } from "../../static/data";
import { backend_url, server } from "../../server";
import axios from "axios";

const AllProducts = () => {
  const [open, setOpen] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [closing, setClosing] = useState(false);
  const [closingMenu, setClosingMenu] = useState(false);
  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const { success, error } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const { products, isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const [openDelete, setOpenDelete] = useState(false);
  const [productId, setProductId] = useState("");
  const [editProduct, setEditProduct] = useState(false);

  const [editingProductId, setEditingProductId] = useState(null);
  const [editingProductDetails, setEditingProductDetails] = useState({
    name: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteProduct(id));
    window.location.reload();
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product created successfully!");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    e.preventDefault();
    let files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newForm = new FormData();
    images.forEach((image) => {
      newForm.append("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("tags", tags);
    newForm.append("originalPrice", originalPrice);
    newForm.append("discountPrice", discountPrice);
    newForm.append("stock", stock);
    newForm.append("shopId", seller._id);
    dispatch(createProduct(newForm));
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(`${server}/shop/update-seller-info`, {}, { withCredentials: true })
      .then((res) => {
        toast.success("Product info updated succesfully!");
        dispatch(updateProduct());
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setClosing(false);
      setOpenProduct(false);
      setSelectedProduct(null);
    }, 300);
  };

  const CloseEditMenu = () => {
    setClosingMenu(true);
    setTimeout(() => {
      setOpenProduct(false);
      setClosingMenu(false);
      setSelectedProduct(null);
    }, 300);
  };

  const openMenu = (order) => {
    setOpen(true);
  };

  const openMenuEdit = (order) => {
    setSelectedProduct(order);
    setOpenProduct(true);
  };

  const handleEdit = (product) => {
    setEditingProductId(product.id);
    setEditingProductDetails({
      name: product.name /* Initialize other fields as needed */,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingProductDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleUpdateProduct = () => {
    // Dispatch an action to update the product
    // e.g., dispatch(updateProduct(editingProductId, editingProductDetails));
    // After successful update, reset editing states
    setEditingProductId(null);
    setEditingProductDetails({ name: "" /* Reset other fields as needed */ });
  };

  const columns = [
    {
      field: "name",
      headerName: "Product",
      minWidth: 180,
      flex: 1.4,
      renderCell: (params) => {
        const product = params.row;
        const imageUrl =
          product.images && product.images.length > 0
            ? `${backend_url}${product.images[0]}`
            : null;
        return (
          <div className="flex items-center ml-2">
            {imageUrl && (
              <img
                src={imageUrl} // Use the first image URL
                alt={product.name}
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "10px",
                  borderRadius: "8px",
                }}
              />
            )}
            <span>{product.name}</span>
          </div>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 80,
      flex: 0.6,
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 90,
      flex: 0.7,
    },
    {
      field: "stock",
      headerName: "Stock",
      type: "number",
      minWidth: 60,
      flex: 0.4,
    },

    {
      field: "sold",
      headerName: "Sold out",
      type: "number",
      minWidth: 60,
      flex: 0.4,
    },
    {
      field: "Preview",
      flex: 0.4,
      minWidth: 50,
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => openMenuEdit(params.row)}
              className="group relative w-full h-[35px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-[50px] text-gray-700 bg-transparent hover:bg-gray-100 font-Inter"
            >
              <IoOpenOutline size={20} />
            </button>
          </>
        );
      },
    },
    {
      field: " ",
      flex: 0.3,
      minWidth: 30,
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => setProductId(params.id) || setOpenDelete(true)}
              className="group relative w-full h-[35px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-[50px] text-gray-700 bg-transparent hover:bg-gray-100 font-Inter"
            >
              <IoTrashOutline size={20} />
            </button>
          </>
        );
      },
    },
  ];

  const row = [];

  products &&
    products.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        price: formatCurrency(item.discountPrice),
        initialPrice: formatCurrency(item.originalPrice),
        stock: item.stock,
        sold: item.sold_out,
        category: item.category,
        images: item.images,
        description: item.description,
      });
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-4 bg-white font-Inter px-5">
          <div className="flex justify-between">
            <h3 className="text-[24px] font-Inter pb-10 font-semibold">
              Products
            </h3>
            <Link to={`/product/create`}>
              <button className="group relative w-[150px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter">
                Add product
              </button>
            </Link>
          </div>
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            style={{ fontFamily: "Inter" }}
          />
          {openDelete && (
            <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
              <div className="w-[95%] 800px:w-[25%] min-h-[20vh] bg-white rounded-xl shadow p-5">
                <div className="w-full flex justify-end cursor-pointer">
                  <IoCloseOutline
                    size={20}
                    onClick={() => setOpenDelete(false)}
                  />
                </div>
                <h3 className="text-[16px] text-center py-5 font-Inter text-[#000000cb]">
                  Are you sure you wanna delete this product?
                </h3>
                <div className="w-full flex items-center justify-center">
                  <button
                    onClick={() => setOpenDelete(false)}
                    className="group relative w-[150px] h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                  >
                    Cancel
                  </button>
                  <div className="m-1"></div>
                  <button
                    onClick={() =>
                      setOpenDelete(false) || handleDelete(productId)
                    }
                    className="group relative font-Inter w-[170px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#ff0015] hover:bg-[#000]"
                  >
                    Confirm Deletion
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* Add Product */}
          {open && (
            <div
              className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
                closing ? "pointer-events-none" : ""
              }`}
              onClick={closeMenu}
            >
              <div
                className={`side-menu ${closing ? "closed" : ""}`}
                onClick={(e) => e.stopPropagation()}
                style={{
                  marginTop: "10px",
                  marginRight: "15px",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <div className="w-full flex justify-end p-4">
                  <IoClose
                    size={24}
                    className="cursor-pointer"
                    onClick={closeMenu}
                  />
                </div>
                <h5 className="text-[24px] font-Inter text-left pl-4 font-medium">
                  Create Product
                </h5>

                <form onSubmit={handleSubmit} className="p-4">
                  <SmoothList delay={300} transitionDuration={600} visible>
                    <div>
                      <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                        Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your product name..."
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={400} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Description <span className="text-red-500">*</span>
                      </label>
                      <textarea
                        cols="30"
                        required
                        rows="3"
                        type="text"
                        name="description"
                        value={description}
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Enter your product description..."
                      ></textarea>
                    </div>
                  </SmoothList>
                  <SmoothList delay={500} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Category <span className="text-red-500">*</span>
                      </label>
                      <select
                        className="w-full mt-2 appearance-none font-Inter block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option
                          value="Choose a category"
                          className="font-Inter text-[0.9rem]"
                        >
                          Choose a category
                        </option>
                        {categoriesData &&
                          categoriesData.map((i) => (
                            <option value={i.title} key={i.title}>
                              {i.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  </SmoothList>
                  <SmoothList delay={600} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Tags
                      </label>
                      <input
                        type="text"
                        name="tags"
                        value={tags}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setTags(e.target.value)}
                        placeholder="Enter your product tags..."
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={700} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Original Price
                      </label>
                      <input
                        type="number"
                        name="price"
                        value={originalPrice}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setOriginalPrice(e.target.value)}
                        placeholder="Enter your product price..."
                      />
                    </div>
                  </SmoothList>

                  <SmoothList delay={800} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Price (With Discount){" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name="price"
                        value={discountPrice}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setDiscountPrice(e.target.value)}
                        placeholder="Enter your product price with discount..."
                      />
                    </div>
                  </SmoothList>

                  <SmoothList delay={900} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Product Stock <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name="price"
                        value={stock}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setStock(e.target.value)}
                        placeholder="Enter your product stock..."
                      />
                    </div>
                  </SmoothList>

                  <SmoothList delay={1000} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Upload Images <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="file"
                        name=""
                        id="upload"
                        className="hidden"
                        multiple
                        onChange={handleImageChange}
                      />
                      <div className="w-full flex items-center flex-wrap mt-5">
                        <label htmlFor="upload">
                          <div
                            className="p-12 bg-gray-100 rounded-lg cursor-pointer"
                            style={{
                              border: "6px dashed #a99a9a",
                              borderWidth: "1px",
                            }}
                          >
                            <IoCloudUploadOutline
                              size={24}
                              className="cursor-pointer"
                              color="#555"
                            />
                          </div>
                        </label>
                        {images &&
                          images.map((i) => (
                            <img
                              src={URL.createObjectURL(i)}
                              key={i}
                              alt=""
                              className="h-[120px] w-[120px] object-cover m-2"
                            />
                          ))}
                      </div>
                      <br />
                      <div className="pt-10">
                        <button
                          type="submit"
                          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter"
                        >
                          Add product
                        </button>
                      </div>
                    </div>
                  </SmoothList>
                </form>
              </div>
            </div>
          )}
          {/* View Product */}
          {selectedProduct && !editProduct && (
            <div
              className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
                closing ? "pointer-events-none" : ""
              }`}
              onClick={CloseEditMenu}
            >
              <div
                className={`side-menu ${closing ? "closed" : ""}`}
                onClick={(e) => e.stopPropagation()}
                style={{
                  marginTop: "10px",
                  marginRight: "15px",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <div className="w-full py-2">
                  <SmoothList delay={300}>
                    <div className="rounded-lg bg-gray-50 flex flex-col">
                      <div className="flex justify-between items-center px-3 py-4">
                        <h5 className="text-[20px] font-Inter text-left font-medium">
                          Product Details
                        </h5>
                        <span className="rounded-full bg-white p-1">
                          <IoClose
                            size={20}
                            className="cursor-pointer"
                            onClick={closeMenu}
                          />
                        </span>
                      </div>
                      <div className="flex justify-start px-3">
                        {selectedProduct.images.map((i, index) => (
                          <div className="flex items-center mb-5 mr-4">
                            <img
                              src={`${backend_url}${i}`}
                              alt="Product item order img"
                              className="w-[80x] h-[80px] rounded-lg"
                              style={{ border: "1px solid #eeee" }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </SmoothList>
                  <SmoothList delay={400}>
                    <div className="flex justify-between pt-6 px-4 items-start">
                      <h5 className="text-[18px] font-Inter text-left font-medium">
                        {selectedProduct.name}
                      </h5>
                      <div className="flex flex-col justify-end">
                        <h4
                          className={
                            "font-medium text-[18px] text-[#000] font-Inter text-right"
                          }
                        >
                          {selectedProduct.price}
                        </h4>
                        {/* <h3
                        className={
                          "font-[500] text-[14px] text-[#8a8a8a] line-through text-right"
                        }
                      >
                        {selectedProduct.initialPrice
                          ? selectedProduct.initialPrice
                          : null}
                      </h3> */}
                      </div>
                    </div>
                  </SmoothList>
                  <SmoothList delay={500}>
                    <div className="flex justify-start px-4 items-start pt-1 pb-2">
                      <div className="flex items-center">
                        {Array.from({ length: 5 }, (_, index) => (
                          <div key={index}>
                            <IoStar color="orange" />
                          </div>
                        ))}
                        <span className="font-Inter pl-1 text-[16px]">5</span>
                      </div>
                      <span className="font-Inter pl-6 text-[16px]">
                        {selectedProduct.reviews ? selectedProduct.reviews : 0}{" "}
                        Reviews
                      </span>
                    </div>
                  </SmoothList>
                  <SmoothList delay={600}>
                    <div className="flex justify-start px-4 items-start pt-1">
                      <div className="rounded-md bg-gray-100 px-3 py-1 mr-2">
                        <h5 className="text-[14px] font-Inter">
                          {selectedProduct.sold} Sold
                        </h5>
                      </div>
                      <div className="rounded-md bg-gray-100 px-3 py-1">
                        <h5 className="text-[14px] font-Inter">
                          {selectedProduct.stock} Remaining
                        </h5>
                      </div>
                    </div>
                  </SmoothList>
                  <SmoothList delay={700}>
                    <div className="flex justify-start px-4 items-start pt-6 flex-col">
                      <h5 className="text-[16px] font-Inter font-medium">
                        Descriptions
                      </h5>
                      <p className="text-gray-500 font-Inter text-[14px] pt-4">
                        {selectedProduct.description}
                      </p>
                    </div>
                  </SmoothList>
                </div>

                <br />
                <SmoothList delay={800}>
                  <div className="pt-10 px-4 pb-6">
                    <div className="flex">
                      <button className="group relative w-full h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter">
                        Cancel
                      </button>
                      <div className="m-1"></div>
                      <button
                        onClick={() => setEditProduct(true)}
                        className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
                      >
                        Edit product
                      </button>
                    </div>
                  </div>
                </SmoothList>
              </div>
            </div>
          )}
          {/* Edit Product */}
          {editProduct && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-end">
              <div
                className="bg-white w-60 h-full overflow-y-auto side-menu"
                style={{
                  marginTop: "10px",
                  marginRight: "15px",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <div className="w-full flex justify-end p-3">
                  <IoClose
                    size={24}
                    onClick={() => setEditProduct(false)}
                    className="cursor-pointer"
                  />
                </div>
                <h5 className="text-[24px] font-Inter text-left pl-4 font-medium">
                  Update Product
                </h5>
                <form onSubmit={updateHandler} className="p-4">
                  <SmoothList delay={300} transitionDuration={600} visible>
                    <div>
                      <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                        Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={editingProductDetails.name}
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={handleInputChange}
                        placeholder="Product Name"
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={400} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Description <span className="text-red-500">*</span>
                      </label>
                      <textarea
                        cols="30"
                        required
                        rows="3"
                        type="text"
                        name="description"
                        value={description}
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Enter your product description..."
                      ></textarea>
                    </div>
                  </SmoothList>
                  <SmoothList delay={500} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Category <span className="text-red-500">*</span>
                      </label>
                      <select
                        className="w-full mt-2 appearance-none font-Inter block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option
                          value="Choose a category"
                          className="font-Inter text-[0.9rem]"
                        >
                          Choose a category
                        </option>
                        {categoriesData &&
                          categoriesData.map((i) => (
                            <option value={i.title} key={i.title}>
                              {i.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  </SmoothList>
                  <SmoothList delay={600} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Tags
                      </label>
                      <input
                        type="text"
                        name="tags"
                        value={tags}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setTags(e.target.value)}
                        placeholder="Enter your product tags..."
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={700} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Original Price
                      </label>
                      <input
                        type="number"
                        name="price"
                        value={originalPrice}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setOriginalPrice(e.target.value)}
                        placeholder="Enter your product price..."
                      />
                    </div>
                  </SmoothList>

                  <SmoothList delay={800} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Price (With Discount){" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name="price"
                        value={discountPrice}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setDiscountPrice(e.target.value)}
                        placeholder="Enter your product price with discount..."
                      />
                    </div>
                  </SmoothList>

                  <SmoothList delay={900} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Product Stock <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name="price"
                        value={stock}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setStock(e.target.value)}
                        placeholder="Enter your product stock..."
                      />
                    </div>
                  </SmoothList>

                  <SmoothList delay={1000} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Upload Images <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="file"
                        name=""
                        id="upload"
                        className="hidden"
                        multiple
                        onChange={handleImageChange}
                      />
                      <div className="w-full flex items-center flex-wrap mt-5">
                        <label htmlFor="upload">
                          <div
                            className="p-12 bg-gray-100 rounded-lg cursor-pointer"
                            style={{
                              border: "6px dashed #a99a9a",
                              borderWidth: "1px",
                            }}
                          >
                            <IoCloudUploadOutline
                              size={24}
                              className="cursor-pointer"
                              color="#555"
                            />
                          </div>
                        </label>
                        {images &&
                          images.map((i) => (
                            <img
                              src={URL.createObjectURL(i)}
                              key={i}
                              alt=""
                              className="h-[120px] w-[120px] object-cover m-2"
                            />
                          ))}
                      </div>
                      <br />
                      <div className="pt-10">
                        <button
                          type="submit"
                          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter"
                        >
                          Add product
                        </button>
                      </div>
                    </div>
                  </SmoothList>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AllProducts;
