import React from "react";
import SaccoDashboardHeader from "../../components/Sacco/Layout/SaccoDashboardHeader";
import SaccoDashboardSideBar from "../../components/Sacco/Layout/SaccoDashboardSideBar";
import SaccoDashboardHero from "../../components/Sacco/DashboardHero";

const SaccoDashboardPage = () => {
  return (
    <div>
      <div className="fixed ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
        <SaccoDashboardHeader />
      </div>

      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[250px] fixed">
          <SaccoDashboardSideBar active={1} />
        </div>
        <div className="ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
          <SaccoDashboardHero />
        </div>
      </div>
    </div>
  );
};

export default SaccoDashboardPage;
