import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSaccoUsers } from "../../redux/actions/user";
import { toast } from "react-toastify";
import axios from "axios";
import {
  IoCloudDownloadOutline,
  IoDocumentAttachOutline,
  IoEllipsisVerticalSharp,
  IoInformationCircleOutline,
  IoTrashOutline,
  IoClose,
} from "react-icons/io5";
import { backend_url, server } from "../../server";
import { Avatar, IconButton, Menu, MenuItem } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import { RxCross1 } from "react-icons/rx";
import { DataGrid } from "@material-ui/data-grid";
import styles from "../../styles/styles";
import { formatCurrency } from "../../Utils/formatCurrency";
import { Link } from "react-router-dom";
import { MdOutlineModeEdit } from "react-icons/md";
import SmoothList from "react-smooth-list";
import Papa from "papaparse";

const AllMembers = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const { sacco } = useSelector((state) => state.sacco);
  const [closing, setClosing] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    dispatch(getAllSaccoUsers(sacco._id));
  }, [dispatch]);

  const handleDelete = async (id) => {
    await axios
      .delete(`${server}/user/delete-user/${id}`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
      });

    dispatch(getAllSaccoUsers());
  };

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setClosing(false);
      setSelectedUser(null);
    }, 300);
  };

  const openMenu = (user) => {
    setSelectedUser(user);
  };

  const handleDownload = () => {
    // Create CSV content
    const csvContent = `Membership ID,Available Credit\n${users.membershipId},${users.availableCredit}`;

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "template.csv"); // Name of the downloaded file

    // Append the link to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
  };

  const updateMembershipCredits = async (data) => {
    try {
      const response = await axios.post("/api/update-credits", data);
      if (response.status === 200) {
        console.log("Database updated successfully");
      } else {
        console.error("Error updating the database");
      }
    } catch (error) {
      console.error("Error updating credits:", error);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const data = results.data;
          updateMembershipCredits(data);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    }
  };

  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part.charAt(0)).join("");
    return initials.toUpperCase();
  };

  const columns = [
    {
      field: "id",
      headerName: "User ID",
      minWidth: 80,
      flex: 0.4,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            #{params.row.id.slice(0, 8).toUpperCase()}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 120,
      flex: 0.7,
      renderCell: (params) => {
        const user = params.row;
        const avatarUrl = user.avatar ? `${backend_url}${user.avatar}` : null;
        const initials = user.name
          .split(" ")
          .map((n) => n[0])
          .join("");

        return (
          <div className="flex items-center ml-2">
            <Avatar
              src={avatarUrl} // Use the avatar URL if available
              alt={user.name}
              style={{
                width: "34px",
                height: "34px",
                marginRight: "10px",
                borderRadius: "50px",
                backgroundColor: "#dfdfdf",
              }}
            >
              <span className="font-Inter p-2 text-[#000] text-[0.8rem] font-medium">
                {!avatarUrl && initials}
              </span>{" "}
              {/* Display initials if no avatar */}
            </Avatar>
            <span>{user.name}</span>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "membershipId",
      headerName: "Member ID",
      type: "text",
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: "availableCredit",
      headerName: "Credit",
      type: "number",
      minWidth: 80,
      flex: 0.5,
      renderCell: (params) => {
        const value = params.value;
        return (
          <span
            className={
              value > 1 ? "color: text-green-600" : "color: text-green-600"
            }
          >
            {value}
          </span>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.3,
      minWidth: 40,
      headerName: " ",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button onClick={() => setUserId(params.id) || setOpen(true)}>
              <IoTrashOutline size={24} />
            </button>
          </>
        );
      },
    },
    {
      field: " ",
      flex: 0.3,
      minWidth: 60,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => openMenu(params.row)}
              className="group relative w-full h-[35px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-[50px] text-gray-700 bg-transparent hover:bg-gray-100 font-Inter"
            >
              <IoEllipsisVerticalSharp size={16} />
            </button>
          </>
        );
      },
    },
  ];

  const row = [];
  users &&
    users.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        email: item.email,
        role: item.role,
        avatar: item.avatar,
        phoneNumber: item.phoneNumber,
        membershipId: item.membershipId,
        availableCredit: formatCurrency(item.availableCredit),
        joinedAt: format(parseISO(item.createdAt), "dd MMM yyyy"),
      });
    });
  return (
    <div className="w-full pt-[10px] bg-white font-Inter">
      <div className="px-12">
        <div className="flex justify-between">
          <h3 className="text-[24px] font-SplineSans pb-5 font-semibold">
            Members
          </h3>
        </div>
        <div className="w-full min-h-[45vh] bg-white rounded">
          <div className="w-full flex justify-end pb-5">
            <button
              type="button"
              onClick={handleDownload}
              className="group w-[130px] h-[40px] flex justify-center items-center py-2 px-4 border-2 text-sm font-medium rounded-s-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
            >
              <IoCloudDownloadOutline size={16} />{" "}
              <span className="pl-1">Template</span>
            </button>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              style={{ display: "none" }}
              id="file-input"
            />
            <button
              type="button"
              onClick={() => document.getElementById("file-input").click()}
              className="group w-[100px] h-[40px] flex justify-center items-center ml-[-2px] mr-[-2px] py-2 px-4 border-2 text-sm font-medium text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
            >
              <IoDocumentAttachOutline size={16} />
              <span className="pl-1">Import</span>
            </button>
            <button
              type="reset"
              className="group w-[100px] h-[40px] flex justify-center items-center py-2 px-4 border-2 text-sm font-medium rounded-e-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
            >
              <IoDocumentAttachOutline size={16} />{" "}
              <span className="pl-1">Export</span>
            </button>
          </div>
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            checkboxSelection
            autoHeight
            style={{ fontFamily: "Inter", color: "#000" }}
          />
        </div>
        {/* Delete User */}
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[25%] min-h-[20vh] bg-white rounded-xl shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <IoClose size={20} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[16px] text-center py-5 font-Inter text-[#000000cb]">
                Are you sure you wanna delete this user?
              </h3>
              <div className="w-full flex items-center justify-center">
                <button
                  onClick={() => setOpen(false)}
                  className="group relative w-[150px] h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                >
                  Cancel
                </button>
                <div className="m-1"></div>
                <button
                  onClick={() => setOpen(false) || handleDelete(userId)}
                  className="group relative font-Inter w-[170px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#ff0015] hover:bg-[#000]"
                >
                  Confirm Deletion
                </button>
              </div>
            </div>
          </div>
        )}
        {/* User Details */}
        {selectedUser && (
          <div
            className={`fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-end transition-opacity ${
              closing ? "opacity-0" : "opacity-100"
            }`}
            onClick={closeMenu}
          >
            <div
              className="bg-white w-80 h-full p-4 overflow-y-auto side-menu-wide"
              onClick={(e) => e.stopPropagation()}
              style={{
                marginTop: "10px",
                marginRight: "15px",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="w-full flex justify-end p-4">
                <IoClose
                  size={24}
                  className="cursor-pointer"
                  onClick={closeMenu}
                />
              </div>
              <div className="px-5">
                <div
                  className="w-full rounded-xl mt-5"
                  style={{ border: "2px solid #eee" }}
                >
                  <SmoothList delay={300}>
                    <div className="flex items-center justify-between px-4 py-4 rounded-lg bg-gray-50 m-2">
                      <div className="flex items-center">
                        {selectedUser.avatar ? (
                          <img
                            src={`${backend_url}${selectedUser.avatar}`}
                            className="w-[80px] h-[80px] rounded-full object-cover"
                            alt="profile img"
                          />
                        ) : (
                          <Avatar
                            alt={selectedUser.name}
                            style={{
                              width: "80px",
                              height: "80px",
                              marginRight: "10px",
                              borderRadius: "50px",
                              backgroundColor: "#dfdfdf",
                            }}
                          >
                            <span className="font-Inter p-2 text-[#000] text-[1.5rem] font-medium">
                              {getInitials(selectedUser.name)}
                            </span>
                          </Avatar>
                        )}
                        <div className="block ml-6 ">
                          <h5 className="font-Inter font-medium text-[1.2rem]">
                            {selectedUser.name}
                          </h5>
                          <h5 className="font-Inter font-normal text-[0.9rem] text-gray-500 capitalize">
                            {selectedUser.role}
                          </h5>
                        </div>
                      </div>
                      <div className="flex"></div>
                    </div>
                  </SmoothList>

                  <div className="block px-4 py-4 ">
                    <div>
                      <SmoothList delay={400}>
                        <div className="w-4/12">
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-2 text-gray-500">
                            Email address
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.email}
                          </h5>
                        </div>
                      </SmoothList>
                      <SmoothList delay={500}>
                        <div>
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-1 text-gray-500">
                            Phone
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            +254{selectedUser.phoneNumber}
                          </h5>
                        </div>
                      </SmoothList>
                    </div>
                    <div>
                      <SmoothList delay={600}>
                        <div>
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-2 text-gray-500">
                            Availed Credit
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2 text-green-600">
                            {selectedUser.availableCredit}
                          </h5>
                        </div>
                      </SmoothList>
                      <SmoothList delay={600}>
                        <div>
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-2 text-gray-500">
                            Sacco Member ID
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.membershipId
                              ? selectedUser.membershipId
                              : "N/A"}
                          </h5>
                        </div>
                      </SmoothList>
                    </div>
                  </div>
                </div>
                <SmoothList delay={700}>
                  <div className="pt-10 pb-6">
                    <div className="flex">
                      <button
                        onClick={closeMenu}
                        type="reset"
                        className="group relative w-full h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                      >
                        Cancel
                      </button>
                      <div className="m-1"></div>
                      <button
                        type="submit"
                        className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
                      >
                        Edit Member
                      </button>
                    </div>
                  </div>
                </SmoothList>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllMembers;
