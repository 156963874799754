import axios from "axios";
import React, { useEffect, useState } from "react";
import { backend_url, server } from "../../server";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { BsPencil } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";
import { toast } from "react-toastify";
import { formatCurrency } from "../../Utils/formatCurrency";
import { Avatar } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import { AiOutlineEdit } from "react-icons/ai";
import { IoClose } from "react-icons/io5";

const AllWithdraw = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [withdrawData, setWithdrawData] = useState();
  const [withdrawStatus, setWithdrawStatus] = useState("Processing");

  useEffect(() => {
    axios
      .get(`${server}/withdraw/get-all-withdraw-request`, {
        withCredentials: true,
      })
      .then((res) => {
        setData(res.data.withdraws);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 50,
      flex: 0.4,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            #{params.row.id.slice(0, 8).toUpperCase()}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        const seller = params.row;
        const avatarUrl = seller.avatar
          ? `${backend_url}${seller.avatar}`
          : null;
        const initials = seller.name
          .split(" ")
          .map((n) => n[0])
          .join("");

        return (
          <div className="flex items-center ml-2">
            <Avatar
              src={avatarUrl} // Use the avatar URL if available
              alt={seller.name}
              style={{
                width: "34px",
                height: "34px",
                marginRight: "10px",
                borderRadius: "50px",
                backgroundColor: "#dfdfdf",
                padding: "5px",
              }}
            >
              <span className="font-Inter p-2 text-[#000] text-[0.8rem] font-medium">
                {!avatarUrl && initials}
              </span>{" "}
            </Avatar>
            <span>{seller.name}</span>
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "status",
      headerName: "Status",
      type: "text",
      minWidth: 80,
      flex: 0.5,
      renderCell: (params) => {
        return <span className="capitalize">{params.row.status}</span>;
      },
    },
    {
      field: "createdAt",
      headerName: "Request On",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: " ",
      headerName: "Update Status",
      type: "number",
      minWidth: 130,
      flex: 0.6,
      renderCell: (params) => {
        return (
          <AiOutlineEdit
            size={20}
            className={`${
              params.row.status !== "Processing" ? "hidden" : ""
            } mr-5 cursor-pointer`}
            onClick={() => setOpen(true) || setWithdrawData(params.row)}
          />
        );
      },
    },
  ];

  const handleSubmit = async () => {
    await axios
      .put(
        `${server}/withdraw/update-withdraw-request/${withdrawData.id}`,
        {
          sellerId: withdrawData.shopId,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Withdraw request updated successfully!");
        setData(res.data.withdraws);
        setOpen(false);
      });
  };

  const row = [];

  data &&
    data.forEach((item) => {
      row.push({
        id: item._id,
        shopId: item.seller._id,
        name: item.seller.name,
        amount: formatCurrency(item.amount),
        status: item.status,
        avatar: item?.avatar,
        createdAt: format(parseISO(item.createdAt), "dd MMM yyyy"),
      });
    });
  return (
    <div className="w-full px-8 pt-[80px] bg-white font-Inter h-[100vh]">
      <h3 className="text-[24px] font-Inter pb-10 font-semibold">
        Withdrawal Requests
      </h3>
      <div className="bg-white">
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
          style={{ fontFamily: "Inter", color: "#000" }}
        />
      </div>
      {open && (
        <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
          <div className="w-[95%] 800px:w-[25%] min-h-[20vh] bg-white rounded-xl shadow p-5">
            <div className="flex justify-end w-full">
              <IoClose
                size={25}
                onClick={() => setOpen(false)}
                className="cursor-pointer"
              />
            </div>
            <h1 className="text-[20px] text-center font-Inter">
              Update Withdraw Status
            </h1>
            <br />
            <select
              name=""
              id=""
              onChange={(e) => setWithdrawStatus(e.target.value)}
              className="w-full mt-2 appearance-none font-Inter block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
            >
              <option
                className="font-Inter text-[0.9rem]"
                value={withdrawStatus}
              >
                {withdrawData.status}
              </option>
              <option
                className="font-Inter text-[0.9rem]"
                value={withdrawStatus}
              >
                Completed
              </option>
            </select>
            <button
              onClick={handleSubmit}
              className="group relative w-full h-[40px] mt-6 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
            >
              Update
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllWithdraw;
