import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { AiOutlineArrowRight, AiOutlineMoneyCollect } from "react-icons/ai";
import { MdBorderClear } from "react-icons/md";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../../redux/actions/order";
import { getAllUsers } from "../../redux/actions/user";
import Loader from "../Layout/Loader";
import { getAllSellers } from "../../redux/actions/sellers";
import { formatCurrency } from "../../Utils/formatCurrency";
import { format, parseISO } from "date-fns";
import { backend_url } from "../../server";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";

const AdminDashboardMain = () => {
  const dispatch = useDispatch();

  const { adminOrders, adminOrderLoading } = useSelector(
    (state) => state.order
  );
  const { sellers } = useSelector((state) => state.seller);
  const { users } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
    dispatch(getAllSellers());
    dispatch(getAllUsers());
  }, []);

  const adminEarning =
    adminOrders &&
    adminOrders.reduce((acc, item) => acc + item.totalPrice * 0.1, 0);

  const adminBalance = adminEarning?.toFixed(2);

  const sellerEarning =
    adminOrders && adminOrders.reduce((acc, item) => acc + item.totalPrice, 0);

  // const totalEarnings = sellerEarning?.toFixed(2);

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      minWidth: 100,
      flex: 0.4,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            #{params.row.id.slice(0, 8).toUpperCase()}
          </div>
        );
      },
    },

    {
      field: "cart",
      headerName: "Product",
      minWidth: 150,
      type: "string",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.cart.map((item, index) => (
              <img
                key={index}
                src={`${backend_url}${item.images[0]}`} // assuming you want to display the first image
                alt={item.name}
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "10px",
                  borderRadius: "8px",
                }}
              />
            ))}
          </div>
        );
      },
    },

    {
      field: "user",
      headerName: "Customer",
      minWidth: 120,
      type: "string",
      flex: 0.6,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.user.name}</div>;
      },
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 0.4,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <span
            className={
              status === "Delivered"
                ? "color: text-green-600"
                : "color: text-red-600"
            }
          >
            {status}
          </span>
        );
      },
    },
    {
      field: "itemsQty",
      headerName: "Qty",
      type: "number",
      minWidth: 90,
      flex: 0.3,
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      type: "number",
      minWidth: 130,
      flex: 0.5,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 100,
      flex: 0.6,
    },
  ];

  const row = [];
  adminOrders &&
    adminOrders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item?.cart?.reduce((acc, item) => acc + item.qty, 0),
        cart: item.cart,
        user: item.user,
        total: formatCurrency(item?.totalPrice),
        status: item?.status,
        createdAt: format(parseISO(item?.createdAt), "dd MMM yyyy"),
      });
    });

  return (
    <>
      {adminOrderLoading ? (
        <Loader />
      ) : (
        <div className="w-full p-8 bg-white pt-[80px]">
          <h3 className="text-[24px] font-Inter pb-10 font-semibold">
            Overview
          </h3>
          <div className="w-full block 800px:flex items-center justify-between">
            <div className="w-full mb-4 800px:w-[24%] min-h-[20vh] bg-gray-200 rounded-lg px-2 py-5">
              <h2 className="pt-2 pl-[30px] text-[24px] font-[600] font-Inter">
                {formatCurrency(adminBalance)}
              </h2>
              <div className="block items-center pl-[30px] pt-2">
                <h5
                  className={`${styles.productTitle} !text-[16px] leading-5 !font-[400] text-[#00000085] font-Inter`}
                >
                  Total Earning{" "}
                </h5>
                <span className="text-[12px] font-Inter text-[#5a5a5a]">
                  from{" "}
                  <span className="text-[#000] font-semibold">
                    {formatCurrency(sellerEarning)}
                  </span>{" "}
                  of Sales
                </span>
              </div>
            </div>

            <div className="w-full mb-4 800px:w-[24%] min-h-[20vh] bg-gray-200 rounded-lg px-2 py-5">
              <h2 className="pt-2 pl-[30px] text-[24px] font-[600] font-Inter">
                {sellers && sellers.length}
              </h2>
              <div className="block items-center pl-[30px] pt-2">
                <h5
                  className={`${styles.productTitle} !text-[16px] leading-5 !font-[400] text-[#00000085] font-Inter`}
                >
                  All Merchants
                </h5>
              </div>
            </div>

            <div className="w-full mb-4 800px:w-[24%] min-h-[20vh] bg-gray-200 rounded-lg px-2 py-5">
              <h2 className="pt-2 pl-[30px] text-[24px] font-[600] font-Inter">
                {users && users.length}
              </h2>
              <div className="block items-center pl-[30px] pt-2">
                <h5
                  className={`${styles.productTitle} !text-[16px] leading-5 !font-[400] text-[#00000085] font-Inter`}
                >
                  All Customers
                </h5>
              </div>
            </div>

            <div className="w-full mb-4 800px:w-[24%] min-h-[20vh] bg-gray-200 rounded-lg px-2 py-5">
              <h2 className="pt-2 pl-[30px] text-[24px] font-[600] font-Inter">
                {adminOrders && adminOrders.length}
              </h2>
              <div className="block items-center pl-[30px] pt-2">
                <h5
                  className={`${styles.productTitle} !text-[16px] leading-5 !font-[400] text-[#00000085] font-Inter`}
                >
                  All Orders
                </h5>
              </div>
            </div>
          </div>

          <br />
          <div className="w-full min-h-[45vh] bg-white rounded">
            <DataGrid
              rows={row}
              columns={columns}
              pageSize={5}
              disableSelectionOnClick
              autoHeight
              style={{ fontFamily: "Inter" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AdminDashboardMain;
