import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { BsCartPlus } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { removeFromWishlist } from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { backend_url } from "../../server";
import { formatCurrency } from "../../Utils/formatCurrency";
import { IoTrashOutline } from "react-icons/io5";

const Wishlist = () => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  const removeFromWishlistHandler = (data) => {
    dispatch(removeFromWishlist(data));
  };

  const addToCartHandler = (data) => {
    const newData = { ...data, qty: 1 };
    dispatch(addTocart(newData));
  };

  return (
    <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] 1000px:w-[74%] block 800px:flex">
        {wishlist && wishlist.length === 0 ? (
          <div className="w-full flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-3 right-3"></div>
            <div className="flex flex-col items-center">
              <img src="../shopping-basket.svg" className="h-[250px]" />
              <h5 className="font-Inter font-medium mt-2">Wishlist is empty</h5>
              <Link to="/products">
                <button className="group mt-3 relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter">
                  Continue Shopping
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <>
            <div className="w-full">
              <div className="flex w-full justify-end pt-5 pr-5 "></div>
              {/* item length */}
              <div className={`${styles.noramlFlex}`}>
                <h3 className="pl-2 text-[30px] font-[500] font-Inter">
                  Wishlist
                </h3>
              </div>

              {/* Cart Single item */}
              <br />
              <div className="w-full border-t">
                {wishlist &&
                  wishlist.map((i, index) => {
                    return (
                      <CartSingle
                        data={i}
                        key={index}
                        removeFromWishlistHandler={removeFromWishlistHandler}
                        addToCartHandler={addToCartHandler}
                      />
                    );
                  })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, removeFromWishlistHandler, addToCartHandler }) => {
  const [value, setValue] = useState(1);
  const totalPrice = data.discountPrice * value;

  return (
    <>
      <div className="border-b p-4">
        <div className="flex justify-between items-center">
          <div className="w-full 800px:flex items-center">
            <div className="flex items-center w-[60%]">
              <img
                src={`${backend_url}${data?.images[0]}`}
                className="w-[130px] h-min ml-2 mr-2 rounded-xl overflow-hidden object-cover bg-[#f2f0ea]"
                alt="side card"
              />

              <div className="pl-[15px]">
                <h1 className="font-Inter">{data.name}</h1>

                <h4 className="font-[600] pt-3 800px:pt-[3px] text-[17px] text-[#000] font-Inter">
                  {formatCurrency(totalPrice)}
                </h4>
              </div>
            </div>

            <div className="px-5 flex w-[40%] justify-end">
              {/* Check out btn */}
              <button
                className="group relative w-full h-[45px] flex justify-center py-2 px-7 border border-transparent text-[16px] font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter"
                onClick={() => addToCartHandler(data)}
              >
                Add to Cart
              </button>
              <button
                className="group relative w-full h-[45px] ml-2 flex justify-center py-2 px-7 border-2 text-[16px] font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                onClick={() => removeFromWishlistHandler(data)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wishlist;
