import React from "react";
import { AiOutlineGift } from "react-icons/ai";
import {
  IoGiftOutline,
  IoBagHandleOutline,
  IoFileTrayFullOutline,
} from "react-icons/io5";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { backend_url } from "../../../server";

const SaccoDashboardHeader = () => {
  const { sacco } = useSelector((state) => state.sacco);
  return (
    <div className="w-full h-[80px] bg-white sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div></div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          {/* <Link to="/dashboard-messages" className="800px:block hidden">
            <BiMessageSquareDetail
              color="#555"
              size={30}
              className="mx-5 cursor-pointer"
            />
          </Link> */}
          <div className="flex items-center bg-[#fbf7ed] py-2 px-3 rounded-[50px]">
            <img
              src={`${backend_url}${sacco.avatar}`}
              alt=""
              className="w-[30px] h-[30px] rounded-full object-cover mr-2"
            />
            <span className="font-Inter">{sacco.name}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaccoDashboardHeader;
