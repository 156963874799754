import React from "react";
import styles from "../../styles/styles";

const CheckoutSteps = ({ active }) => {
  console.log(active);
  return (
    <div className="w-full flex justify-center">
      <div className="w-[90%] 800px:w-[50%] flex items-center flex-wrap">
        <div className={`${styles.noramlFlex}`}>
          <div
            className={`p-[14px] h-[34px] rounded-[50px] bg-[#000] flex items-center justify-center cursor-pointer`}
          >
            <span className={`text-[#fff] text-[12px] font-[600] font-Inter`}>
              1
            </span>
          </div>
          <div
            className={`${
              active > 1
                ? "w-[30px] 800px:w-[150px] h-[1px] !bg-[#000]"
                : "w-[30px] 800px:w-[150px] h-[1px] !bg-[#d1d1d1]"
            }`}
          />
        </div>

        <div className={`${styles.noramlFlex}`}>
          <div
            className={`${
              active > 1
                ? `p-[14px] h-[34px] rounded-[50px] bg-[#000] flex items-center justify-center cursor-pointer`
                : `p-[14px] h-[34px] rounded-[50px] flex items-center justify-center cursor-pointer !bg-[#ec00ff]`
            }`}
          >
            <span
              className={`${
                active > 1
                  ? `text-[#fff] text-[12px] font-[600]`
                  : `text-[12px] font-[600] !text-[#000]`
              } font-Inter`}
            >
              2
            </span>
          </div>
        </div>

        <div className={`${styles.noramlFlex}`}>
          <div
            className={`${
              active > 3
                ? "w-[30px] 800px:w-[150px] h-[1px] !bg-[#000]"
                : "w-[30px] 800px:w-[150px] h-[1px] !bg-[#d1d1d1]"
            }`}
          />
          <div
            className={`${
              active > 2
                ? `p-[14px] h-[34px] rounded-[50px] bg-[#000] flex items-center justify-center cursor-pointer`
                : `p-[14px] h-[34px] rounded-[50px] flex items-center justify-center cursor-pointer !bg-[#ec00ff]`
            }`}
          >
            <span
              className={`${
                active > 2
                  ? `text-[#fff] text-[12px] font-[600]`
                  : `text-[12px] font-[600] !text-[#000]`
              } font-Inter`}
            >
              3
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSteps;
