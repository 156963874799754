import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { productData } from "../../static/data";
import styles from "../../styles/styles";
import ProductCard from "../Route/ProductCard/ProductCard";

const SuggestedProduct = ({ data }) => {
  const [products, setProducts] = useState([]);
  const { allProducts } = useSelector((state) => state.products);
  const [productData, setProductData] = useState();

  // Proudect is filter when the cataegory is same as the current product when page is loaded
  useEffect(() => {
    const d =
      allProducts && allProducts.filter((i) => i.category === data.category);
    setProductData(d);
  }, []);

  return (
    <div className="w-full bg-white">
      {data ? (
        <div className={`p-4 w-11/12 mx-auto mb-10`}>
          <h2
            className={`text-[27px] text-center md:text-start font-[600] font-SplineSans mb-1`}
          >
            Related Products
          </h2>
          <p className="font-Inter font-light mb-6">
            We have picked out a selection of products that might interest you.{" "}
          </p>
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-4 xl:gap-[30px] mb-12">
            {productData &&
              productData.map((i, index) => (
                <ProductCard data={i} key={index} />
              ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SuggestedProduct;
