import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { server } from "../server";
import axios from "axios";

const ActivationPage = () => {
  const { activation_token } = useParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (activation_token) {
      const activationEmail = async () => {
        try {
          const res = await axios.post(`${server}/user/activation`, {
            activation_token,
          });
          console.log(res.data.message);
        } catch (err) {
          console.log(err.response.data.message);
          setError(true);
        }
      };
      activationEmail();
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {error ? (
        <div>
          <p className="text-red-800 font-Inter">Your token has expired </p>
          <div className="mt-2">
            <Link to={"/sign-up"}>
              <button className="group relative w-full h-[45px] flex justify-center py-2 px-7 border border-transparent text-[16px] font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter">
                Sign up again
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-green-800 font-Inter">
            Your Account has been created successfully!
          </p>
          <div className="mt-2">
            <Link to={"/login"}>
              <button className="group relative w-full h-[45px] flex justify-center py-2 px-7 border border-transparent text-[16px] font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter">
                Login here
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivationPage;
