import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import {
  IoBagHandleOutline,
  IoClose,
  IoCloseOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { backend_url } from "../../server";
import { addTocart, removeFromCart } from "../../redux/actions/cart";
import { formatCurrency } from "../../Utils/formatCurrency";

const Cart = () => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  //remove from cart
  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  // Total price
  const totalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  return (
    <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] 1000px:w-[74%] block 800px:flex">
        <div className="w-full">
          {cart && cart.length == 0 ? (
            <div className="w-full flex items-center justify-center">
              <div className="py-5">
                <div className="flex flex-col items-center">
                  <img src="../empty-cart.svg" className="h-[200px]" />
                  <h5 className="font-Inter font-medium mt-2">Cart is empty</h5>
                  <Link to="/products">
                    <button className="group mt-3 relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter">
                      Continue Shopping
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="w-full 800px:w-[100%]">
                <div className="flex w-full justify-end pt-5 ">
                  {/* <IoClose
                  size={22}
                  className="cursor-pointer"
                  onClick={() => setOpenCart(false)}
                /> */}
                </div>
                {/* item length */}
                <div className={`${styles.noramlFlex}`}>
                  <h3 className="pl-2 text-[30px] font-[500] font-Inter">
                    Cart
                  </h3>
                </div>

                {/* Cart Single item */}
                <br />
                <div className="w-full">
                  <div className="flex justify-between py-4 px-2 mr-5 ml-5 border-b-2 mb-3">
                    <span className="font-Inter w-[40%] text-left">
                      Product
                    </span>
                    <span className="font-Inter w-[20%] text-center">
                      Quantity
                    </span>
                    <span className="font-Inter w-[30%] text-center">
                      Price
                    </span>
                    <span className="font-Inter w-[10%] text-right">
                      Remove
                    </span>
                  </div>
                  {cart &&
                    cart.map((i, index) => {
                      return (
                        <CartSingle
                          data={i}
                          key={index}
                          quantityChangeHandler={quantityChangeHandler}
                          removeFromCartHandler={removeFromCartHandler}
                        />
                      );
                    })}
                </div>
              </div>

              <div className="px-5 mb-3 mt-6 flex justify-end">
                {/* Check out btn */}
                <Link to="/checkout" className="mr-2">
                  <button className="group relative w-full h-[45px] flex justify-center py-2 px-7 border border-transparent text-[16px] font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter">
                    Checkout {formatCurrency(totalPrice)}
                  </button>
                </Link>
                <Link to="/products">
                  <button className="group relative w-full h-[45px] flex justify-center py-2 px-7 border-2 text-[16px] font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter">
                    Continue shopping
                  </button>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {
  const [value, setValue] = useState(data.qty);
  const totalPrice = data.discountPrice * value;

  const increment = (data) => {
    if (data.stock < value) {
      toast.error("Product stock limited!");
    } else {
      setValue(value + 1);
      /* The line `const updateCartData = { ...data, qty: value + 1 };` is creating a new object
            `updateCartData` by spreading the properties of the `data` object and adding a new
            property `qty` with the value of `value + 1`. This is typically used to update the
            quantity of an item in the cart. */
      const updateCartData = { ...data, qty: value + 1 };
      quantityChangeHandler(updateCartData);
    }
  };

  // Decrement
  const decrement = (data) => {
    setValue(value === 1 ? 1 : value - 1);
    const updateCartData = { ...data, qty: value === 1 ? 1 : value - 1 };
    quantityChangeHandler(updateCartData);
  };

  return (
    <>
      <div className="border-b p-4">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between items-center w-[40%]">
            <div className="flex items-center mr-10">
              <img
                src={`${backend_url}${data?.images[0]}`}
                className="w-[130px] h-min ml-2 mr-2 rounded-xl overflow-hidden object-cover bg-[#f2f0ea]"
                alt="side card"
              />

              <div className="pl-[15px]">
                <h1 className="font-Inter">{data.name}</h1>
                <h4 className="font-[400] text-[15px] text-[#00000082] font-Inter">
                  {" "}
                  {formatCurrency(data.discountPrice)} x {value}
                </h4>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-[20%]">
            <div className="flex items-center">
              <button
                className=" text-gray-500 font-bold px-4 py-2 hover:opacity-75 transition duration-300 ease-in-out rounded-md"
                onClick={() => decrement(data)}
                style={{ border: "2px solid #eee" }}
              >
                -
              </button>
              <span className="font-Inter px-4">{data.qty}</span>

              <button
                className="text-gray-500 font-bold px-4 py-2 hover:opacity-75 transition duration-300 ease-in-out rounded-md"
                onClick={() => increment(data)}
                style={{ border: "2px solid #eee" }}
              >
                +
              </button>
            </div>
          </div>
          <div className="w-[30%]">
            <h4 className="font-[600] text-[17px] font-Inter text-[#000] text-center pr-6 ">
              {formatCurrency(totalPrice)}
            </h4>
          </div>
          <div
            className="flex cursor-pointer items-center rounded-lg bg-[#f2f0ea] px-2 w-[10%] "
            onClick={() => removeFromCartHandler(data)}
          >
            <IoTrashOutline
              size={14}
              color="#000"
              className="cursor-pointer mr-2"
            />
            <span className="font-Inter">Remove</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
