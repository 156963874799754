import React from "react";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";
import AllCoupons from "../../components/Shop/AllCoupons";

const ShopAllCoupouns = () => {
  return (
    <div>
      <div className="fixed ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
        <DashboardHeader />
      </div>
      <div className="flex justify-between w-full">
        <div className="w-[80px] 800px:w-[250px] fixed">
          <DashboardSideBar active={9} />
        </div>
        <div
          className="w-full justify-center flex ml-[250px] bg-white mt-[70px]"
          style={{ width: "calc(100% - 250px)" }}
        >
          <AllCoupons />
        </div>
      </div>
    </div>
  );
};

export default ShopAllCoupouns;
