import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { backend_url } from "../../../server";
import { Avatar } from "@material-ui/core";

const UserHeader = () => {
  const { user } = useSelector((state) => state.user);

  // Function to get initials from the user's name
  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials.toUpperCase();
  };

  return (
    <div className="w-full h-[80px] bg-white sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div></div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          {/* <Link to="/dashboard-messages" className="800px:block hidden">
            <BiMessageSquareDetail
              color="#555"
              size={30}
              className="mx-5 cursor-pointer"
            />
          </Link> */}
          <div className="flex items-center py-2 px-3 rounded-[8px] mr-2">
            {user && user?.role === "Admin" && (
              <Link to="/admin/dashboard">
                <button className="group relative w-[200px] h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter">
                  Admin Dashboard
                </button>
              </Link>
            )}
          </div>
          <div className="flex items-center bg-[#fbf7ed] py-2 px-3 rounded-[50px]">
            {user?.avatar ? (
              <img
                src={`${backend_url}${user?.avatar}`}
                alt=""
                className="w-[30px] h-[30px] rounded-full mr-2"
              />
            ) : (
              <Avatar
                alt={user?.name}
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "10px",
                  borderRadius: "50px",
                  backgroundColor: "#dfdfdf",
                }}
              >
                <span className="font-Inter p-2 text-[#000] text-[0.8rem] font-medium">
                  {getInitials(user?.name)}
                </span>
              </Avatar>
            )}
            <span className="font-Inter">{user?.name}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserHeader;
