import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrdersOfSacco,
  getAllOrdersOfUser,
} from "../../redux/actions/order";
import { useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Avatar, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { formatCurrency } from "../../Utils/formatCurrency";
import { DataGrid } from "@material-ui/data-grid";
import styles from "../../styles/styles";
import { backend_url } from "../../server";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { getAllSaccoUsers } from "../../redux/actions/user";

const SaccoDashboardHero = () => {
  const dispatch = useDispatch();
  const { sacco } = useSelector((state) => state.sacco);
  const { orders } = useSelector((state) => state.order);
  const { users } = useSelector((state) => state.user);
  const [totalAvailableCredit, setTotalAvailableCredit] = useState(0);

  // useEffect(() => {
  //   dispatch(getAllOrdersOfUser());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getAllOrdersOfSacco(sacco._id));
    dispatch(getAllSaccoUsers(sacco._id));
  }, [dispatch]);

  useEffect(() => {
    if (users && users.length > 0) {
      const total = users.reduce(
        (acc, order) => acc + (order.availableCredit || 0),
        0
      );
      setTotalAvailableCredit(total);
    }
  }, [users]);

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      minWidth: 120,
      flex: 0.6,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            #{params.row.id.slice(0, 8).toUpperCase()}
          </div>
        );
      },
    },
    {
      field: "user",
      headerName: "Member",
      minWidth: 120,
      type: "string",
      flex: 0.6,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.user.name}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.getValue(params.id, "status") === "Delivered"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "cart",
      headerName: "Product",
      minWidth: 150,
      type: "string",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.cart.map((item, index) => (
              <img
                key={index}
                src={`${backend_url}${item.images[0]}`} // assuming you want to display the first image
                alt={item.name}
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "10px",
                  borderRadius: "8px",
                }}
              />
            ))}
          </div>
        );
      },
    },
    {
      field: "itemsQty",
      headerName: "Qty",
      type: "number",
      minWidth: 40,
      flex: 0.4,
    },
    {
      field: "date",
      headerName: "Date",
      type: "number",
      minWidth: 60,
      flex: 0.6,
    },
    {
      field: "paymentInfo",
      headerName: "Credit Used",
      type: "number",
      minWidth: 100,
      flex: 0.6,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            {formatCurrency(params.row.paymentInfo.creditUsed)}
          </div>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        user: item.user,
        cart: item.cart,
        itemsQty: item.cart.reduce((acc, item) => acc + item.qty, 0),
        total: formatCurrency(item.totalPrice),
        status: item.status,
        date: format(parseISO(item.createdAt), "dd MMM yyyy"),
        paymentInfo: item.paymentInfo,
      });
    });

  return (
    <div className="w-full px-12 bg-white pt-[80px]">
      <h3 className="text-[24px] font-SplineSans pb-10 font-semibold">
        Dashboard
      </h3>
      <div className="w-full block 800px:flex items-center justify-start">
        <div
          className="w-full mb-4 800px:w-[24%] min-h-[20vh] bg-white rounded-lg px-2 py-5 mr-5"
          style={{ border: "1px solid #eee" }}
        >
          <h2 className="pt-4 pl-[20px] text-[24px] font-[600] font-Inter">
            {formatCurrency(totalAvailableCredit)}
          </h2>
          <div className="block items-center pl-[20px] pt-2">
            <h5
              className={`${styles.productTitle} !text-[14px] leading-5 !font-[500] text-[#00000085] font-Inter`}
            >
              Available Members Credit{" "}
            </h5>
          </div>
        </div>

        <div className="w-full mb-4 800px:w-[24%] min-h-[20vh] bg-blue-50 rounded-lg px-2 py-5 mr-5">
          <h2 className="pt-4 pl-[20px] text-[24px] font-[600] font-Inter">
            {orders && orders.length}
          </h2>
          <div className="block items-center pl-[20px] pt-2">
            <h5
              className={`${styles.productTitle} !text-[14px] leading-5 !font-[500] text-[#00000085] font-Inter`}
            >
              All Orders
            </h5>
          </div>
        </div>

        <div className="w-full mb-4 800px:w-[24%] min-h-[20vh] bg-blue-50 rounded-lg px-2 py-5 mr-5">
          <h2 className="pt-4 pl-[20px] text-[24px] font-[600] font-Inter">
            {users && users.length}
          </h2>
          <div className="block items-center pl-[20px] pt-2">
            <h5
              className={`${styles.productTitle} !text-[14px] leading-5 !font-[500] text-[#00000085] font-Inter`}
            >
              Members
            </h5>
          </div>
        </div>
      </div>
      <br />
      <div className="w-full min-h-[45vh] bg-white rounded-lg">
        <div className="w-full flex justify-end pb-5">
          <Link to={"/sacco/orders"}>
            <span className="font-Inter font-medium pr-4">View All</span>
          </Link>
        </div>
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
          style={{ fontFamily: "Inter" }}
        />
      </div>
    </div>
  );
};

export default SaccoDashboardHero;
