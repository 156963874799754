import React from "react";
import AdminHeader from "../../components/Admin/Layout/AdminHeader";
import AdminSideBar from "../../components/Admin/Layout/AdminSideBar";
import AllSaccos from "../../components/Admin/AllSaccos";

const AdminDashboardSaccos = () => {
  return (
    <div>
      <div className="fixed ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
        <AdminHeader />
      </div>

      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[250px] fixed">
          <AdminSideBar active={6} />
        </div>
        <div className="ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
          <AllSaccos />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardSaccos;
