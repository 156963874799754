import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { getAllSellers } from "../../redux/actions/sellers";
import { getAllProducts } from "../../redux/actions/product";
import { deleteProduct } from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import axios from "axios";
import { server } from "../../server";
import { useState } from "react";
import { formatCurrency } from "../../Utils/formatCurrency";
import { backend_url } from "../../server";
import { IoOpenOutline } from "react-icons/io5";

const AllProducts = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { sellers } = useSelector((state) => state.seller);
  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [closing, setClosing] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllSellers());
  }, []);

  useEffect(() => {
    axios
      .get(`${server}/product/admin-all-products`, { withCredentials: true })
      .then((res) => {
        setData(res.data.products);
      });
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Product",
      minWidth: 180,
      flex: 1.4,
      renderCell: (params) => {
        const product = params.row;
        const imageUrl =
          product.images && product.images.length > 0
            ? `${backend_url}${product.images[0]}`
            : null;
        return (
          <div className="flex items-center ml-2">
            {imageUrl && (
              <img
                src={imageUrl} // Use the first image URL
                alt={product.name}
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "10px",
                  borderRadius: "8px",
                }}
              />
            )}
            <span>{product.name}</span>
          </div>
        );
      },
    },

    {
      field: "category",
      headerName: "Category",
      type: "string",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "shop",
      headerName: "Merchant",
      type: "string",
      minWidth: 130,
      flex: 0.7,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.shop.name}</div>;
      },
    },
    {
      field: "Stock",
      headerName: "Stock",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },
    {
      field: "sold",
      headerName: "Sold",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      minWidth: 100,
      flex: 0.6,
    },
  ];

  const row = [];

  data &&
    data.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        price: formatCurrency(item.discountPrice),
        initialPrice: formatCurrency(item.originalPrice),
        stock: item.stock,
        sold: item?.sold_out,
        category: item.category,
        images: item.images,
        description: item.description,
      });
    });

  // Search Function
  useEffect(() => {
    const rows = data.map((item) => ({
      id: item._id,
      name: item.name,
      price: formatCurrency(item.discountPrice),
      Stock: item.stock,
      sold: item?.sold_out,
      images: item.images,
      shop: item.shop,
      category: item.category,
    }));
    setFilteredRows(rows);
  }, [data]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(searchValue, "i");
    const filteredData = data
      .filter((item) => {
        return Object.keys(item).some((field) => {
          return searchRegex.test(String(item[field]));
        });
      })
      .map((item) => ({
        id: item._id,
        name: item.name,
        price: formatCurrency(item.discountPrice),
        Stock: item.stock,
        sold: item?.sold_out,
        images: item.images,
        shop: item.shop,
        category: item.category,
      }));
    setFilteredRows(filteredData);
  };

  return (
    <>
      <div className="w-full px-9 pt-[80px] bg-white font-Inter h-[100vh]">
        <div className="flex justify-between">
          <h3 className="text-[24px] font-Inter pb-10 font-semibold">
            Products
          </h3>
          <div className="flex justify-end w-[600px]">
            <input
              type="text"
              name="text"
              autoComplete="text"
              value={searchText}
              onChange={(e) => requestSearch(e.target.value)}
              placeholder="Search products.."
              className="appearance-none font-Inter block w-[200px] h-[40px] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
            />
          </div>
        </div>
        <div className="w-full block 800px:flex items-center justify-between">
          <div className="w-full mb-4 800px:w-[24%] min-h-[20vh] bg-gray-200 rounded-lg px-2 py-5">
            <h2 className="pt-2 pl-[30px] text-[30px] font-[600] font-Inter">
              {data && data.length}
            </h2>
            <div className="block items-center pl-[30px] pt-2">
              <h5
                className={`${styles.productTitle} !text-[16px] leading-5 !font-[400] text-[#00000085] font-Inter`}
              >
                Total No. of Products{" "}
              </h5>
              <span className="text-[12px] font-Inter text-[#5a5a5a]">
                from{" "}
                <span className="text-[#000] font-semibold">
                  {sellers && sellers.length}
                </span>{" "}
                of Merchant(s)
              </span>
            </div>
          </div>
        </div>

        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          checkboxSelection
          autoHeight
          style={{ fontFamily: "Inter", color: "#000" }}
        />
      </div>
    </>
  );
};

export default AllProducts;
