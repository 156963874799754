import { createTheme } from "@material-ui/core";

// export const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#bf00ff",
//       light: "#bf00ff",
//     },
//     secondary: {
//       main: "#283a57",
//       light: "#283a57",
//     },
//     neutral: {
//       main: "#fff",
//       light: "#fff",
//     },
//   },
//   // eslint-disable-next-line no-use-before-define
//   padding: { spacing: 20 },
//   progress: {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     marginTop: -12,
//     marginLeft: -12,
//   },
//   typography: {
//     // In Chinese and Japanese the characters are usually larger,
//     // so a smaller fontsize may be appropriate.
//     fontSize: "1.85rem",
//     textTransform: "capitalize",
//   },
//   media: {
//     width: "100%",
//     height: "100%",
//   },
//   shape: {
//     borderRadius: 8,
//   },
//   "& .MuiOutlinedInput-root:hover": {
//     "& .MuiOutlinedInput-notchedOutline": {
//       borderWidth: 2,
//     },
//   },
//   "& .MuiOutlinedInput-root": {
//     "& .MuiOutlinedInput-notchedOutline": {
//       borderWidth: 2,
//     },
//   },
//   "& .MuiFilledInput-root": {
//     borderTopLeftRadius: "8px",
//     borderTopRightRadius: "8px",
//     borderBottomLeftRadius: "8px",
//     borderBottomRightRadius: "8px",
//   },
// });

export const theme = createTheme({
  palette: {
    primary: {
      main: "#bf00ff",
      light: "#bf00ff",
    },
    secondary: {
      main: "#283a57",
      light: "#283a57",
    },
    neutral: {
      main: "#fff",
      light: "#fff",
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        border: "2px solid #bf00ff",
        borderRadius: "8px",
        overflow: "hidden",
        backgroundColor: "transparent",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        "&:hover": {
          backgroundColor: "#fff",
        },
        "&.Mui-focused": {
          borderColor: "#bf00ff",
          backgroundColor: "#fff",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "none",
        },
        "&:after": {
          borderBottom: "none",
        },
        "&:hover:before": {
          borderBottom: "none",
        },
      },
      input: {
        padding: "17px 12px 12px",
      },
    },
    MuiInputLabel: {
      root: {
        backgroundColor: "transparent",
        padding: "0 4px",
      },
      filled: {
        transform: "translate(12px, 16px) scale(1)",
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: "Inter",
        fontWeight: 500,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: "8px",
      },
    },
  },
});
