import React from "react";
import { Link } from "react-router-dom";
import { navItems } from "../../static/data";
import styles from "../../styles/styles";

const Navbar = ({ active }) => {
  return (
    <>
      <div
        className={`block 800px:${styles.noramlFlex}`}
        style={{ display: "flex", justifyContent: "start", flex: "1" }}
      >
        {navItems.map((i, index) => (
          <div key={index} className="flex">
            <Link
              to={i.url}
              className={`menuItem ${
                active === index + 1
                  ? "text-[#020202]"
                  : "text-black 800px:text-[#677471]"
              } pb-[30px] 800px:pb-0 font-[400] px-6 cursor-pointer}`}
              style={{ fontFamily: "Inter" }}
            >
              <div>{i.title}</div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default Navbar;
