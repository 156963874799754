import React, { useEffect } from "react";
import SaccoLogin from "../components/Sacco/SaccoLogin";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SaccoLoginPage = () => {
  const navigate = useNavigate();
  const { isSacco, isLoading } = useSelector((state) => state.sacco);
  // if user is login then redirect to home page
  useEffect(() => {
    if (isSacco === true) {
      navigate(`/sacco/dashboard`);
    }
  }, [isLoading, isSacco]);
  return (
    <div>
      <SaccoLogin />
    </div>
  );
};

export default SaccoLoginPage;
