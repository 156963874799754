import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import Events from "../components/Events/Events";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Sponsored from "../components/Route/Sponsored";
import Footer from "../components/Layout/Footer";
import Loader from "../components/Layout/Loader"; // Import the Loader component

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(true); // State to control the loader

  useEffect(() => {
    // Simulate a network request or some async operation
    setTimeout(() => {
      setIsLoading(false); // Set loading to false after a delay
    }, 2000); // Adjust the timeout duration as needed
  }, []);

  if (isLoading) {
    return <Loader />; // Show loader while loading
  }

  return (
    <div>
      <Header activeHeading={1} />
      <Hero />
      <Categories />
      <BestDeals />
      {/* <Events /> */}
      {/* <FeaturedProduct /> */}
      {/* <Sponsored /> */}
      <Footer />
    </div>
  );
};

export default HomePage;
