import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSaccos } from "../../redux/actions/saccos";
import { toast } from "react-toastify";
import { backend_url, server } from "../../server";
import axios from "axios";
import SmoothList from "react-smooth-list";
import {
  deleteUserAddress,
  loadUser,
  updateUserInformation,
  updatUserAddress,
} from "../../redux/actions/user";
import {
  IoCamera,
  IoClose,
  IoLocate,
  IoLockClosedOutline,
  IoLockOpenOutline,
  IoPersonCircleOutline,
} from "react-icons/io5";
import { AiOutlineEdit } from "react-icons/ai";
import CustomSelect from "./Layout/CustomSelect";
import { Country, State } from "country-state-city";
import { Avatar } from "@material-ui/core";

const UserSettings = () => {
  const { saccos } = useSelector((state) => state.sacco);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { user, error, successMessage } = useSelector((state) => state.user);
  const [name, setName] = useState(user && user.name);
  const [email, setEmail] = useState(user && user.email);
  const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);
  const [saccoId, setSaccoId] = useState(user && user.saccoId);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState();
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [addressType, setAddressType] = useState("");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [membershipId, setMembershipId] = useState(user && user.membershipId);

  const [open, setOpen] = useState(false);
  const [openButton, setOpenButton] = useState(false);
  const [closing, setClosing] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [closingPassword, setClosingPassword] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [closingProfile, setClosingProfile] = useState(false);
  const [selectedSacco, setSelectedSacco] = useState([]);
  const [selected, setSelected] = useState(
    selectedSacco || { name: "Choose your sacco", avatar: "" }
  );

  const dispatch = useDispatch();

  const fullName = user && user.name;
  const nameParts = fullName.split(" ");

  // Assuming the name has at least two parts (first and last name)
  const firstName = nameParts[0];
  const lastName = nameParts[nameParts.length - 1];

  // If you want to handle cases where the name might not be in the expected format
  // const firstName = nameParts.length > 0 ? nameParts[0] : '';
  // const lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';

  useEffect(() => {
    dispatch(getAllSaccos());
  }, [dispatch]);

  const addressTypeData = [
    {
      name: "Default",
    },
    {
      name: "Home",
    },
    {
      name: "Office",
    },
  ];

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [error, successMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateUserInformation(
        name,
        email,
        phoneNumber,
        password,
        membershipId,
        saccoId,
        selectedSacco ? selectedSacco._id : null
      )
    );
  };

  // Image update
  const handleImage = async (e) => {
    const file = e.target.files[0];
    setAvatar(file);

    const formData = new FormData();

    formData.append("image", e.target.files[0]);

    await axios
      .put(`${server}/user/update-avatar`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((response) => {
        dispatch(loadUser());
        toast.success("avatar updated successfully!");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Password is updated");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleAdressSubmit = async (e) => {
    e.preventDefault();

    if (addressType === "" || country === "" || city === "") {
      toast.error("Please fill all the fields!");
    } else {
      // hear use redex
      dispatch(
        updatUserAddress(
          country,
          city,
          address1,
          address2,
          zipCode,
          addressType
        )
      );
      setOpen(false);
      setCountry("");
      setCity("");
      setAddress1("");
      setAddress2("");
      setZipCode(null); // return type is int so default is null
      setAddressType("");
    }
  };

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setOpenProfile(false);
      setOpenPassword(false);
      setClosing(false);
    }, 300);
  };

  const handleDelete = (item) => {
    const id = item._id;
    dispatch(deleteUserAddress(id));
  };

  // Find the sacco name by saccoId
  const getSaccoName = (saccoId) => {
    const sacco = saccos.find((s) => s._id === saccoId);
    return sacco ? sacco.name : "N/A";
  };

  const handleButtonClick = () => {
    setOpenButton(!openButton);
  };

  const handleOptionClick = (sacco) => {
    setSelected(sacco);
    setSelectedSacco(sacco);
    setOpenButton(false);
  };

  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials.toUpperCase();
  };

  return (
    <div className="w-full p-8 bg-white pt-[80px]">
      <div className="w-full bg-white">
        <h3 className="text-[24px] font-SplineSans pb-10 font-semibold px-5">
          My Profile
        </h3>
        <div className="w-full">
          <div className="w-full px-5">
            <div
              className="flex items-center justify-between px-4 py-4 rounded-xl bg-purple-50"
              style={{ border: "2px solid #eee" }}
            >
              <div className="flex items-center">
                {user?.avatar ? (
                  <img
                    src={`${backend_url}${user?.avatar}`}
                    className="w-[80px] h-[80px] rounded-full object-cover"
                    alt="profile img"
                  />
                ) : (
                  <Avatar
                    alt={user?.name}
                    style={{
                      width: "80px",
                      height: "80px",
                      marginRight: "10px",
                      borderRadius: "50px",
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <span className="font-Inter p-2 text-[#000] text-[1.5rem] font-medium">
                      {getInitials(user?.name)}
                    </span>
                  </Avatar>
                )}
                <div className="block ml-6 ">
                  <h5 className="font-Inter font-medium text-[1.2rem] pb-1">
                    {user && user.name}
                  </h5>
                  <h5 className="font-Inter font-medium text-[0.9rem] text-gray-500">
                    {user && user.role}
                  </h5>
                  <h5 className="font-Inter font-normal text-[0.8rem] text-gray-400">
                    Nairobi City, Kenya
                  </h5>
                </div>
              </div>
              <div className="flex">
                <button
                  onClick={() => setOpenPassword(true)}
                  className="text-[#fc5252] font-[400] flex justify-center items-center font-Inter text-[14px] w-[120px] h-[30px] border-2 border-[#d3d3d3] text-center rounded-[8px]"
                >
                  Password <IoLockOpenOutline />
                </button>
              </div>
            </div>
          </div>
          {openPassword && (
            <div
              className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
                closing ? "pointer-events-none" : ""
              }`}
            >
              <div
                className={`side-menu ${closing ? "closed" : ""}`}
                onClick={(e) => e.stopPropagation()}
                style={{
                  marginTop: "10px",
                  marginRight: "15px",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <div className="w-full flex justify-end p-4">
                  <IoClose
                    size={24}
                    className="cursor-pointer"
                    onClick={closeMenu}
                  />
                </div>
                <h5 className="text-[18px] font-Inter text-left pl-4 font-medium flex items-center">
                  <IoLockClosedOutline size={15} />{" "}
                  <span className="pl-2">Change Password</span>
                </h5>
                <div className="w-full">
                  <form
                    aria-required
                    onSubmit={passwordChangeHandler}
                    className="p-4"
                  >
                    <SmoothList delay={300} transitionDuration={600} visible>
                      <div className="pt-3">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          Old password
                        </label>
                        <input
                          type="password"
                          className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                          required
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                      </div>
                    </SmoothList>
                    <SmoothList delay={400} transitionDuration={600} visible>
                      <div className="pt-3">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          New Password
                        </label>
                        <input
                          type="password"
                          className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                          required
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                    </SmoothList>
                    <SmoothList delay={500} transitionDuration={600} visible>
                      <div className="pt-3">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          Confirm new password
                        </label>
                        <input
                          type="password"
                          className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                          required
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </SmoothList>
                    <SmoothList delay={600} transitionDuration={600} visible>
                      <div className="pt-8">
                        <div className="flex">
                          <button
                            onClick={closeMenu}
                            type="reset"
                            className="group relative w-full h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                          >
                            Cancel
                          </button>
                          <div className="m-1"></div>
                          <button
                            type="submit"
                            className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter"
                          >
                            Change password
                          </button>
                        </div>
                      </div>
                    </SmoothList>
                  </form>
                </div>
              </div>
            </div>
          )}
          <br />

          <div className="w-full px-5">
            <div
              className="px-4 py-4 rounded-xl bg-purple-50"
              style={{ border: "2px solid #eee" }}
            >
              <div className="flex justify-between">
                <h5 className="font-Inter font-semibold text-[1rem] pb-5">
                  Personal Information
                </h5>
                <button
                  onClick={() => setOpenProfile(true)}
                  className="text-[#646464] font-[400] flex justify-center items-center font-Inter text-[14px] w-[80px] h-[30px] border-2 border-[#d3d3d3] text-center rounded-[8px]"
                >
                  Edit <AiOutlineEdit />
                </button>
              </div>

              <div className="block">
                <div className="flex mb-4">
                  <div className="w-4/12">
                    <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                      First Name
                    </h5>
                    <h5 className="font-Inter font-medium text-[1rem] pb-1">
                      {firstName}
                    </h5>
                  </div>
                  <div>
                    <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                      Last Name
                    </h5>
                    <h5 className="font-Inter font-medium text-[1rem] pb-1">
                      {lastName}
                    </h5>
                  </div>
                </div>
                <div className="flex mb-4">
                  <div className="w-4/12">
                    <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                      Email address
                    </h5>
                    <h5 className="font-Inter font-medium text-[1rem] pb-1">
                      {user && user.email}
                    </h5>
                  </div>
                  <div>
                    <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                      Phone
                    </h5>
                    <h5 className="font-Inter font-medium text-[1rem] pb-1">
                      +254 {user && user.phoneNumber}
                    </h5>
                  </div>
                </div>
                <div className="flex mb-4">
                  <div className="w-4/12">
                    <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                      Sacco
                    </h5>
                    <h5 className="font-Inter font-medium text-[1rem] pb-1">
                      {user && user.saccoId
                        ? getSaccoName(user.saccoId)
                        : "N/A"}
                    </h5>
                  </div>
                  <div>
                    <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                      Sacco Member ID
                    </h5>
                    <h5 className="font-Inter font-medium text-[1rem] pb-1">
                      {user && user.membershipId ? user.membershipId : "N/A"}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          {openProfile && (
            <div
              className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
                closing ? "pointer-events-none" : ""
              }`}
            >
              <div
                className={`side-menu ${closing ? "closed" : ""}`}
                onClick={(e) => e.stopPropagation()}
                style={{
                  marginTop: "10px",
                  marginRight: "15px",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <div className="w-full flex justify-end p-4">
                  <IoClose
                    size={24}
                    className="cursor-pointer"
                    onClick={closeMenu}
                  />
                </div>
                <h5 className="text-[18px] font-Inter text-left pl-4 font-medium flex items-center">
                  <IoPersonCircleOutline size={18} />{" "}
                  <span className="pl-2">Update Profile</span>
                </h5>
                <SmoothList delay={300} transitionDuration={600} visible>
                  <div className="flex justify-center w-full">
                    <div className="relative">
                      {user?.avatar ? (
                        <img
                          src={`${backend_url}${user?.avatar}`}
                          className="w-[80px] h-[80px] rounded-full object-cover"
                          alt="profile img"
                        />
                      ) : (
                        <Avatar
                          alt={user?.name}
                          style={{
                            width: "80px",
                            height: "80px",
                            marginRight: "10px",
                            borderRadius: "50px",
                            backgroundColor: "#dfdfdf",
                          }}
                        >
                          <span className="font-Inter p-2 text-[#000] text-[1.5rem] font-medium">
                            {getInitials(user?.name)}
                          </span>
                        </Avatar>
                      )}
                      <div>
                        <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center absolute bottom-[-1px] right-[-3px] cursor-pointer">
                          <input
                            type="file"
                            id="image"
                            className="hidden"
                            onChange={handleImage}
                          />
                          <label htmlFor="image">
                            <IoCamera className="cursor-pointer" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </SmoothList>
                <br />

                <div className="w-full px-5">
                  <form onSubmit={handleSubmit} aria-required={true}>
                    <SmoothList delay={400} transitionDuration={600} visible>
                      <div>
                        <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                          required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </SmoothList>
                    <SmoothList delay={500} transitionDuration={600} visible>
                      <div className="pt-3">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          Email Address
                        </label>
                        <input
                          type="text"
                          className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </SmoothList>
                    <SmoothList delay={600} transitionDuration={600} visible>
                      <div className="pt-3">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          Phone Number
                        </label>
                        <input
                          type="number"
                          className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                          required
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </div>
                    </SmoothList>
                    <SmoothList delay={700} transitionDuration={600} visible>
                      <div className="pt-3">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          Your password
                        </label>
                        <input
                          type="password"
                          className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </SmoothList>

                    <SmoothList delay={800} transitionDuration={600} visible>
                      <div className="pt-3">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          Select Sacco
                        </label>
                        <select
                          name=""
                          id=""
                          value={city}
                          onChange={(e) => setSaccoId(e.target.value)}
                          className="w-full mt-2 appearance-none font-Inter block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        >
                          <option
                            value=""
                            className="block border pb-2 font-Inter"
                          >
                            Choose your sacco
                          </option>
                          {saccos.map((sacco) => (
                            <option
                              key={sacco._id}
                              value={sacco._id}
                              className="block border pb-2 font-Inter"
                            >
                              {sacco.name}
                            </option>
                          ))}
                        </select>
                        {/* <CustomSelect
                          saccos={saccos}
                          selectedSacco={selectedSacco}
                          setSelectedSacco={setSelectedSacco}
                        /> */}
                        {/* <div>
                          <label className="pb-2 font-Inter text-[0.9rem]">
                            Select Sacco
                          </label>
                          <div className="relative">
                            <span className="inline-block w-full rounded-md shadow-sm mt-2">
                              <button
                                onClick={handleButtonClick}
                                type="button"
                                aria-haspopup="listbox"
                                aria-expanded={openButton}
                                aria-labelledby="assigned-to-label"
                                className="cursor-default relative w-full font-Inter rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-[#BF00FF] transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                              >
                                <div className="flex items-center space-x-3">
                                  {selected.avatar ? (
                                    <img
                                      src={`${backend_url}${selected.avatar}`}
                                      alt=""
                                      className="flex-shrink-0 h-6 w-6 rounded-full"
                                    />
                                  ) : (
                                    <span className="flex-shrink-0 h-6 w-6 rounded-full bg-gray-200"></span>
                                  )}
                                  <span className="block truncate font-Inter">
                                    {selected.name}
                                  </span>
                                </div>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <svg
                                    className="h-5 w-5 text-gray-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                      fillRule="evenodd"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            </span>
                            {openButton && (
                              <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
                                <ul
                                  tabIndex="-1"
                                  role="listbox"
                                  aria-labelledby="assigned-to-label"
                                  className="max-h-56 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                                >
                                  {saccos.map((sacco) => (
                                    <li
                                      key={sacco._id}
                                      value={saccoId}
                                      role="option"
                                      onClick={() => handleOptionClick(sacco)}
                                      onMouseEnter={() => setSelected(sacco)}
                                      onMouseLeave={() =>
                                        setSelected(
                                          selectedSacco || {
                                            name: "Choose your sacco",
                                            avatar: "",
                                          }
                                        )
                                      }
                                      className={`${
                                        selected._id === sacco._id
                                          ? "text-white bg-indigo-600"
                                          : "text-gray-900"
                                      } cursor-default select-none relative py-2 pl-4 pr-9`}
                                    >
                                      <div className="flex items-center space-x-3">
                                        <img
                                          src={`${backend_url}${sacco.avatar}`}
                                          alt=""
                                          className="flex-shrink-0 h-6 w-6 rounded-full"
                                        />
                                        <span
                                          className={`${
                                            selected._id === sacco._id
                                              ? "font-semibold"
                                              : "font-normal"
                                          } block truncate font-Inter`}
                                        >
                                          {sacco.name}
                                        </span>
                                      </div>
                                      {selected._id === sacco._id && (
                                        <span
                                          className={`${
                                            selected._id === sacco._id
                                              ? "text-white"
                                              : "text-indigo-600"
                                          } absolute inset-y-0 right-0 flex items-center pr-4`}
                                        >
                                          <svg
                                            className="h-5 w-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clipRule="evenodd"
                                            ></path>
                                          </svg>
                                        </span>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div> */}
                      </div>
                    </SmoothList>
                    <SmoothList delay={900} transitionDuration={600} visible>
                      <div className="pt-3">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          Sacco Membership ID
                        </label>
                        <input
                          type="text"
                          className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                          value={membershipId}
                          onChange={(e) => setMembershipId(e.target.value)}
                        />
                      </div>
                    </SmoothList>
                    <SmoothList delay={1000} transitionDuration={600} visible>
                      <div className="py-8">
                        <div className="flex">
                          <button
                            onClick={closeMenu}
                            type="reset"
                            className="group relative w-full h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                          >
                            Cancel
                          </button>
                          <div className="m-1"></div>
                          <button
                            type="submit"
                            className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter"
                          >
                            Update profile
                          </button>
                        </div>
                      </div>
                    </SmoothList>
                  </form>
                </div>
              </div>
            </div>
          )}

          <div className="w-full px-5 pb-8 bg-white">
            <div
              className="px-4 py-4 rounded-xl bg-purple-50"
              style={{ border: "2px solid #eee" }}
            >
              <div className="flex justify-between">
                <h5 className="font-Inter font-semibold text-[1rem]">
                  Address
                </h5>
                <button
                  onClick={() => setOpen(true)}
                  className="text-[#646464] font-[400] flex justify-center items-center font-Inter text-[14px] w-[80px] h-[30px] border-2 border-[#d3d3d3] text-center rounded-[8px]"
                >
                  Add <AiOutlineEdit />
                </button>
              </div>

              {user &&
                user.addresses.map((item, index) => (
                  <div
                    className={`block pt-5 ${
                      index !== user.addresses.length - 1
                        ? "border-b border-gray-200"
                        : ""
                    }`}
                    key={index}
                  >
                    <div className="flex mb-4">
                      <div className="w-4/12">
                        <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                          Residence or Office
                        </h5>
                        <h5 className="font-Inter font-medium text-[1rem] pb-1">
                          {item.address1}
                        </h5>
                      </div>
                      <div>
                        <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                          Unit/Suite/House No.
                        </h5>
                        <h5 className="font-Inter font-medium text-[1rem] pb-1">
                          {item.address2}
                        </h5>
                      </div>
                    </div>
                    <div className="flex mb-4">
                      <div className="w-4/12">
                        <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                          Postal Box
                        </h5>
                        <h5 className="font-Inter font-medium text-[1rem] pb-1">
                          {item.zipCode}
                        </h5>
                      </div>
                      <div>
                        <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                          City or Town
                        </h5>
                        <h5 className="font-Inter font-medium text-[1rem] pb-1">
                          {item.city}
                        </h5>
                      </div>
                    </div>
                    <div className="flex mb-4">
                      <div className="w-4/12">
                        <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                          Country
                        </h5>
                        <h5 className="font-Inter font-medium text-[1rem] pb-1">
                          {item.country}
                        </h5>
                      </div>
                      <div>
                        <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                          Address Type
                        </h5>
                        <h5 className="font-Inter font-medium text-[1rem] pb-1">
                          {item.addressType}
                        </h5>
                      </div>
                    </div>
                    <div className="pb-5">
                      <button
                        className="text-red-600 font-Inter"
                        onClick={() => handleDelete(item)}
                      >
                        Delete address
                      </button>
                    </div>
                  </div>
                ))}
              {user && user.addresses.length === 0 && (
                <h5 className="text-center pt-8 text-[18px]">
                  You not have any saved address!
                </h5>
              )}
            </div>
          </div>

          {open && (
            <div
              className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
                closing ? "pointer-events-none" : ""
              }`}
            >
              <div
                className={`side-menu ${closing ? "closed" : ""}`}
                onClick={(e) => e.stopPropagation()}
                style={{
                  marginTop: "10px",
                  marginRight: "15px",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <div className="w-full flex justify-end p-4">
                  <IoClose
                    size={24}
                    className="cursor-pointer"
                    onClick={closeMenu}
                  />
                </div>
                <h5 className="text-[18px] font-Inter text-left pl-4 font-medium flex items-center">
                  <IoLocate size={18} />{" "}
                  <span className="pl-2">New Address</span>
                </h5>
                <form
                  aria-required
                  onSubmit={handleAdressSubmit}
                  className="w-full"
                >
                  <div className="w-full block p-4">
                    <SmoothList delay={300} transitionDuration={600} visible>
                      <div className="w-full pb-2">
                        <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                          Country
                        </label>
                        <select
                          name=""
                          id=""
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          className="w-full mt-2 appearance-none font-Inter block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        >
                          <option
                            value=""
                            className="bloc border pb-2 font-Inter"
                          >
                            Choose your country
                          </option>
                          {Country &&
                            Country.getAllCountries().map((item) => (
                              <option
                                className="block pb-2"
                                key={item.isoCode}
                                value={item.isoCode}
                              >
                                <span className="font-Inter">{item.name}</span>
                              </option>
                            ))}
                        </select>
                      </div>
                    </SmoothList>

                    {/* City */}
                    <SmoothList delay={400} transitionDuration={600} visible>
                      <div className="w-full pb-2">
                        <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                          County
                        </label>
                        <select
                          name=""
                          id=""
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          className="w-full mt-2 appearance-none font-Inter block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        >
                          <option
                            value=""
                            className="block border pb-2 font-Inter"
                          >
                            Choose your county
                          </option>
                          {State &&
                            State.getStatesOfCountry(country).map((item) => (
                              <option
                                className="block pb-2"
                                key={item.isoCode}
                                value={item.isoCode}
                              >
                                <span className="font-Inter">{item.name}</span>
                              </option>
                            ))}
                        </select>
                      </div>
                    </SmoothList>

                    {/* Address 1 */}
                    <SmoothList delay={500} transitionDuration={600} visible>
                      <div className="w-full pb-2">
                        <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                          Residence or Office
                        </label>
                        <input
                          type="address"
                          className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                          required
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                        />
                      </div>
                    </SmoothList>

                    {/* Address 2 */}
                    <SmoothList delay={600} transitionDuration={600} visible>
                      <div className="w-full pb-2">
                        <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                          Unit/Suite/House No.
                        </label>
                        <input
                          type="address"
                          className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                          required
                          value={address2}
                          onChange={(e) => setAddress2(e.target.value)}
                        />
                      </div>
                    </SmoothList>

                    <SmoothList delay={700} transitionDuration={600} visible>
                      <div className="w-full pb-2">
                        <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                          Zip Code
                        </label>
                        <input
                          type="number"
                          className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                          required
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                        />
                      </div>
                    </SmoothList>

                    <SmoothList delay={800} transitionDuration={600} visible>
                      <div>
                        <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                          Address Type
                        </label>
                        <select
                          name=""
                          id=""
                          value={addressType}
                          onChange={(e) => setAddressType(e.target.value)}
                          className="w-full mt-2 appearance-none font-Inter block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        >
                          <option value="" className="block border pb-2">
                            Choose Yoour Address Type
                          </option>
                          {addressTypeData &&
                            addressTypeData.map((item) => (
                              <option
                                className="block pb-2"
                                key={item.name}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </SmoothList>

                    <SmoothList delay={900} transitionDuration={600} visible>
                      <div className=" w-full pb-2 pt-8">
                        <button
                          type="submit"
                          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter"
                        >
                          Add address
                        </button>
                      </div>
                    </SmoothList>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
