import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Lottie from "react-lottie";
import animationData from "../Assests/animations/no-product.json";

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [play, setPlay] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleNavigate = () => {
    navigate("/products");
    window.location.reload();
  };

  useEffect(() => {
    if (categoryData === null) {
      const d = allProducts;
      setData(d);
    } else {
      const d =
        allProducts && allProducts.filter((i) => i.category === categoryData);
      setData(d);
    }
    //    window.scrollTo(0,0);
  }, [allProducts]);

  useEffect(() => {
    // Delay the start of the animation by 2 seconds (2000 milliseconds)
    const timer = setTimeout(() => {
      setPlay(true);
    }, 2000); // Adjust the delay time here

    // Clean up the timer
    return () => clearTimeout(timer);
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: play,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={3} />
          <div className="bg-[#f2f0ea] h-[15rem] w-full flex items-center justify-center">
            <div>
              <h1 className="text-4xl font-bold font-SplineSans">
                {categoryData ? categoryData : "All Products"}
              </h1>
            </div>
          </div>
          <br />
          <br />
          <div className={`${styles.section} mb-40`}>
            <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
              {data &&
                data.map((i, index) => <ProductCard data={i} key={index} />)}
            </div>
            {data && data.length === 0 ? (
              <div className="flex flex-col items-center">
                <Lottie options={defaultOptions} width={200} height={200} />
                <h1 className="text-center w-full text-[20px] font-Inter font-medium">
                  No products Found!
                </h1>
                <h5 className="text-center w-full pb-[10px] text-[14px] font-Inter font-light text-gray-500">
                  We are working hard to add them in the mean time <br />
                  you can checkout our other categories
                </h5>
                <div className="ml-14 mr-14 mb-10">
                  <button
                    onClick={handleNavigate}
                    className="group relative w-[250px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
                  >
                    Continue shopping
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default ProductsPage;
