import { useEffect, useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import styles from "../../styles/styles";
import { Tooltip } from "@material-ui/core";
import { formatCurrency } from "../../Utils/formatCurrency";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { DataGrid } from "@material-ui/data-grid";
import { format, parseISO } from "date-fns";

const UserDashboardHero = () => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const [availableCredit, setAvailableCredit] = useState(
    user && user.availableCredit
  );
  const [totalCreditUsed, setTotalCreditUsed] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  useEffect(() => {
    if (orders && orders.length > 0) {
      const total = orders.reduce(
        (acc, order) => acc + (order.paymentInfo?.creditUsed || 0),
        0
      );
      setTotalCreditUsed(total);
    }
  }, [orders]);

  const columns = [
    {
      field: "paymentInfo",
      headerName: "Transaction ID",
      minWidth: 100,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className="rowitem">{params.row.paymentInfo.transactionId}</div>
        );
      },
    },
    {
      field: "total",
      headerName: "Amount",
      minWidth: 160,
      flex: 0.8,
    },
    {
      field: "creditLimit",
      headerName: "Credit Used",
      minWidth: 140,
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div
            className={`rowitem ${
              params.row.paymentInfo.creditUsed > 0
                ? "text-red-500"
                : "text-black"
            }`}
          >
            {formatCurrency(params.row.paymentInfo.creditUsed)}
          </div>
        );
      },
    },
    {
      field: "paidAt",
      headerName: "Date",
      minWidth: 90,
      flex: 0.4,
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: formatCurrency(item.totalPrice),
        status: item.status,
        user: item.user,
        cart: item.cart,
        paymentInfo: item.paymentInfo,
        paidAt: format(parseISO(item.paidAt), "dd MMM yyyy"),
        createdAt: format(parseISO(item.paidAt), "MMMM d, yyyy"),
        timeAt: format(parseISO(item.paidAt), "H:MM aa"),
      });
    });

  return (
    <div className="w-full p-8 bg-white pt-[80px]">
      <div className="w-full bg-white">
        <h3 className="text-[24px] font-SplineSans pb-10 font-semibold px-5">
          Dashboard
        </h3>
        <div className="w-full block 800px:flex items-center justify-start px-5">
          <div
            className="w-full mb-4 800px:w-[40%] min-h-[20vh] bg-white rounded-lg px-2 py-5 mr-5 flex"
            style={{ border: "1px solid #eee" }}
          >
            <div className="w-6/12">
              <h2 className="pt-4 pl-[20px] text-[20px] font-[600] font-Inter text-ellipsis">
                {formatCurrency(user && user.availableCredit)}
              </h2>
              <div className="flex items-center pl-[20px] pt-3">
                <h5
                  className={`${styles.productTitle} !text-[14px] leading-5 !font-[500] text-[#000000] font-Inter`}
                >
                  Approved Credit{" "}
                </h5>
                <span className="ml-[4px]">
                  <Tooltip title="Top" placement="bottom">
                    <IoInformationCircleOutline
                      size={16}
                      color="gray"
                      className="cursor-pointer"
                    />
                  </Tooltip>
                </span>
              </div>
            </div>
            <div className="w-6/12">
              <h2 className="pt-4 pl-[20px] text-[20px] font-[600] font-Inter text-green-500">
                {formatCurrency(user && user.availableCredit - totalCreditUsed)}
              </h2>
              <div className="flex items-center pl-[20px] pt-3">
                <h5
                  className={`${styles.productTitle} !text-[14px] leading-5 !font-[500] text-[#000000] font-Inter`}
                >
                  Remaining Credit
                </h5>
                <span className="ml-[4px]">
                  <IoInformationCircleOutline size={16} color="gray" />
                </span>
              </div>
            </div>
          </div>

          <div className="w-full mb-4 800px:w-[32%] min-h-[20vh] bg-blue-50 rounded-lg px-2 py-5">
            <h2 className="pt-4 pl-[20px] text-[20px] font-[600] font-Inter">
              {orders && orders.length}
            </h2>
            <div className="block items-center pl-[20px] pt-3">
              <h5
                className={`${styles.productTitle} !text-[14px] leading-5 !font-[400] text-[#000000] font-Inter`}
              >
                Total Orders
              </h5>
            </div>
          </div>
        </div>
        <br />
        <div className="px-5">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            autoHeight
            style={{ fontFamily: "Inter" }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserDashboardHero;
