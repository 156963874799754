import React from "react";
import UserHeader from "../../components/User/Layout/UserHeader";
import UserSidebar from "../../components/User/Layout/UserSidebar";
import TrackAllOrders from "../../components/User/TrackAllOrders";

const UserTrackOrdersPage = () => {
  return (
    <div>
      <div className="fixed ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
        <UserHeader />
      </div>
      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[250px] fixed">
          <UserSidebar active={4} />
        </div>
        <div className="ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
          <TrackAllOrders />
        </div>
      </div>
    </div>
  );
};

export default UserTrackOrdersPage;
