import React from "react";
import { MdOutlineLocalOffer } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CiMoneyBill } from "react-icons/ci";
import { GrWorkshop } from "react-icons/gr";
import { backend_url } from "../../../server";
import { IoStorefrontOutline, IoCashOutline } from "react-icons/io5";
import { Avatar } from "@material-ui/core";

const AdminHeader = () => {
  const { user } = useSelector((state) => state.user);

  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials.toUpperCase();
  };

  return (
    <div className="w-full h-[80px] bg-white sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div></div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <Link to="/admin/withdraw-request" className="800px:block hidden">
            <IoCashOutline
              color="#555"
              size={24}
              className="mx-4 cursor-pointer"
            />
          </Link>
          <Link to="/admin/merchants" className="800px:block hidden">
            <IoStorefrontOutline
              color="#555"
              size={24}
              className="mx-4 cursor-pointer"
            />
          </Link>
          <div className="flex items-center bg-[#fbf7ed] py-2 px-3 rounded-[50px]">
            {user?.avatar ? (
              <img
                src={`${backend_url}${user?.avatar}`}
                alt=""
                className="w-[30px] h-[30px] rounded-full mr-2"
              />
            ) : (
              <Avatar
                alt={user?.name}
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "10px",
                  borderRadius: "50px",
                  backgroundColor: "#dfdfdf",
                }}
              >
                <span className="font-Inter p-2 text-[#000] text-[0.8rem] font-medium">
                  {getInitials(user?.name)}
                </span>
              </Avatar>
            )}
            <span className="font-Inter">{user?.name}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
