import React from "react";
import { AiOutlineFolderAdd, AiOutlineGift } from "react-icons/ai";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { VscNewFile } from "react-icons/vsc";
import { CiMoneyBill, CiSettings } from "react-icons/ci";
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import {
  IoSpeedometerOutline,
  IoSpeedometer,
  IoBagHandleOutline,
  IoBagHandle,
  IoBagAddOutline,
  IoBagAdd,
  IoCubeOutline,
  IoCube,
  IoCardOutline,
  IoCard,
  IoGiftOutline,
  IoGift,
  IoReceiptOutline,
  IoReceipt,
  IoSettingsOutline,
  IoSettings,
  IoLogOut,
  IoLogOutOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../../server";

const DashboardSideBar = ({ active }) => {
  const navigate = useNavigate();

  const logoutHandler = () => {
    axios
      .get(`${server}/shop/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload(true);
        navigate("/");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  return (
    <div className="w-full h-[100vh] bg-[#130f0c] top-0 left-0 z-10 ">
      <div className="h-screen flex flex-col justify-between">
        <div>
          <div className="h-[80px] flex items-center ml-8">
            <Link to="/merchant/dashboard">
              <img
                style={{ height: "35px", paddingLeft: "10px" }}
                src="../lipable-logo-light.png"
                alt="Lipable"
              />
            </Link>
          </div>
          {/* single item */}
          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/merchant/dashboard" className="w-full flex items-center">
              {active === 1 ? (
                <IoSpeedometer size={20} color={"#FFFFFF"} />
              ) : (
                <IoSpeedometerOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 1 ? "text-[#fff]" : "text-[#787471]"
                }`}
              >
                Dashboard
              </h5>
            </Link>
          </div>

          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/merchant/orders" className="w-full flex items-center">
              {active === 2 ? (
                <IoBagHandle size={20} color={"#FFFFFF"} />
              ) : (
                <IoBagHandleOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 2 ? "text-[#FFFFFF]" : "text-[#787471]"
                }`}
              >
                All Orders
              </h5>
            </Link>
          </div>

          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/merchant/products" className="w-full flex items-center">
              {active === 3 ? (
                <IoCube size={20} color={"#FFFFFF"} />
              ) : (
                <IoCubeOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 3 ? "text-[#FFFFFF]" : "text-[#787471]"
                }`}
              >
                All Products
              </h5>
            </Link>
          </div>

          {/* <div className="w-full flex items-center p-4 ml-6">
        <Link
          to="/dashboard-create-product"
          className="w-full flex items-center"
        >
          {active === 4 ? (
            <IoBagAdd size={20} color={"#FFFFFF"} />
          ) : (
            <IoBagAddOutline size={20} color={"#787471"} />
          )}
          <h5
            className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
              active === 4 ? "text-[#FFFFFF]" : "text-[#787471]"
            }`}
          >
            Add Product
          </h5>
        </Link>
      </div> */}

          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/merchant/withdraw" className="w-full flex items-center">
              {active === 7 ? (
                <IoCard size={20} color={"#FFFFFF"} />
              ) : (
                <IoCardOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 7 ? "text-[#FFFFFF]" : "text-[#787471]"
                }`}
              >
                Withdraw
              </h5>
            </Link>
          </div>

          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/merchant/discounts" className="w-full flex items-center">
              {active === 9 ? (
                <IoGift size={20} color={"#FFFFFF"} />
              ) : (
                <IoGiftOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 9 ? "text-[#FFFFFF]" : "text-[#787471]"
                }`}
              >
                Discounts
              </h5>
            </Link>
          </div>

          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/merchant/refunds" className="w-full flex items-center">
              {active === 10 ? (
                <IoReceipt size={20} color={"#FFFFFF"} />
              ) : (
                <IoReceiptOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 10 ? "text-[#FFFFFF]" : "text-[#787471]"
                }`}
              >
                Refunds
              </h5>
            </Link>
          </div>

          <div className="w-full flex items-center p-4 ml-6">
            <Link to="/merchant/settings" className="w-full flex items-center">
              {active === 11 ? (
                <IoSettings size={20} color={"#FFFFFF"} />
              ) : (
                <IoSettingsOutline size={20} color={"#787471"} />
              )}
              <h5
                className={`hidden 800px:block pl-2 text-[14px] font-[400] font-Inter ${
                  active === 11 ? "text-[#FFFFFF]" : "text-[#787471]"
                }`}
              >
                Settings
              </h5>
            </Link>
          </div>
        </div>
        <div
          className="w-full flex items-center p-4 ml-6 mb-6 cursor-pointer"
          onClick={logoutHandler}
        >
          {active === 12 ? (
            <IoLogOut size={20} color={"#FFFFFF"} />
          ) : (
            <IoLogOutOutline size={20} color={"#787471"} />
          )}
          <span
            className={`pl-2 text-[14px] font-[400] font-Inter ${
              active === 12 ? "text-[#fff]" : "text-[#787471]"
            } 800px:block hidden`}
          >
            Logout
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashboardSideBar;
