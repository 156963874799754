import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { format, parseISO } from "date-fns";
import { Avatar, Button } from "@material-ui/core";
import { MdContentCopy } from "react-icons/md";
import styles from "../../styles/styles";
import { RxAvatar, RxCross1 } from "react-icons/rx";
import axios from "axios";
import {
  IoTrashOutline,
  IoClose,
  IoCloudUploadOutline,
  IoCopyOutline,
  IoSearch,
  IoCloseOutline,
  IoOpenOutline,
} from "react-icons/io5";
import { backend_url, server } from "../../server";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllSaccos } from "../../redux/actions/saccos";
import { Link, useNavigate } from "react-router-dom";
import SmoothList from "react-smooth-list";

const AllSaccos = () => {
  const dispatch = useDispatch();
  const { saccos } = useSelector((state) => state.sacco);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [userId, setUserId] = useState("");
  const [closing, setClosing] = useState(false);
  const [copied, setCopied] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState();
  const [avatar, setAvatar] = useState();
  const [password, setPassword] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const { success, error } = useSelector((state) => state.sacco);
  const [selectedUser, setSelectedUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (success) {
      toast.success("Sacco created successfully!", {
        position: "top-right",
        theme: "light",
      });
      window.location.reload();
    }
  }, [dispatch, error, success]);

  // file upload
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  // Create Sacco
  const handleSubmit = async (e) => {
    e.preventDefault();

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const newForm = new FormData();
    newForm.append("file", avatar);
    newForm.append("name", name);
    newForm.append("email", email);
    newForm.append("password", password);
    newForm.append("address", address);
    newForm.append("website", website);
    newForm.append("phoneNumber", phoneNumber);
    newForm.append("zipCode", zipCode);
    newForm.append("description", description);

    try {
      const res = await axios.post(
        `${server}/sacco/create-sacco`,
        newForm,
        config
      );
      toast.success(res.data.message);
      // Reset form fields
      setName("");
      setEmail("");
      setPassword("");
      setAddress("");
      setPhoneNumber("");
      setZipCode("");
      setWebsite("");
      setDescription("");
      setAvatar(null);

      // Navigate to sacco list or some other page
      navigate("/admin/saccos");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const inputRef = useRef(null);

  const handleCopyClick = async () => {
    if (inputRef.current) {
      try {
        await navigator.clipboard.writeText(inputRef.current.value);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Hide the message after 2 seconds
      } catch (err) {
        console.error("Failed to copy: ", err);
        alert("Failed to copy");
      }
    }
  };

  const generatePassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#*!%()?+=-";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    if (inputRef.current) {
      inputRef.current.value = result;
    }
  };

  useEffect(() => {
    dispatch(getAllSaccos());
  }, [dispatch]);

  const handleDelete = async (id) => {
    await axios
      .delete(`${server}/sacco/delete-sacco/${id}`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
      });

    dispatch(getAllSaccos());
  };

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setClosing(false);
      setSelectedUser(null);
    }, 300);
  };

  const openMenu = () => {
    setOpen(true);
  };

  const openUser = (user) => {
    setSelectedUser(user);
  };

  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part.charAt(0)).join("");
    return initials.toUpperCase();
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
      flex: 0.4,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            #{params.row.id.slice(0, 8).toUpperCase()}
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "Sacco Name",
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        const sacco = params.row;
        const avatarUrl = sacco.avatar ? `${backend_url}${sacco.avatar}` : null;
        const initials = sacco.name
          .split(" ")
          .map((n) => n[0])
          .join("");

        return (
          <div className="flex items-center ml-2">
            <Avatar
              src={avatarUrl} // Use the avatar URL if available
              alt={sacco.name}
              style={{
                width: "34px",
                height: "34px",
                marginRight: "10px",
                borderRadius: "50px",
              }}
            >
              {!avatarUrl && initials} {/* Display initials if no avatar */}
            </Avatar>
            <span>{sacco.name}</span>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "address",
      headerName: "Sacco Address",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "joinedAt",
      headerName: "Created On",
      type: "text",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "Preview",
      flex: 0.4,
      minWidth: 50,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => openUser(params.row)}
              className="group relative w-full h-[35px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-[50px] text-gray-700 bg-transparent hover:bg-gray-100 font-Inter"
            >
              <IoOpenOutline size={20} />
            </button>
          </>
        );
      },
    },
    {
      field: " ",
      flex: 0.3,
      minWidth: 30,
      headerName: " ",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              className="group relative w-full h-[35px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-[50px] text-gray-700 bg-transparent hover:bg-gray-100 font-Inter"
              onClick={() => setUserId(params.id) || setOpenDelete(true)}
            >
              <IoTrashOutline size={20} />
            </button>
          </>
        );
      },
    },
  ];

  const row = [];
  saccos &&
    saccos.forEach((item) => {
      row.push({
        id: item._id,
        name: item?.name,
        email: item?.email,
        avatar: item?.avatar,
        joinedAt: format(parseISO(item.createdAt), "dd MMM yyyy"),
        address: item.address,
        zipCode: item?.zipCode,
        description: item?.description,
        phoneNumber: item?.phoneNumber,
      });
    });

  return (
    <div className="w-full px-8 pt-[80px] bg-white font-Inter h-[100vh]">
      <div>
        <div className="flex justify-between">
          <h3 className="text-[24px] font-Inter pb-10 font-semibold">Saccos</h3>
          <button
            onClick={() => setOpen(true)}
            className="group relative w-[150px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
          >
            Add sacco
          </button>
        </div>

        <div className="w-full min-h-[45vh] bg-white rounded-2xl">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            style={{ fontFamily: "Inter", color: "#000" }}
          />
        </div>
        {open && (
          <div
            className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
              closing ? "pointer-events-none" : ""
            }`}
            onClick={closeMenu}
          >
            <div
              className={`side-menu-wide ${closing ? "closed" : ""}`}
              onClick={(e) => e.stopPropagation()}
              style={{
                marginTop: "10px",
                marginRight: "15px",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="w-full flex justify-end p-4">
                <IoClose
                  size={24}
                  className="cursor-pointer"
                  onClick={closeMenu}
                />
              </div>
              <h5 className="text-[24px] font-Inter text-left pl-4 font-medium">
                Add Sacco
              </h5>

              <form className="p-4" onSubmit={handleSubmit}>
                <SmoothList delay={300} transitionDuration={600} visible>
                  <div>
                    <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                      Sacco Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                      placeholder="Name"
                    />
                  </div>
                </SmoothList>
                <SmoothList delay={400} transitionDuration={600} visible>
                  <div className="flex justify-between">
                    <div className="pt-3 w-[49%]">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Email Address <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        placeholder="Email"
                      />
                    </div>
                    <div className="pt-3 w-[49%] relative">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Set Password <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-2 relative">
                        <input
                          type="password"
                          ref={inputRef}
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          autoComplete={false}
                          className="appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                          placeholder="*****"
                          id="password-input"
                        />
                        <button
                          type="button"
                          onClick={handleCopyClick}
                          className="absolute right-1 top-1/2 transform -translate-y-1/2 bg-transparent px-2 py-1 rounded-md"
                        >
                          <MdContentCopy color="gray" />
                        </button>
                        {copied && (
                          <div className="copied-message text-[0.8rem]">
                            Copied to clipboard!
                          </div>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={generatePassword}
                        className="pt-2 text-blue-500 underline text-[0.9rem]"
                      >
                        Generate Password
                      </button>
                    </div>
                  </div>
                </SmoothList>
                <SmoothList delay={500} transitionDuration={600} visible>
                  <div className="flex justify-between">
                    <div className="pt-3 w-[49%]">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Phone Number <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        placeholder="0721001122"
                      />
                    </div>
                    <div className="pt-3 w-[49%]">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Website (Optional)
                      </label>
                      <input
                        type="text"
                        name="website"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        placeholder="www.example.com"
                      />
                    </div>
                  </div>
                  {/* <div class="relative mb-4 flex flex-wrap items-stretch border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field">
                    <span
                      class="flex font-Inter items-center whitespace-nowrap rounded-s border border-e-0 border-solid border-gray-400 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-surface dark:border-white/10 dark:text-gray-400"
                      id="basic-addon1"
                    >
                      https://
                    </span>
                    <input
                      type="text"
                      class="relative m-0 block flex-auto rounded-e-md border border-solid border-neutral-600 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-[#BF00FF] focus:shadow-inset focus:outline-none motion-reduce:transition-none dark:border-white/10 dark:text-black dark:placeholder:text-neutral-600 dark:autofill:shadow-autofill dark:focus:border-primary input-field"
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div> */}
                </SmoothList>
                <SmoothList delay={600} transitionDuration={600} visible>
                  <div className="flex justify-between">
                    <div className="pt-3 w-[49%]">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Office Location <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        placeholder="Address"
                      />
                    </div>
                    <div className="pt-3 w-[49%]">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Postal Code <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name="zipCode"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                        className="mt-2 appearance-none font-Inter block px-3 py-2 border w-full border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        placeholder="00100"
                      />
                    </div>
                  </div>
                </SmoothList>
                <SmoothList delay={700} transitionDuration={600} visible>
                  <div className="pt-3">
                    <label className="pb-2 font-Inter text-[0.9rem]">
                      Sacco short descriptions{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      cols="30"
                      rows="3"
                      type="text"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                      placeholder="Enter sacco description..."
                    ></textarea>
                  </div>
                </SmoothList>
                <SmoothList delay={800} transitionDuration={600} visible>
                  <div className="pt-3">
                    <label className="pb-2 font-Inter text-[0.9rem]">
                      Upload Logo <span className="text-red-500">*</span>
                    </label>
                    {/* Avatar start */}
                    <div>
                      <label
                        htmlFor="avatar"
                        className="block text-sm font-medium text-gray-700"
                      ></label>
                      <div className="mt-2 flex items-center">
                        <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                          {avatar ? (
                            <img
                              src={URL.createObjectURL(avatar)}
                              alt="avatar"
                              className="h-full w-full object-cover rounded-full"
                            />
                          ) : (
                            <RxAvatar className="h-8 w-8" />
                          )}
                        </span>
                        {/* Input file start */}
                        <label
                          htmlFor="file-input"
                          className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                        >
                          <span>Upload logo</span>
                          <input
                            type="file"
                            name="avatar"
                            id="file-input"
                            accept=".jpg,.jpeg,.png"
                            onChange={handleFileInputChange}
                            className="sr-only"
                          />
                        </label>
                        {/* Input file end */}
                      </div>
                    </div>
                    {/* Avatar end */}
                    <br />
                    <div className="pt-10">
                      <div className="flex">
                        <button className="group relative w-full h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter">
                          Cancel
                        </button>
                        <div className="m-1"></div>
                        <button
                          type="submit"
                          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter"
                        >
                          Add sacco
                        </button>
                      </div>
                    </div>
                  </div>
                </SmoothList>
              </form>
            </div>
          </div>
        )}
        {openDelete && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[25%] min-h-[20vh] bg-white rounded-xl shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <IoCloseOutline
                  size={20}
                  onClick={() => setOpenDelete(false)}
                />
              </div>
              <h3 className="text-[18px] text-center py-5 font-Inter text-[#000000cb]">
                Are you sure you wanna delete this Sacco?
              </h3>
              <div className="w-full flex items-center justify-center">
                <button
                  onClick={() => setOpenDelete(false)}
                  className="group relative w-[150px] h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                >
                  Cancel
                </button>
                <div className="m-1"></div>
                <button
                  onClick={() => setOpenDelete(false) || handleDelete(userId)}
                  className="group relative font-Inter w-[170px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#ff0015] hover:bg-[#000]"
                >
                  Confirm Deletion
                </button>
              </div>
            </div>
          </div>
        )}
        {selectedUser && (
          <div
            className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
              closing ? "pointer-events-none" : ""
            }`}
            onClick={closeMenu}
          >
            <div
              className={`side-menu ${closing ? "closed" : ""}`}
              onClick={(e) => e.stopPropagation()}
              style={{
                marginTop: "10px",
                marginRight: "15px",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="w-full flex justify-end p-4">
                <IoClose
                  size={24}
                  className="cursor-pointer"
                  onClick={closeMenu}
                />
              </div>
              <div className="px-5">
                <div
                  className="w-full rounded-xl mt-5"
                  style={{ border: "2px solid #eee" }}
                >
                  <SmoothList delay={300}>
                    <div className="flex items-center justify-between px-4 py-4 rounded-lg bg-gray-50 m-2">
                      <div className="flex items-center">
                        {selectedUser.avatar ? (
                          <img
                            src={`${backend_url}${selectedUser.avatar}`}
                            className="w-[80px] h-[80px] rounded-full object-cover"
                            alt="profile img"
                          />
                        ) : (
                          <Avatar
                            alt={selectedUser.name}
                            style={{
                              width: "80px",
                              height: "80px",
                              marginRight: "10px",
                              borderRadius: "50px",
                              backgroundColor: "#dfdfdf",
                            }}
                          >
                            <span className="font-Inter p-2 text-[#000] text-[1.5rem] font-medium">
                              {getInitials(selectedUser.name)}
                            </span>
                          </Avatar>
                        )}
                        <div className="block ml-6 ">
                          <h5 className="font-Inter font-medium text-[1.2rem]">
                            {selectedUser.name}
                          </h5>
                          <h5 className="font-Inter font-normal text-[0.9rem] text-gray-500 capitalize">
                            {selectedUser.role}
                          </h5>
                        </div>
                      </div>
                      <div className="flex"></div>
                    </div>
                  </SmoothList>
                  <div className="block px-4 py-4 ">
                    <div>
                      <SmoothList delay={400}>
                        <div className="w-4/12">
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-2 text-gray-500">
                            Email address
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.email}
                          </h5>
                        </div>
                      </SmoothList>
                      <SmoothList delay={500}>
                        <div>
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-1 text-gray-500">
                            Phone
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            +254{selectedUser.phoneNumber}
                          </h5>
                        </div>
                      </SmoothList>
                    </div>
                    <div>
                      <SmoothList delay={600}>
                        <div className="w-4/12">
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-2 text-gray-500">
                            Address
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.address}
                          </h5>
                        </div>
                      </SmoothList>
                      <SmoothList delay={700}>
                        <div>
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-1 text-gray-500">
                            Postal Code
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.zipCode}
                          </h5>
                        </div>
                      </SmoothList>
                    </div>
                    <div>
                      <SmoothList delay={800}>
                        <div className="w-12/12">
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-2 text-gray-500">
                            Description
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.description}
                          </h5>
                        </div>
                      </SmoothList>
                      <SmoothList delay={900}>
                        <div>
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-1 text-gray-500">
                            Joined On
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.joinedAt}
                          </h5>
                        </div>
                      </SmoothList>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllSaccos;
