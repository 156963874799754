import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { formatCurrency } from "../../Utils/formatCurrency";
import { format, parseISO } from "date-fns";
import { MdTrackChanges } from "react-icons/md";
import { Link } from "react-router-dom";
import { backend_url } from "../../server";
import { DataGrid } from "@material-ui/data-grid";
import { IoCloseOutline } from "react-icons/io5";
import SmoothList from "react-smooth-list";

const TrackAllOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const [open, setOpen] = useState(false);
  const [openTracking, setOpenTracking] = useState(null);
  const [closing, setClosing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpenTracking(false);
      setClosing(false);
      setOpen(false);
    }, 300);
  };

  const openMenu = (order) => {
    setOpenTracking(order);
    setOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      minWidth: 150,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            #{params.row.id.slice(0, 8).toUpperCase()}
          </div>
        );
      },
    },

    {
      field: "cart",
      headerName: "Product",
      minWidth: 150,
      type: "string",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.cart.map((item, index) => (
              <img
                key={index}
                src={`${backend_url}${item.images[0]}`} // assuming you want to display the first image
                alt={item.name}
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "10px",
                  borderRadius: "8px",
                }}
              />
            ))}
          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.5,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <span
            className={
              status === "Delivered"
                ? "color: text-green-600"
                : "color: text-red-600"
            }
          >
            {status}
          </span>
        );
      },
    },
    {
      field: "paidAt",
      headerName: "Date Purchased",
      type: "string",
      minWidth: 100,
      flex: 0.6,
    },

    {
      field: "itemsQty",
      headerName: "Qty",
      type: "number",
      minWidth: 80,
      flex: 0.3,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 100,
      flex: 0.6,
    },

    {
      field: " ",
      flex: 0.5,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => openMenu(params.row)}
              className="group relative w-full h-[35px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-[50px] text-gray-700 bg-transparent hover:bg-gray-100 font-Inter"
            >
              <MdTrackChanges size={20} />
            </button>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: formatCurrency(item.totalPrice),
        status: item.status,
        user: item.user,
        cart: item.cart,
        paidAt: format(parseISO(item.paidAt), "dd MMM yyyy"),
      });
    });

  return (
    <div className="w-full p-8 bg-white pt-[80px]">
      <div className="w-full bg-white">
        <h3 className="text-[24px] font-SplineSans pb-10 font-semibold px-5">
          Track Orders
        </h3>
        <div className="w-full min-h-[45vh] bg-white rounded-lg px-5">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            style={{ fontFamily: "Inter" }}
          />
        </div>
        {openTracking && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[30%] min-h-[20vh] bg-white rounded-xl shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <IoCloseOutline
                  size={20}
                  onClick={() => setOpenTracking(false)}
                />
              </div>
              <div className="w-full flex justify-center items-center">
                {" "}
                <>
                  <SmoothList>
                    {openTracking.status === "Processing" ? (
                      <h1 className="text-[16px] font-Inter pb-4">
                        Your Order is being Processed.
                      </h1>
                    ) : openTracking.status ===
                      "Transferred to delivery partner" ? (
                      <h1 className="text-[16px] font-Inter pb-4">
                        Your Order is on the way to our Delivery Partner.
                      </h1>
                    ) : openTracking.status === "Delivery" ? (
                      <h1 className="text-[16px] font-Inter pb-4">
                        Your Order is on the way with our Delivery Partner.
                      </h1>
                    ) : openTracking.status === "Received" ? (
                      <h1 className="text-[16px] font-Inter pb-4">
                        Your Order is in your city. Our Delivery Partner will
                        deliver it.
                      </h1>
                    ) : openTracking.status === "On the way" ? (
                      <h1 className="text-[16px] font-Inter pb-4">
                        Our Delivery Partner is on the way to deliver your
                        order.
                      </h1>
                    ) : openTracking.status === "Delivered" ? (
                      <h1 className="text-[16px] font-Inter pb-4">
                        Your order has been Delivered!
                      </h1>
                    ) : openTracking.status === "Processing refund" ? (
                      <h1 className="text-[16px] font-Inter pb-4">
                        Your refund is being Processed!
                      </h1>
                    ) : openTracking.status === "Refund Success" ? (
                      <h1 className="text-[16px] font-Inter pb-4">
                        Your Refund has been Processed!
                      </h1>
                    ) : null}
                  </SmoothList>
                </>
              </div>
              <div className="w-full flex items-center justify-center">
                <button
                  onClick={() => setOpenTracking(false)}
                  className="group relative w-[150px] h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                >
                  Close
                </button>
                <div className="m-1"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackAllOrders;
