import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Lottie from "react-lottie";
import animationData from "../Assests/animations/order.json";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import { Link, useParams } from "react-router-dom";

const OrderSuccessPage = () => {
  return (
    <div>
      <Header />
      <Success />
      <Footer />
    </div>
  );
};

const Success = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const { id } = useParams();

  const data = orders && orders.find((item) => item._id === id);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="w-5/12 mx-auto 800px:w-80%">
      <div className="border-2 border-gray-100 rounded-xl mt-10">
        <div className="flex flex-col justify-center items-center ">
          <Lottie options={defaultOptions} width={200} height={200} />
          <h5 className="text-center mb-4 text-[25px] text-[#000] font-Inter">
            Thanks for your order!
          </h5>
          <p className="text-[14px] font-Inter text-gray-500 pb-4">
            The order details have been sent to {user?.email}
          </p>
        </div>
        <div className="flex justify-center items-center">
          <span className="border-b w-10/12 px-4"></span>
        </div>
        <br />
        <div className="ml-14 mr-14 mb-10">
          <Link to={"/products"}>
            <button className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter">
              Continue shopping
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccessPage;
