import React, { useState } from "react";
import styles from "../../styles/styles";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import axios from "axios";
import { server } from "../../server";
import { backend_url } from "../../server";
import { toast } from "react-toastify";
import { formatCurrency } from "../../Utils/formatCurrency";
import { addTocart, removeFromCart } from "../../redux/actions/cart";

const Checkout = () => {
  const { user } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [userInfo, setUserInfo] = useState(true);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [zipCode, setZipCode] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paymentSubmit = () => {
    if (
      address1 === "" ||
      address2 === "" ||
      zipCode === null ||
      country === "" ||
      city === ""
    ) {
      toast.error("Please choose your delivery address!");
    } else {
      const shippingAddress = {
        address1,
        address2,
        zipCode,
        country,
        city,
      };

      const orderData = {
        cart,
        totalPrice,
        subTotalPrice,
        shipping,
        discountPrice,
        shippingAddress,
        user,
      };

      // update local storage with the updated orders array
      localStorage.setItem("latestOrder", JSON.stringify(orderData));
      navigate("/payment");
    }
  };

  //remove from cart
  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  // this is shipping cost variable
  const shipping = 500; // 10%

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = couponCode;

    await axios.get(`${server}/coupon/get-coupon-value/${name}`).then((res) => {
      const shopId = res.data.couponCode?.shopId;

      const couponCodeValue = res.data.couponCode?.value;

      if (res.data.couponCode !== null) {
        const isCouponValid =
          cart && cart.filter((item) => item.shopId === shopId);

        if (isCouponValid.length === 0) {
          toast.error("Coupon code is not valid for this shop");
          setCouponCode("");
        } else {
          const eligiblePrice = isCouponValid.reduce(
            (acc, item) => acc + item.qty * item.discountPrice,
            0
          );
          const discountPrice = (eligiblePrice * couponCodeValue) / 100;
          setDiscountPrice(discountPrice);
          setCouponCodeData(res.data.couponCode);
          setCouponCode("");
        }
      }
      if (res.data.couponCode === null) {
        toast.error("Coupon code doesn't exists!");
        setCouponCode("");
      }
    });
  };

  const discountPercentenge = couponCodeData ? discountPrice : "";

  const totalPrice = couponCodeData
    ? (subTotalPrice + shipping - discountPercentenge).toFixed(2)
    : (subTotalPrice + shipping).toFixed(2);

  console.log(discountPercentenge);

  return (
    <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
        <div className="w-full 800px:w-[65%]">
          <ShippingInfo
            user={user}
            country={country}
            setCountry={setCountry}
            city={city}
            setCity={setCity}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            address1={address1}
            setAddress1={setAddress1}
            address2={address2}
            setAddress2={setAddress2}
            zipCode={zipCode}
            setZipCode={setZipCode}
          />
          <div className="mt-6">
            <button
              onClick={paymentSubmit}
              className="group relative w-[250px] h-[45px] text-[16px] flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
            >
              Continue
            </button>
          </div>
        </div>
        <div
          className="w-full 800px:w-[35%] 800px:mt-0 mt-8 rounded-xl bg-white px-7"
          style={{ border: "2px solid #eee" }}
        >
          <div className="w-full py-6 border-b">
            <h2 className="font-Inter font-semibold text-[1.2rem] text-[#000] ">
              Your Order
            </h2>
          </div>
          <div className="w-full">
            {cart &&
              cart.map((i, index) => {
                return (
                  <CartSingle
                    data={i}
                    key={index}
                    quantityChangeHandler={quantityChangeHandler}
                    removeFromCartHandler={removeFromCartHandler}
                  />
                );
              })}
          </div>
          <CartData
            handleSubmit={handleSubmit}
            totalPrice={totalPrice}
            shipping={shipping}
            subTotalPrice={subTotalPrice}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            discountPercentenge={discountPercentenge}
          />
        </div>
      </div>
    </div>
  );
};

const ShippingInfo = ({
  user,
  country,
  setCountry,
  city,
  setCity,
  userInfo,
  setUserInfo,
  address1,
  setAddress1,
  address2,
  setAddress2,
  zipCode,
  setZipCode,
}) => {
  return (
    <div
      className="w-full 800px:w-[95%] bg-white rounded-xl p-5 pb-8"
      style={{ border: "2px solid #eee" }}
    >
      <h5 className="text-[18px] font-[500] font-Inter">Delivery Address</h5>
      <br />
      <form>
        <div className="w-full flex pb-3">
          <div className="w-[50%]">
            <label className="block pb-2 font-Inter">Full Name</label>
            <input
              type="text"
              value={user && user.name}
              required
              className={`${styles.input} !w-[95%] font-Inter h-[40px] focus:border-[#BF00FF] sm:text-sm input-field rounded-lg px-2 border-2`}
            />
          </div>
          <div className="w-[50%]">
            <label className="block pb-2 font-Inter">Email Address</label>
            <input
              type="email"
              value={user && user.email}
              required
              className={`${styles.input} font-Inter h-[40px] focus:border-[#BF00FF] sm:text-sm input-field rounded-lg px-2 border-2`}
            />
          </div>
        </div>

        <div className="w-full flex pb-3">
          <div className="w-[50%]">
            <label className="block pb-2 font-Inter">Phone Number</label>
            <input
              type="number"
              required
              value={user && user.phoneNumber}
              className={`${styles.input} !w-[95%] font-Inter h-[40px] focus:border-[#BF00FF] sm:text-sm input-field rounded-lg px-2 border-2`}
            />
          </div>
          <div className="w-[50%]">
            <label className="block pb-2 font-Inter">Zip Code</label>
            <input
              type="number"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              required
              className={`${styles.input} font-Inter h-[40px] focus:border-[#BF00FF] sm:text-sm input-field rounded-lg px-2 border-2`}
            />
          </div>
        </div>

        <div className="w-full flex pb-3">
          <div className="w-[50%]">
            <label className="block pb-2 font-Inter">Country</label>
            <select
              className="w-[95%] h-[40px] rounded-[8px] focus:border-[#BF00FF] sm:text-sm input-field px-2 border-2"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option className="block pb-2 font-Inter" value="">
                Choose your country
              </option>
              {Country &&
                Country.getAllCountries().map((item) => (
                  <option
                    key={item.isoCode}
                    value={item.isoCode}
                    className="font-Inter"
                  >
                    <span className="font-Inter">{item.name}</span>
                  </option>
                ))}
            </select>
          </div>
          <div className="w-[50%]">
            <label className="block pb-2 font-Inter">City</label>
            <select
              className="w-[95%] h-[40px] rounded-[8px] focus:border-[#BF00FF] sm:text-sm input-field px-2 border-2"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            >
              <option className="block pb-2 font-Inter" value="">
                Choose your City
              </option>
              {State &&
                State.getStatesOfCountry(country).map((item) => (
                  <option
                    key={item.isoCode}
                    value={item.isoCode}
                    className="font-Inter"
                  >
                    <span className="font-Inter">{item.name}</span>
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="w-full flex pb-3">
          <div className="w-[50%]">
            <label className="block pb-2 font-Inter">Address 1</label>
            <input
              type="address"
              required
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              className={`${styles.input} !w-[95%] h-[40px] font-Inter focus:border-[#BF00FF] sm:text-sm input-field rounded-lg px-2 border-2`}
            />
          </div>
          <div className="w-[50%]">
            <label className="block pb-2 font-Inter">Address 2</label>
            <input
              type="address"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              required
              className={`${styles.input} font-Inter h-[40px] focus:border-[#BF00FF] sm:text-sm input-field rounded-lg px-2 border-2`}
            />
          </div>
        </div>

        <div></div>
      </form>
      <div className="mt-2 rounded-lg bg-[#f2f0ea] p-4">
        <h5
          className="text-[16px] cursor-pointer inline-block font-Inter text-gray-700"
          onClick={() => setUserInfo(!userInfo)}
        >
          Choose from saved address
        </h5>
        {userInfo && (
          <div
            className="mt-2 bg-white rounded-lg p-2"
            style={{ border: "2px solid #efefef" }}
          >
            {user &&
              user.addresses.map((item, index) => (
                <div className="w-full flex">
                  <input
                    type="radio"
                    className="mr-3 font-Inter"
                    value={item.addressType}
                    onClick={() =>
                      setAddress1(item.address1) ||
                      setAddress2(item.address2) ||
                      setZipCode(item.zipCode) ||
                      setCountry(item.country) ||
                      setCity(item.city)
                    }
                  />
                  <h2 className="font-Inter">{item.addressType}</h2>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

const CartData = ({
  handleSubmit,
  totalPrice,
  shipping,
  subTotalPrice,
  couponCode,
  setCouponCode,
  discountPercentenge,
}) => {
  return (
    <div className="w-full py-5 pb-8">
      <div className="flex justify-between pt-3">
        <h3 className="text-[14px] font-[400] text-[#000000a4] font-Inter">
          Sub Total
        </h3>
        <h5 className="text-[14px] font-[600] font-Inter mb-3">
          {formatCurrency(totalPrice - shipping.toFixed(2))}
        </h5>
      </div>
      <div className="flex justify-between pt-3">
        <h3 className="text-[14px] font-[400] text-[#000000a4] font-Inter">
          Delivery
        </h3>
        <h5 className="text-[14px] font-[600] font-Inter">
          {formatCurrency(shipping.toFixed(2))}
        </h5>
      </div>
      <br />
      <div className="flex justify-between pb-6">
        <h3 className="text-[14px] font-[400] text-[#000000a4] font-Inter">
          Discount
        </h3>
        <h5 className="text-[14px] font-[600] font-Inter">
          -{" "}
          {formatCurrency(
            discountPercentenge ? discountPercentenge.toString() : null
          )}
        </h5>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex">
          <input
            type="text"
            className={`${styles.input} h-[40px] pl-2 font-Inter rounded-lg mr-1 focus:border-[#BF00FF] sm:text-sm input-field`}
            placeholder="Promo code"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            required
          />
          <input
            className={`w-full h-[40px] border-2 border-[#f63b60] text-center text-[#f63b60] rounded-[8px] cursor-pointer font-Inter ml-1`}
            required
            value="Apply code"
            type="submit"
          />
        </div>
      </form>
      <br />
      <div className="flex justify-between p-6 bg-[#f2f0ea] rounded-xl mt-4">
        <h3 className="text-[16px] font-[400] text-[#000000a4] font-Inter">
          Total
        </h3>
        <h5 className="text-[18px] font-[600] font-Inter">
          {formatCurrency(totalPrice)}
        </h5>
      </div>
    </div>
  );
};

const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {
  const [value, setValue] = useState(data.qty);
  const totalPrice = data.discountPrice * value;

  const increment = (data) => {
    if (data.stock < value) {
      toast.error("Product stock limited!");
    } else {
      setValue(value + 1);
      /* The line `const updateCartData = { ...data, qty: value + 1 };` is creating a new object
              `updateCartData` by spreading the properties of the `data` object and adding a new
              property `qty` with the value of `value + 1`. This is typically used to update the
              quantity of an item in the cart. */
      const updateCartData = { ...data, qty: value + 1 };
      quantityChangeHandler(updateCartData);
    }
  };

  // Decrement
  const decrement = (data) => {
    setValue(value === 1 ? 1 : value - 1);
    const updateCartData = { ...data, qty: value === 1 ? 1 : value - 1 };
    quantityChangeHandler(updateCartData);
  };

  return (
    <>
      <div className="border-b py-4 bg-[#fff] ">
        <div className="w-full flex items-center">
          <img
            src={`${backend_url}${data?.images[0]}`}
            className="w-[80px] h-min rounded-[6px] object-cover"
            alt="side card"
          />

          <div className="pl-[15px]">
            <h1 className="font-Inter font-bold text-[1.2rem]">{data.name}</h1>
            <h4 className="font-[400] text-[0.8rem] text-[#1d1d1d82] font-Inter">
              {" "}
              <span className="text-[#3a3a3a] font-Inter font-semibold">
                {formatCurrency(data.discountPrice)}
              </span>{" "}
              x {value}
            </h4>
            {/* <h4 className="font-[400] text-[17px] pt-[3px]  text-[#d02222] font-Roboto ">
              {formatCurrency(totalPrice)}
            </h4> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
