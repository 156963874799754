import React from "react";
import { AiOutlineGift } from "react-icons/ai";
import {
  IoGiftOutline,
  IoBagHandleOutline,
  IoFileTrayFullOutline,
} from "react-icons/io5";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { backend_url } from "../../../server";
import { Avatar } from "@material-ui/core";

const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);

  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials.toUpperCase();
  };

  return (
    <div className="w-full h-[80px] bg-white sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div></div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <Link to="/merchant/discounts" className="800px:block hidden">
            <IoGiftOutline
              color="#555"
              size={24}
              className="mx-3 cursor-pointer"
            />
          </Link>
          <Link to="/merchant/products" className="800px:block hidden">
            <IoBagHandleOutline
              color="#555"
              size={24}
              className="mx-3 cursor-pointer"
            />
          </Link>
          <Link to="/merchant/orders" className="800px:block hidden">
            <IoFileTrayFullOutline
              color="#555"
              size={24}
              className="mx-3 cursor-pointer"
            />
          </Link>
          {/* <Link to="/dashboard-messages" className="800px:block hidden">
            <BiMessageSquareDetail
              color="#555"
              size={30}
              className="mx-5 cursor-pointer"
            />
          </Link> */}
          <div className="flex items-center bg-[#fbf7ed] py-2 px-3 rounded-[50px] ml-2">
            {seller?.avatar ? (
              <img
                src={`${backend_url}${seller?.avatar}`}
                alt=""
                className="w-[30px] h-[30px] rounded-full mr-2"
              />
            ) : (
              <Avatar
                alt={seller?.name}
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "10px",
                  borderRadius: "50px",
                  backgroundColor: "#dfdfdf",
                }}
              >
                <span className="font-Inter p-2 text-[#000] text-[0.8rem] font-medium">
                  {getInitials(seller?.avatar)}
                </span>
              </Avatar>
            )}
            <span className="font-Inter">{seller?.name}</span>
          </div>
          {/* <div className="flex items-center bg-[#fbf7ed] py-2 px-3 rounded-[50px]">
            <img
              src={`${backend_url}${seller.avatar}`}
              alt=""
              className="w-[30px] h-[30px] rounded-full object-cover mr-2"
            />
            <span className="font-Inter">{seller.name}</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
