import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSaccoUsers, loadSacco } from "../../redux/actions/user";
import { backend_url, server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import { format, parseISO } from "date-fns";
import SmoothList from "react-smooth-list";
import {
  IoCamera,
  IoClose,
  IoLocate,
  IoLockClosedOutline,
  IoLockOpenOutline,
  IoPersonCircleOutline,
} from "react-icons/io5";

const SaccoSettings = () => {
  const { sacco, error, successMessage } = useSelector((state) => state.sacco);
  const { users } = useSelector((state) => state.user);
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState(sacco && sacco.name);
  const [email, setEmail] = useState(sacco && sacco.email);
  const [description, setDescription] = useState(
    sacco && sacco.description ? sacco.description : ""
  );
  const [address, setAddress] = useState(sacco && sacco.address);
  const [phoneNumber, setPhoneNumber] = useState(sacco && sacco.phoneNumber);
  const [zipCode, setZipcode] = useState(sacco && sacco.zipCode);
  const [website, setWebsite] = useState(sacco && sacco.website);
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [openButton, setOpenButton] = useState(false);
  const [closing, setClosing] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSaccoUsers(sacco._id));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/sacco/update-sacco-info`,
        {
          name,
          address,
          zipCode,
          phoneNumber,
          description,
          website,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Sacco info updated succesfully!");
        dispatch(loadSacco());
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  // Image updated
  const handleImage = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setAvatar(file);

    const formData = new FormData();

    formData.append("image", e.target.files[0]);

    await axios
      .put(`${server}/sacco/update-shop-avatar`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((res) => {
        dispatch(loadSacco());
        toast.success("Avatar updated successfully!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Password is updated");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [error, successMessage]);

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setOpenPassword(false);
      setOpenProfile(false);
      setClosing(false);
    }, 300);
  };

  return (
    <div className="w-full bg-white pt-[10px]">
      <h3 className="text-[24px] font-SplineSans pb-10 font-semibold px-12">
        Sacco Profile
      </h3>
      <div className="w-full">
        <div className="w-full px-12">
          <div
            className="flex items-center justify-between px-4 py-4 rounded-xl bg-purple-50"
            style={{ border: "2px solid #eee" }}
          >
            <div className="flex items-center">
              <img
                src={
                  avatar
                    ? URL.createObjectURL(avatar)
                    : `${backend_url}/${sacco.avatar}`
                }
                alt=""
                className="w-[80px] h-[80px] rounded-full cursor-pointer"
              />
              <div className="block ml-6 ">
                <h5 className="font-Inter font-medium text-[1.2rem] pb-1">
                  {sacco && sacco.name}
                </h5>
                <h5 className="font-Inter font-medium text-[0.9rem] text-gray-500">
                  {sacco && sacco.role}
                </h5>
                <h5 className="font-Inter font-normal text-[0.8rem] text-gray-400">
                  {sacco && sacco.address}
                </h5>
              </div>
            </div>
            <div className="flex">
              <button
                onClick={() => setOpenPassword(true)}
                className="text-[#fc5252] font-[400] flex justify-center items-center font-Inter text-[14px] w-[120px] h-[30px] border-2 border-[#d3d3d3] text-center rounded-[8px]"
              >
                Password <IoLockOpenOutline />
              </button>
            </div>
          </div>
        </div>
        {openPassword && (
          <div
            className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
              closing ? "pointer-events-none" : ""
            }`}
          >
            <div
              className={`side-menu ${closing ? "closed" : ""}`}
              onClick={(e) => e.stopPropagation()}
              style={{
                marginTop: "10px",
                marginRight: "15px",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="w-full flex justify-end p-4">
                <IoClose
                  size={24}
                  className="cursor-pointer"
                  onClick={closeMenu}
                />
              </div>
              <h5 className="text-[18px] font-Inter text-left pl-4 font-medium flex items-center">
                <IoLockClosedOutline size={15} />{" "}
                <span className="pl-2">Change Password</span>
              </h5>
              <div className="w-full">
                <form
                  aria-required
                  onSubmit={passwordChangeHandler}
                  className="p-4"
                >
                  <SmoothList delay={300} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Old password
                      </label>
                      <input
                        type="password"
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={400} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={500} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Confirm new password
                      </label>
                      <input
                        type="password"
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={600} transitionDuration={600} visible>
                    <div className="pt-8">
                      <div className="flex">
                        <button
                          onClick={closeMenu}
                          type="reset"
                          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                        >
                          Cancel
                        </button>
                        <div className="m-1"></div>
                        <button
                          type="submit"
                          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter"
                        >
                          Change password
                        </button>
                      </div>
                    </div>
                  </SmoothList>
                </form>
              </div>
            </div>
          </div>
        )}
        <br />

        {/* sacco info */}
        <div className="w-full px-12">
          <div
            className="px-4 py-4 rounded-xl bg-purple-50"
            style={{ border: "2px solid #eee" }}
          >
            <div className="flex justify-between">
              <h5 className="font-Inter font-semibold text-[1rem] pb-5">
                Sacco Information
              </h5>
              <button
                onClick={() => setOpenProfile(true)}
                className="text-[#646464] font-[400] flex justify-center items-center font-Inter text-[14px] w-[80px] h-[30px] border-2 border-[#d3d3d3] text-center rounded-[8px]"
              >
                Edit <AiOutlineEdit />
              </button>
            </div>

            <div className="block">
              <div className="flex mb-4">
                <div className="w-4/12">
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Sacco Name
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {sacco && sacco.name}
                  </h5>
                </div>
                <div>
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Email
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {sacco && sacco.email}
                  </h5>
                </div>
              </div>
              <div className="flex mb-4">
                <div className="w-4/12">
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Description
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {sacco && sacco.description}
                  </h5>
                </div>
                <div>
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Phone
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    +254 {sacco && sacco.phoneNumber}
                  </h5>
                </div>
              </div>
              <div className="flex mb-4">
                <div className="w-4/12">
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Sacco Address
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {sacco && sacco.address}
                  </h5>
                </div>
                <div>
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Postal Code
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {sacco && sacco.zipCode}
                  </h5>
                </div>
              </div>
              <div className="flex mb-4">
                <div className="w-4/12">
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Joined On
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {format(parseISO(sacco && sacco.createdAt), "dd MMMM yyyy")}
                  </h5>
                </div>
                <div>
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Members
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {users && users.length}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {openProfile && (
          <div
            className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
              closing ? "pointer-events-none" : ""
            }`}
          >
            <div
              className={`side-menu ${closing ? "closed" : ""}`}
              onClick={(e) => e.stopPropagation()}
              style={{
                marginTop: "10px",
                marginRight: "15px",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="w-full flex justify-end p-4">
                <IoClose
                  size={24}
                  className="cursor-pointer"
                  onClick={closeMenu}
                />
              </div>
              <h5 className="text-[18px] font-Inter text-left pl-4 font-medium flex items-center">
                <IoPersonCircleOutline size={18} />{" "}
                <span className="pl-2">Update Profile</span>
              </h5>
              <SmoothList delay={300} transitionDuration={600} visible>
                <div className="flex justify-center w-full">
                  <div className="relative">
                    <img
                      src={`${backend_url}${sacco?.avatar}`}
                      className="w-[80px] h-[80px] rounded-full object-cover"
                      alt="profile img"
                    />
                    <div>
                      <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center absolute bottom-[-1px] right-[-3px] cursor-pointer">
                        <input
                          type="file"
                          id="image"
                          className="hidden"
                          onChange={handleImage}
                        />
                        <label htmlFor="image">
                          <IoCamera className="cursor-pointer" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </SmoothList>
              <br />

              <div className="w-full px-5">
                <form onSubmit={handleSubmit} aria-required={true}>
                  <SmoothList delay={400} transitionDuration={600} visible>
                    <div>
                      <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                        Sacco Name
                      </label>
                      <input
                        type="text"
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={500} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Email Address
                      </label>
                      <input
                        type="text"
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={600} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Phone Number
                      </label>
                      <input
                        type="number"
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={700} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Sacco Address (Office)
                      </label>
                      <input
                        type="text"
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={800} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Postal Code
                      </label>
                      <input
                        type="text"
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        value={zipCode}
                        onChange={(e) => setZipcode(e.target.value)}
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={900} transitionDuration={600} visible>
                    <div className="py-8">
                      <div className="flex">
                        <button
                          onClick={closeMenu}
                          type="reset"
                          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                        >
                          Cancel
                        </button>
                        <div className="m-1"></div>
                        <button
                          type="submit"
                          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter"
                        >
                          Update profile
                        </button>
                      </div>
                    </div>
                  </SmoothList>
                </form>
              </div>
            </div>
          </div>
        )}
        <br />
      </div>
    </div>
  );
};

export default SaccoSettings;
