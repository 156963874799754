// DeviceDetector.jsx
import React from "react";
import ComingSoon from "./ComingSoon";
import App from "./App";

const DeviceDetector = () => {
  // Function to check if the device is mobile
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  return <div>{isMobileDevice() ? <ComingSoon /> : <App />}</div>;
};

export default DeviceDetector;
