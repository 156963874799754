// ComingSoon.jsx
import React from "react";
import { IoPhonePortrait } from "react-icons/io5";
import SmoothList from "react-smooth-list";

const ComingSoon = () => {
  // Function to handle button click and redirect
  const handleRedirect = () => {
    window.location.href = "https://lipable.com";
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100 px-8">
      <div className="flex flex-col items-center">
        <SmoothList delay={300}>
          <IoPhonePortrait size={70} />
        </SmoothList>
        <SmoothList delay={400}>
          <h1 className="text-xl font-normal font-Inter pt-9">
            Mobile Phone Version
          </h1>
        </SmoothList>
        <SmoothList delay={500}>
          <h1 className="text-xl font-bold font-SplineSans pt-3">
            Coming Soon
          </h1>
        </SmoothList>
        <SmoothList delay={600}>
          <button
            onClick={handleRedirect}
            className="group mt-3 relative w-[150px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
          >
            Main Site
          </button>
        </SmoothList>
      </div>
    </div>
  );
};

export default ComingSoon;
