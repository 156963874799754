import React from "react";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";
import DashboardHero from "../../components/Shop/DashboardHero";

const ShopDashboardPage = () => {
  return (
    <div>
      <div className="fixed ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
        <DashboardHeader />
      </div>

      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[250px] fixed">
          <DashboardSideBar active={1} />
        </div>
        <div className="ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
          <DashboardHero />
        </div>
      </div>
    </div>
  );
};

export default ShopDashboardPage;
