import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency } from "../../Utils/formatCurrency";
import { format, parseISO } from "date-fns";
import {
  IoClose,
  IoEllipsisHorizontalSharp,
  IoEllipsisVerticalSharp,
} from "react-icons/io5";
import { backend_url, server } from "../../server";
import SmoothList from "react-smooth-list";
import { toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import styles from "../../styles/styles";

const AllOrders = () => {
  const { user } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openReview, setOpenReview] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(1);

  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setClosing(false);
      setSelectedOrder(null);
    }, 300);
  };

  const openMenu = (order) => {
    setSelectedOrder(order);
    setOpen(true);
  };

  const { id } = useParams();

  const reviewHandler = async (type) => {
    try {
      const endpoint =
        type === "product"
          ? "/product/create-new-review"
          : "/event/create-new-review-event";

      const res = await axios.put(
        `${server}${endpoint}`,
        {
          user,
          rating,
          comment,
          productId: selectedItem?._id,
          orderId: selectedOrder._id,
        },
        { withCredentials: true }
      );

      toast.success(res.data.message);
      dispatch(getAllOrdersOfUser(user._id));
      setComment("");
      setRating(null);
      setOpenReview(false);
    } catch (error) {
      console.error(error); // Log the error to the console for debugging
      toast.error("An error occurred. Please try again."); // Display a generic error message
    }
  };

  const combinedHandler = async () => {
    if (rating > 1) {
      await reviewHandler("product");
      await reviewHandler("event");
    }
  };

  useEffect(() => {
    console.log("Selected Order:", selectedOrder);
  }, [selectedOrder]);

  // Refund
  const refundHandler = async () => {
    await axios
      .put(`${server}/order/order-refund/${selectedOrder.id}`, {
        status: "Processing refund",
      })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getAllOrdersOfUser(user._id));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      minWidth: 150,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            #{params.row.id.slice(0, 8).toUpperCase()}
          </div>
        );
      },
    },

    // {
    //   field: "user",
    //   headerName: "Customer",
    //   minWidth: 150,
    //   type: "string",
    //   flex: 0.7,
    //   renderCell: (params) => {
    //     return <div className="rowitem">{params.row.user.name}</div>;
    //   },
    // },

    {
      field: "cart",
      headerName: "Product",
      minWidth: 150,
      type: "string",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.cart.map((item, index) => (
              <img
                key={index}
                src={`${backend_url}${item.images[0]}`} // assuming you want to display the first image
                alt={item.name}
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "10px",
                  borderRadius: "8px",
                }}
              />
            ))}
          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.5,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <span
            className={
              status === "Delivered"
                ? "color: text-green-600"
                : "color: text-red-600"
            }
          >
            {status}
          </span>
        );
      },
    },

    {
      field: "paidAt",
      headerName: "Date Purchased",
      type: "string",
      minWidth: 100,
      flex: 0.6,
    },

    {
      field: "itemsQty",
      headerName: "Qty",
      type: "number",
      minWidth: 80,
      flex: 0.3,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 100,
      flex: 0.6,
    },

    {
      field: " ",
      flex: 0.2,
      minWidth: 30,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              className="group relative w-full h-[35px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-[50px] text-gray-700 bg-transparent hover:bg-gray-100 font-Inter"
              onClick={() => openMenu(params.row)}
            >
              <IoEllipsisVerticalSharp size={16} />
            </button>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: formatCurrency(item.totalPrice),
        status: item.status,
        user: item.user,
        cart: item.cart,
        shippingAddress: item.shippingAddress,
        paymentInfo: item.paymentInfo,
        paidAt: format(parseISO(item.paidAt), "dd MMM yyyy"),
        createdAt: format(parseISO(item.paidAt), "MMMM d, yyyy"),
        timeAt: format(parseISO(item.paidAt), "H:MM aa"),
      });
    });

  return (
    <>
      <div className="w-full p-8 bg-white pt-[80px]">
        <div className="w-full bg-white h-[100vh]">
          <h3 className="text-[24px] font-SplineSans pb-10 font-semibold px-5">
            Orders
          </h3>
          <div className="w-full min-h-[45vh] bg-white rounded-lg px-5">
            <DataGrid
              rows={row}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              autoHeight
              style={{ fontFamily: "Inter" }}
            />
          </div>
        </div>
      </div>

      {open && !openReview && (
        <div
          className={`fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-end transition-opacity ${
            closing ? "opacity-0" : "opacity-100"
          }`}
          onClick={closeMenu}
        >
          <div
            className="bg-white w-80 h-full p-4 overflow-y-auto side-menu-wide"
            onClick={(e) => e.stopPropagation()}
            style={{
              marginTop: "10px",
              marginRight: "15px",
              borderRadius: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className="w-full flex justify-end p-4">
              <IoClose
                size={24}
                className="cursor-pointer"
                onClick={closeMenu}
              />
            </div>

            {selectedOrder && (
              <div className="py-4 bg-white p-10 mb-20">
                <SmoothList delay={300} transitionDuration={600} visible>
                  <div className="w=full flex items-center justify-between">
                    <div className="flex items-center">
                      <h1 className=" text-[20px] font-Inter font-semibold">
                        Order ID:{" "}
                        <span className="pl-1 pr-1 font-semibold text-gray-900">
                          #{selectedOrder.id.slice(0, 8).toUpperCase()}
                        </span>{" "}
                      </h1>
                      <h5 className="text-[#00000084] font-Inter flex items-center">
                        {selectedOrder.status ? (
                          <span
                            style={{
                              fontSize: "12px",
                              padding: "3px 8px 3px 8px",
                              backgroundColor: "#fff0e1",
                              borderRadius: "20px",
                              color: "#ff7f00",
                              fontWeight: 400,
                            }}
                          >
                            {selectedOrder.status}
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "12px",
                              padding: "3px 8px 3px 8px",
                              backgroundColor: "#faf0f0",
                              borderRadius: "20px",
                              color: "red",
                              fontWeight: 400,
                            }}
                          >
                            Not Paid
                          </span>
                        )}
                      </h5>
                    </div>
                  </div>
                </SmoothList>
                <SmoothList delay={400} transitionDuration={600} visible>
                  <div className="w-full flex items-center justify-between pt-1">
                    <p className="text-[#00000084] font-Inter">
                      <span className="text-gray-600 text-[12px]">
                        {selectedOrder.createdAt} at {selectedOrder.timeAt}
                      </span>
                    </p>
                  </div>
                </SmoothList>
                <br />
                <br />
                <SmoothList delay={500} transitionDuration={600} visible>
                  <h5 className="text-[#000000d0] font-Inter pb-4 font-medium ">
                    Order Items
                  </h5>
                </SmoothList>
                <SmoothList delay={600} transitionDuration={600} visible>
                  <ul className="rounded-xl bg-[#fbfbfb] p-2 border-2 mb-4">
                    {selectedOrder.cart.map((item, index) => (
                      <div className="w-full flex items-center mb-5">
                        <img
                          src={`${backend_url}/${item.images[0]}`}
                          alt="Product item order img"
                          className="w-[80x] h-[80px] rounded-lg"
                        />
                        <div className="w-full">
                          <h5 className="pl-5 text-[16px] font-Inter">
                            {item.name}
                          </h5>
                          <h5 className="pl-5 text-[16px] text-[#00000091] font-Inter">
                            {formatCurrency(item.discountPrice)} x {item.qty}
                          </h5>
                        </div>
                        {!item.isReviewed &&
                        selectedOrder.status === "Delivered" ? (
                          <button
                            className="group relative w-[230px] h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                            onClick={() =>
                              setOpenReview(true) || setSelectedItem(item)
                            }
                          >
                            Write a review
                          </button>
                        ) : null}
                      </div>
                    ))}
                  </ul>
                </SmoothList>
                {/* Shipping Address */}
                <SmoothList delay={700} transitionDuration={600} visible>
                  <div className="w-full 800px:flex items-start rounded-xl bg-[#ffffff] p-4 border-2 mb-4 mt-8">
                    <div className="w-full 800px:w-[60%]">
                      <h4 className=" text-[14px] font-Inter">
                        Address 1: {selectedOrder.shippingAddress.address1}
                      </h4>
                      <h4 className="text-[14px] font-Inter">
                        Address 2: {selectedOrder.shippingAddress.address2}
                      </h4>
                      <h4 className="text-[14px] font-Inter">
                        Country: {selectedOrder.shippingAddress.country}
                      </h4>
                      <h4 className="text-[14px] font-Inter">
                        City: {selectedOrder.shippingAddress.city}
                      </h4>

                      <h4 className="text-[14px] font-Inter">
                        Phone: +254{selectedOrder.user.phoneNumber}
                      </h4>
                    </div>

                    <div className="w-full 800px:w-[40%]">
                      <h4 className=" text-[16px] font-Inter">
                        Payment Method
                      </h4>
                      <h4 className="text-[16px] font-Inter">
                        <span
                          style={{
                            fontSize: "14px",
                            padding: "3px 8px 3px 8px",
                            backgroundColor: "#ebffee",
                            borderRadius: "20px",
                            color: "#05e66f",
                            fontWeight: 400,
                          }}
                        >
                          {selectedOrder.paymentInfo?.type}
                        </span>
                      </h4>
                      <br />
                      {selectedOrder.status === "Delivered" && (
                        <button
                          onClick={refundHandler}
                          className="group relative w-[150px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
                        >
                          Request Refund
                        </button>
                      )}
                    </div>
                  </div>
                </SmoothList>
                <SmoothList delay={800} transitionDuration={600} visible>
                  <div className="flex justify-between items-center pt-6">
                    <div></div>
                    <div className="text-right">
                      <h5 className="font-Inter text-[1.2rem]">
                        Total: <strong>{selectedOrder.total}</strong>
                      </h5>
                    </div>
                  </div>
                </SmoothList>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Review Popup */}
      {openReview && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-end">
          <div
            className="bg-white w-60 h-full overflow-y-auto side-menu-wide"
            style={{
              marginTop: "10px",
              marginRight: "15px",
              borderRadius: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className="w-full flex justify-end p-3">
              <IoClose
                size={24}
                onClick={() => setOpenReview(false)}
                className="cursor-pointer"
              />
            </div>
            <h2 className="text-[20px] font-[500] font-Inter text-center">
              Give a review
            </h2>
            <div className="w-full flex pt-9 px-6 items-center">
              <img
                src={`${backend_url}/${selectedItem?.images[0]}`}
                alt=""
                className="w-[80px] h-[80px] rounded-lg"
              />
              <div className="w-full">
                <h5 className="pl-5 text-[16px] font-Inter">
                  {selectedItem?.name}
                </h5>
                <h5 className="pl-5 text-[16px] text-[#00000091] font-Inter">
                  {formatCurrency(selectedItem?.discountPrice)} x{" "}
                  {selectedItem?.qty}
                </h5>
              </div>
            </div>
            <br />
            <div className="w-full flex"></div>

            {/* Rating */}
            <h5 className="px-6 text-[16px] font-[500] font-Inter">
              Give a Rating <span className="text-red-500">*</span>
            </h5>
            <div className="flex w-fit px-6 pt-1">
              {[1, 2, 3, 4, 5].map((i) =>
                rating >= i ? (
                  <AiFillStar
                    key={i}
                    className="mr-1 cursor-pointer"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                ) : (
                  <AiOutlineStar
                    key={i}
                    className="mr-1 cursor-pointer"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                )
              )}
            </div>
            <br />
            {/* Comment */}
            <div className="w-full px-6">
              <label className="block text-[16px] font-[500] font-Inter">
                Write a Comment
                <span className="ml-1 font-[400] text-[16px] text-[#00000052] font-Inter">
                  (Optional)
                </span>
              </label>
              <textarea
                name="comment"
                id=""
                cols="20"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="How was your product? write your expresion about it!"
                className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
              ></textarea>
            </div>
            <div className="px-6 pt-9">
              <button
                onClick={rating > 1 ? combinedHandler : null}
                className="group relative w-[150px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#130f0c] hover:bg-[#4d4c4c] font-Inter"
              >
                Submit review
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllOrders;
