import React, { useEffect, useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import { FaAngleRight, FaRegImage } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createProduct } from "../../redux/actions/product";
import {
  apparelData,
  categoriesData,
  clothSizeData,
  colorData,
  shoeSizeData,
  storageData,
  targetGroupData,
} from "../../static/data";
import { toast } from "react-toastify";
import Loader from "../Layout/Loader";
import SmoothList from "react-smooth-list";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Multiselect from "multiselect-react-dropdown";
import Tabs from "@material-ui/core/Tabs";
import { Box, Tab, Typography } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [storageSize, setStorageSize] = useState([]);
  const [shoeSize, setShoeSize] = useState([]);
  const [color, setColor] = useState([]);
  const [clothSize, setClothSize] = useState([]);
  const [tags, setTags] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product created successfully!");
      navigate("/dashboard");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  useEffect(() => {
    // Simulate a network request or some async operation
    setTimeout(() => {
      setIsLoading(false); // Set loading to false after a delay
    }, 2000); // Adjust the timeout duration as needed
  }, []);

  if (isLoading) {
    return <Loader />; // Show loader while loading
  }

  const handleNameChange = (e) => {
    const inputName = e.target.value;
    setName(inputName);
    setSlug(generateSlug(inputName));
  };

  const generateSlug = (name) => {
    return name
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading or trailing hyphens
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelect = (selectedList) => {
    setStorageSize(selectedList);
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    let files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  console.log(images);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.append("images", image);
    });
    newForm.append("name", name);
    newForm.append("slug", slug);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("tags", tags);
    newForm.append("originalPrice", originalPrice);
    newForm.append("discountPrice", discountPrice);
    newForm.append("stock", stock);
    newForm.append("shopId", seller._id);
    dispatch(createProduct(newForm));
  };

  return (
    <div className="w-full mx-8 pt-1 mt-4 bg-white font-Inter px-5">
      <div>
        <div className="flex flex-col pl-4 pb-4 pr-4">
          <div className="flex justify-between items-center">
            <div>
              <h5 className="text-[24px] font-SplineSans text-left font-medium pb-2">
                Create a New Product
              </h5>
            </div>
            <div></div>
          </div>

          <div className="flex items-center">
            <Link to={"/merchant/dashboard"}>
              <h6 className="text-[14px] font-Inter font-medium text-blue-600 hover:text-blue-950 pr-2">
                Dashboard
              </h6>
            </Link>
            <FaAngleRight color="gray" />
            <Link to={"/merchant/products"}>
              <h6 className="text-[14px] font-Inter font-medium text-blue-600 hover:text-blue-950 px-2">
                Product List
              </h6>
            </Link>
            <FaAngleRight color="gray" />
            <h6 className="text-[14px] font-Inter font-medium text-gray-400 pl-2">
              Add Product
            </h6>
          </div>
        </div>

        {/* create product form */}
        <form onSubmit={handleSubmit} className="p-4">
          <div className="flex">
            <div className="p-6 border-2 rounded-xl w-[60%] shadow-purple-400 mr-3">
              <h6 className="font-Inter font-semibold pb-4 ">
                General Information
              </h6>
              <div>
                <label className="pb-2 pt-2 font-Inter text-[0.9rem]">
                  Product Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  required
                  value={name}
                  className="mt-2 appearance-none font-Inter block w-full px-3 py-2 bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                  onChange={handleNameChange}
                  placeholder="Enter product name..."
                />
              </div>
              <p className="pt-2 font-Inter text-[0.8rem] text-blue-700">
                Permalink:{" "}
                <span className="text-gray-500">
                  https://shop.lipable.com/product/{slug}
                </span>
              </p>
              <div className="pt-3">
                <label className="pb-2 font-Inter text-[0.9rem]">
                  Description <span className="text-red-500">*</span>
                </label>
                <ReactQuill
                  value={description}
                  onChange={setDescription}
                  placeholder="Enter your product description..."
                  className="mt-2 appearance-none font-Inter block w-full px-3 py-2 bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                />
              </div>
            </div>
            <div className="p-6 border-2 rounded-xl w-[40%] shadow-purple-400 ml-3">
              <h6 className="font-Inter font-semibold pb-4 ">Product Media</h6>
              <input
                type="file"
                name=""
                id="upload"
                className="hidden"
                multiple
                onChange={handleImageChange}
              />
              <div className="w-full flex items-center flex-wrap mt-5">
                <label htmlFor="upload">
                  <div
                    className="px-1 py-8 bg-gray-100 rounded-lg cursor-pointer flex flex-col items-center mr-1"
                    style={{
                      border: "6px dashed #a99a9a",
                      borderWidth: "1px",
                    }}
                  >
                    <FaRegImage
                      size={24}
                      className="cursor-pointer"
                      color="#555"
                    />
                    <span className="text-[12px] font-Inter font-normal text-gray-500 pt-2 ">
                      Click here to upload
                    </span>
                  </div>
                </label>
                {images &&
                  images.map((i) => (
                    <img
                      src={URL.createObjectURL(i)}
                      key={i}
                      alt=""
                      className="h-[120px] w-[120px] object-cover m-1 bg-gray-100 rounded-lg"
                    />
                  ))}
              </div>
              <div className="flex items-center pt-6">
                <IoInformationCircleOutline size={24} />
                <span className="text-[12px] text-gray-700 leading-4 ml-2">
                  You need at least 4 images. Pay attention to the quality of
                  pictures you add (important)
                </span>
              </div>
            </div>
          </div>
          <div className="flex mt-5">
            <div className="p-6 border-2 rounded-xl w-[60%] shadow-purple-400 mr-3">
              <h6 className="font-Inter font-semibold pb-4 ">Pricing</h6>
              <div>
                <div>
                  <label className="pb-2 font-Inter text-[0.9rem]">
                    Regular Price
                  </label>
                  <input
                    type="number"
                    name="price"
                    prefix={"KES"}
                    value={originalPrice}
                    className="mt-2 appearance-none font-Inter block px-3 py-2 w-full bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                    onChange={(e) => setOriginalPrice(e.target.value)}
                    placeholder="KES 0.00"
                  />
                </div>
                <div className="pt-3">
                  <label className="pb-2 font-Inter text-[0.9rem]">
                    Sale Price (With Discount){" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    name="price"
                    value={discountPrice}
                    className="mt-2 appearance-none font-Inter block px-3 py-2 w-full bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                    onChange={(e) => setDiscountPrice(e.target.value)}
                    placeholder="KES 0.00"
                  />
                </div>
              </div>
            </div>
            <div className="p-6 border-2 rounded-xl w-[40%] shadow-purple-400 ml-3">
              <h6 className="font-Inter font-semibold pb-4 ">Category</h6>
              <div>
                <label className="pb-2 font-Inter text-[0.9rem]">
                  Product Category <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <select
                    className="w-full mt-2 appearance-none font-Inter block px-3 py-2 bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option
                      value=""
                      className="font-Inter text-[0.9rem] bg-white"
                    >
                      Select a category
                    </option>
                    {categoriesData &&
                      categoriesData.map((i) => (
                        <option
                          value={i.title}
                          key={i.title}
                          className="rounded-md font-Inter bg-white hover:bg-red-100"
                        >
                          {i.title}
                        </option>
                      ))}
                  </select>
                  {/* Custom dropdown arrow */}
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <label className="pb-2 font-Inter text-[0.9rem]">
                  Product Tags
                </label>
                <input
                  type="text"
                  name="tags"
                  value={tags}
                  className="mt-2 appearance-none font-Inter block px-3 py-2 w-full bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                  onChange={(e) => setTags(e.target.value)}
                  placeholder="Enter your product tags..."
                />
              </div>
            </div>
          </div>
          <div className="flex mt-5">
            <div className="p-6 border-2 rounded-xl w-[60%] shadow-purple-400 mr-3">
              <h6 className="font-Inter font-semibold pb-4 ">Inventory</h6>
              <div className="flex justify-between">
                <div className="mr-3">
                  <label className="pb-2 font-Inter text-[0.9rem]">
                    Quantity <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    name="price"
                    value={stock}
                    className="mt-2 appearance-none font-Inter block px-3 py-2 w-full bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                    onChange={(e) => setStock(e.target.value)}
                    placeholder="Type product quantity..."
                  />
                </div>
                <div className="mr-3">
                  <label className="pb-2 font-Inter text-[0.9rem]">SKU</label>
                  <input
                    type="number"
                    name="price"
                    value={stock}
                    className="mt-2 appearance-none font-Inter block px-3 py-2 w-full bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                    onChange={(e) => setStock(e.target.value)}
                    placeholder="113902"
                  />
                </div>
                <div>
                  <label className="pb-2 font-Inter text-[0.9rem]">
                    Barcode
                  </label>
                  <input
                    type="number"
                    name="price"
                    value={stock}
                    className="mt-2 appearance-none font-Inter block px-3 py-2 w-full bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                    onChange={(e) => setStock(e.target.value)}
                    placeholder="09812345678"
                  />
                </div>
              </div>
            </div>
            <div className="p-6 ml-3 w-[40%]">
              <div className="flex items-start">
                <button className="group w-full h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter">
                  Cancel
                </button>
                <div className="m-1"></div>
                <button
                  type="submit"
                  className="group w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
                >
                  Add product
                </button>
              </div>
            </div>
          </div>
          <div className="flex mt-5">
            <div className="p-6 border-2 rounded-xl w-[60%] shadow-purple-400 mr-3">
              <h6 className="font-Inter font-semibold pb-4 ">
                Short Description
              </h6>
              <div className="pt-3">
                <label className="pb-2 font-Inter text-[0.9rem]">
                  Short Description
                </label>
                <ReactQuill
                  value={description}
                  onChange={setDescription}
                  placeholder="Enter short product description..."
                  className="mt-2 appearance-none font-Inter block w-full px-3 py-2 bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                />
              </div>
            </div>
            <div className="p-6 ml-3 w-[40%]"></div>
          </div>
          <div className="flex mt-5 mb-10">
            <div className="p-6 border-2 rounded-xl w-[60%] shadow-purple-400 mr-3">
              <h6 className="font-Inter font-semibold pb-4 ">Variant</h6>
              <Tabs value={value} onChange={handleChange}>
                <Tab
                  label={
                    <span className="font-Inter text-[0.9rem] capitalize">
                      Apparel
                    </span>
                  }
                />
                <Tab
                  label={
                    <span className="font-Inter text-[0.9rem] capitalize">
                      Electronics
                    </span>
                  }
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <div className="mr-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Brand
                      </label>
                      <div className="relative">
                        <select
                          className="w-[310px] mt-2 appearance-none font-Inter block px-3 py-2 bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option
                            value=""
                            className="font-Inter text-[0.9rem] bg-white"
                          >
                            Select brand
                          </option>
                          {apparelData &&
                            apparelData.map((i) => (
                              <option
                                value={i.title}
                                key={i.title}
                                className="rounded-md font-Inter bg-white hover:bg-red-100"
                              >
                                {i.title}
                              </option>
                            ))}
                        </select>
                        {/* Custom dropdown arrow */}
                        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                          <svg
                            className="w-4 h-4 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="mr-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Cloth Size
                      </label>
                      <div className="w-[310px] mt-2">
                        <Multiselect
                          options={clothSizeData} // Options to display in the dropdown
                          selectedValues={clothSize} // Preselected value to persist in dropdown
                          onSelect={handleSelect} // Function will trigger on select event
                          onRemove={handleSelect} // Function will trigger on remove event
                          displayValue="title" // Property name to display in the dropdown options
                          placeholder="Select sizes"
                          showCheckbox
                          style={{
                            chips: { background: "#BF00FF" },
                            multiselectContainer: {
                              color: "#000",
                              fontSize: "0.875rem",
                              fontFamily: "inter",
                            },
                            searchBox: {
                              border: "2px solid #e5e7eb",
                              backgroundColor: "#f3f4f6",
                              borderRadius: "0.375rem",
                              paddingLeft: "0.75rem",
                              paddingRight: "0.75rem",
                              paddingTop: "0.4rem",
                              paddingBottom: "0.4rem",
                              fontFamily: "inter",
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between mt-2">
                    <div className="mr-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Color
                      </label>
                      <div className="w-[310px] mt-2">
                        <Multiselect
                          options={colorData} // Options to display in the dropdown
                          selectedValues={color} // Preselected value to persist in dropdown
                          onSelect={handleSelect} // Function will trigger on select event
                          onRemove={handleSelect} // Function will trigger on remove event
                          displayValue="color" // Property name to display in the dropdown options
                          placeholder="Select colors"
                          showCheckbox
                          style={{
                            chips: { background: "#BF00FF" },
                            multiselectContainer: {
                              color: "#000",
                              fontSize: "0.875rem",
                              fontFamily: "inter",
                            },
                            searchBox: {
                              border: "2px solid #e5e7eb",
                              backgroundColor: "#f3f4f6",
                              borderRadius: "0.375rem",
                              paddingLeft: "0.75rem",
                              paddingRight: "0.75rem",
                              paddingTop: "0.4rem",
                              paddingBottom: "0.4rem",
                              fontFamily: "inter",
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="mr-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Shoe Size(s)
                      </label>
                      <div className="w-[310px] mt-2">
                        <Multiselect
                          options={shoeSizeData} // Options to display in the dropdown
                          selectedValues={shoeSize} // Preselected value to persist in dropdown
                          onSelect={handleSelect} // Function will trigger on select event
                          onRemove={handleSelect} // Function will trigger on remove event
                          displayValue="size" // Property name to display in the dropdown options
                          placeholder="Select sizes"
                          showCheckbox
                          style={{
                            chips: { background: "#BF00FF" },
                            multiselectContainer: {
                              color: "#000",
                              fontSize: "0.875rem",
                              fontFamily: "inter",
                            },
                            searchBox: {
                              border: "2px solid #e5e7eb",
                              backgroundColor: "#f3f4f6",
                              borderRadius: "0.375rem",
                              paddingLeft: "0.75rem",
                              paddingRight: "0.75rem",
                              paddingTop: "0.4rem",
                              paddingBottom: "0.4rem",
                              fontFamily: "inter",
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between mt-2">
                    <div className="mr-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Target Group (Gender)
                      </label>
                      <div className="relative">
                        <select
                          className="w-[310px] mt-2 appearance-none font-Inter block px-3 py-2 bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option
                            value=""
                            className="font-Inter text-[0.9rem] bg-white"
                          >
                            Select target group
                          </option>
                          {targetGroupData &&
                            targetGroupData.map((i) => (
                              <option
                                value={i.title}
                                key={i.title}
                                className="rounded-md font-Inter bg-white hover:bg-red-100"
                              >
                                {i.title}
                              </option>
                            ))}
                        </select>
                        {/* Custom dropdown arrow */}
                        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                          <svg
                            className="w-4 h-4 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="flex justify-between">
                  <div className="mr-3">
                    <label className="pb-2 font-Inter text-[0.9rem]">
                      Brand
                    </label>
                    <input
                      type="number"
                      name="price"
                      value={stock}
                      className="mt-2 appearance-none font-Inter block px-3 py-2 w-full bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                      onChange={(e) => setStock(e.target.value)}
                      placeholder="Size in Inches"
                    />
                  </div>
                </div>
              </TabPanel>

              <div className="flex justify-between">
                <div className="mr-3">
                  <label className="pb-2 font-Inter text-[0.9rem]">
                    Screen Size
                  </label>
                  <input
                    type="number"
                    name="price"
                    value={stock}
                    className="mt-2 appearance-none font-Inter block px-3 py-2 w-full bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                    onChange={(e) => setStock(e.target.value)}
                    placeholder="Size in Inches"
                  />
                </div>
                <div className="mr-3">
                  <label className="pb-2 font-Inter text-[0.9rem]">RAM</label>
                  <input
                    type="number"
                    name="price"
                    value={stock}
                    className="mt-2 appearance-none font-Inter block px-3 py-2 w-full bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                    onChange={(e) => setStock(e.target.value)}
                    placeholder="RAM Capacity in GB"
                  />
                </div>
                <div>
                  <label className="pb-2 font-Inter text-[0.9rem]">
                    Storage Capacity
                  </label>
                  <div className="mt-2">
                    <Multiselect
                      options={storageData} // Options to display in the dropdown
                      selectedValues={storageSize} // Preselected value to persist in dropdown
                      onSelect={handleSelect} // Function will trigger on select event
                      onRemove={handleSelect} // Function will trigger on remove event
                      displayValue="size" // Property name to display in the dropdown options
                      placeholder="Select capacity"
                      showCheckbox
                      style={{
                        chips: { background: "#BF00FF" },
                        multiselectContainer: {
                          color: "#000",
                          fontSize: "0.875rem",
                          fontFamily: "inter",
                        },
                        searchBox: {
                          border: "2px solid #e5e7eb",
                          backgroundColor: "#f3f4f6",
                          borderRadius: "0.375rem",
                          paddingLeft: "0.75rem",
                          paddingRight: "0.75rem",
                          paddingTop: "0.4rem",
                          paddingBottom: "0.4rem",
                          fontFamily: "inter",
                          fontSize: "0.875rem",
                        },
                      }}
                    />
                  </div>

                  {/* <div className="relative w-[210px]">
                    <select
                      className="w-full mt-2 appearance-none font-Inter block px-3 py-2 bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                      value={storageSize}
                      onChange={(e) => setStorageSize(e.target.value)}
                    >
                      <option
                        value=""
                        className="font-Inter text-[0.9rem] bg-white w-full block"
                      >
                        Select capacity
                      </option>
                      {storageData &&
                        storageData.map((i) => (
                          <option
                            value={i.size}
                            key={i.size}
                            className="rounded-md font-Inter bg-white hover:bg-red-100 w-full"
                          >
                            {i.size}
                          </option>
                        ))}
                    </select>

                    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="flex justify-between pt-3">
                <div className="mr-3">
                  <label className="pb-2 font-Inter text-[0.9rem]">Size</label>
                  <input
                    type="number"
                    name="price"
                    value={stock}
                    className="mt-2 appearance-none font-Inter block px-3 py-2 w-full bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                    onChange={(e) => setStock(e.target.value)}
                    placeholder="Enter the size"
                  />
                </div>
                <div className="mr-3">
                  <label className="pb-2 font-Inter text-[0.9rem]">Color</label>
                  <input
                    type="number"
                    name="price"
                    value={stock}
                    className="mt-2 appearance-none font-Inter block px-3 py-2 w-full bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                    onChange={(e) => setStock(e.target.value)}
                    placeholder="RAM Capacity in GB"
                  />
                </div>
                <div>
                  <label className="pb-2 font-Inter text-[0.9rem]">
                    Storage
                  </label>
                  <input
                    type="number"
                    name="price"
                    value={stock}
                    className="mt-2 appearance-none font-Inter block px-3 py-2 w-full bg-gray-100 border-gray-200 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                    onChange={(e) => setStock(e.target.value)}
                    placeholder="Specify the storage capacity"
                  />
                </div>
              </div>
            </div>
            <div className="p-6 ml-3 w-[40%]"></div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateProduct;
