import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { loadSeller } from "../../redux/actions/user";
import { AiOutlineDelete } from "react-icons/ai";
import { formatCurrency } from "../../Utils/formatCurrency";
import {
  IoClose,
  IoInformationCircleOutline,
  IoLibraryOutline,
  IoTrashOutline,
} from "react-icons/io5";
import SmoothList from "react-smooth-list";

const WithdrawMoney = () => {
  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const dispatch = useDispatch();
  const { seller } = useSelector((state) => state.seller);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(50);
  const [bankInfo, setBankInfo] = useState({
    bankName: "",
    bankCountry: "",
    bankSwiftCode: null,
    bankAccountNumber: null,
    bankHolderName: "",
    bankAddress: "",
  });

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const withdrawMethod = {
      bankName: bankInfo.bankName,
      bankCountry: bankInfo.bankCountry,
      bankSwiftCode: bankInfo.bankSwiftCode,
      bankAccountNumber: bankInfo.bankAccountNumber,
      bankHolderName: bankInfo.bankHolderName,
      bankAddress: bankInfo.bankAddress,
    };

    setPaymentMethod(false);

    await axios
      .put(
        `${server}/shop/update-payment-methods`,
        {
          withdrawMethod,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Withdraw method added successfully!");
        dispatch(loadSeller());
        setBankInfo({
          bankName: "",
          bankCountry: "",
          bankSwiftCode: null,
          bankAccountNumber: null,
          bankHolderName: "",
          bankAddress: "",
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const deleteHandler = async () => {
    await axios
      .delete(`${server}/shop/delete-withdraw-method`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success("Withdraw method deleted successfully!");
        dispatch(loadSeller());
      });
  };

  const error = () => {
    toast.error("You not have enough balance to withdraw!");
  };

  const withdrawHandler = async () => {
    if (withdrawAmount < 50 || withdrawAmount > availableBalance) {
      toast.error("You can't withdraw this amount!");
    } else {
      const amount = withdrawAmount;
      await axios
        .post(
          `${server}/withdraw/create-withdraw-request`,
          { amount },
          { withCredentials: true }
        )
        .then((res) => {
          toast.success("Withdraw money request is successful!");
        });
    }
  };

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setClosing(false);
    }, 300);
  };

  const availableBalance = seller?.availableBalance.toFixed(2);

  return (
    <div className="w-full p-8 px-12">
      <div className="w-full bg-white h-full rounded flex items-start justify-start flex-col">
        <div className="w-full mb-4 800px:w-[32%] min-h-[20vh] bg-purple-100 rounded-lg px-2 py-5">
          <h2 className="pt-4 pl-[20px] text-[24px] font-[600] font-Inter">
            {formatCurrency(availableBalance)}
          </h2>
          <div className="block items-center pl-[20px] pt-2">
            <h5
              className={`${styles.productTitle} !text-[14px] leading-5 !font-[500] text-[#00000085] font-Inter`}
            >
              Account Balance{" "}
            </h5>
            <span className="text-[12px] font-Inter text-[#5a5a5a]">
              (with 10% service charge)
            </span>
          </div>
        </div>
        <button
          onClick={() => (availableBalance < 50 ? error() : setOpen(true))}
          className="group relative w-[150px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#130f0c] hover:bg-[#4d4c4c] font-Inter"
        >
          Withdraw
        </button>
      </div>
      {open && (
        <div
          className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
            closing ? "pointer-events-none" : ""
          }`}
          onClick={closeMenu}
        >
          <div
            className={`side-menu ${closing ? "closed" : ""}`}
            onClick={(e) => e.stopPropagation()}
            style={{
              marginTop: "10px",
              marginRight: "15px",
              borderRadius: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className="w-full flex justify-end p-4">
              <IoClose
                size={24}
                className="cursor-pointer"
                onClick={closeMenu}
              />
            </div>
            {paymentMethod ? (
              <div className="px-3">
                <SmoothList delay={200} transitionDuration={600} visible>
                  <h3 className="text-[20px] text-start font-[600] font-Inter pb-6">
                    Add Withdraw Method
                  </h3>
                </SmoothList>

                <form onSubmit={handleSubmit}>
                  <SmoothList delay={300} transitionDuration={600} visible>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 font-Inter">
                        Bank Name{" "}
                        <span className="text-red-500 font-Inter">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        value={bankInfo.bankName}
                        onChange={(e) =>
                          setBankInfo({ ...bankInfo, bankName: e.target.value })
                        }
                        id=""
                        placeholder="Bank name"
                        className="appearance-none font-Inter mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={400} transitionDuration={600} visible>
                    <div className="pt-2">
                      <label className="block text-sm font-medium text-gray-700 font-Inter">
                        Bank Country{" "}
                        <span className="text-red-500 font-Inter">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        value={bankInfo.bankCountry}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankCountry: e.target.value,
                          })
                        }
                        id=""
                        required
                        placeholder="Country"
                        className="appearance-none font-Inter mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={500} transitionDuration={600} visible>
                    <div className="pt-2">
                      <label className="block text-sm font-medium text-gray-700 font-Inter">
                        Bank Swift Code{" "}
                        <span className="text-red-500 font-Inter">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        id=""
                        required
                        value={bankInfo.bankSwiftCode}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankSwiftCode: e.target.value,
                          })
                        }
                        placeholder="Bank swift code"
                        className="appearance-none font-Inter mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={600} transitionDuration={600} visible>
                    <div className="pt-2">
                      <label className="block text-sm font-medium text-gray-700 font-Inter">
                        Bank Account Number{" "}
                        <span className="text-red-500 font-Inter">*</span>
                      </label>
                      <input
                        type="number"
                        name=""
                        id=""
                        value={bankInfo.bankAccountNumber}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankAccountNumber: e.target.value,
                          })
                        }
                        required
                        placeholder="Bank account number"
                        className="appearance-none font-Inter mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={700} transitionDuration={600} visible>
                    <div className="pt-2">
                      <label className="block text-sm font-medium text-gray-700 font-Inter">
                        Bank Holder Name{" "}
                        <span className="text-red-500 font-Inter">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        value={bankInfo.bankHolderName}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankHolderName: e.target.value,
                          })
                        }
                        id=""
                        placeholder="Bank holder name"
                        className="appearance-none font-Inter mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={800} transitionDuration={600} visible>
                    <div className="pt-2">
                      <label className="block text-sm font-medium text-gray-700 font-Inter">
                        Bank Address{" "}
                        <span className="text-red-500 font-Inter">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        id=""
                        value={bankInfo.bankAddress}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankAddress: e.target.value,
                          })
                        }
                        placeholder="Bank address"
                        className="appearance-none font-Inter mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={900} transitionDuration={600} visible>
                    <button
                      type="submit"
                      className="group relative w-[100px] h-[40px] mt-6 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#130f0c] hover:bg-[#4d4c4c] font-Inter"
                    >
                      Add
                    </button>
                  </SmoothList>
                </form>
              </div>
            ) : (
              <>
                <h3 className="pb-5 text-[20px] font-Inter flex items-center justify-center">
                  Available Withdraw Methods
                </h3>
                {seller && seller?.withdrawMethod ? (
                  <div className="px-4">
                    <h4 className="font-Inter text-center ">
                      Account: {formatCurrency(availableBalance)}
                    </h4>
                    <br />
                    <div className="w-full mb-4 800px:w-[100%] bg-blue-50 rounded-lg px-5 py-7 flex justify-between">
                      <div className="flex justify-start flex-col">
                        <h5 className="font-Inter">
                          Account Number:{" "}
                          {"*".repeat(
                            seller?.withdrawMethod.bankAccountNumber.length - 3
                          ) +
                            seller?.withdrawMethod.bankAccountNumber.slice(-3)}
                        </h5>
                        <h5 className="font-Inter">
                          Bank Name: {seller?.withdrawMethod.bankName}
                        </h5>
                      </div>
                      <div>
                        <IoTrashOutline
                          size={25}
                          className="cursor-pointer"
                          onClick={() => deleteHandler()}
                        />
                      </div>
                    </div>
                    <br />

                    <div className="800px:flex w-full items-center flex-col">
                      <input
                        type="number"
                        placeholder="Amount..."
                        value={withdrawAmount}
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                        className="appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                      />
                      <button
                        onClick={withdrawHandler}
                        className="group relative w-full h-[40px] flex mt-3 justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#130f0c] hover:bg-[#4d4c4c] font-Inter"
                      >
                        Withdraw
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="p-5">
                    <div className="flex items-center justify-center flex-col">
                      <IoInformationCircleOutline size={30} />
                      <p className="text-[12px] pt-2 font-Inter">
                        No Withdraw Methods available!
                      </p>
                      <div className="pt-5">
                        <button
                          onClick={() => setPaymentMethod(true)}
                          className="group relative w-[250px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#130f0c] hover:bg-[#4d4c4c] font-Inter"
                        >
                          Add withdraw method
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {/* {open && (
        <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
          <div
            className={`w-[95%] 800px:w-[50%] bg-white shadow rounded ${
              paymentMethod ? "h-[80vh] overflow-y-scroll" : "h-[unset]"
            } min-h-[40vh] p-3`}
          >
            <div className="w-full flex justify-end">
              <IoClose
                size={25}
                onClick={() => setOpen(false) || setPaymentMethod(false)}
                className="cursor-pointer"
              />
            </div>
            {paymentMethod ? (
              <div>
                <h3 className="text-[22px] font-Poppins text-center font-[600] font-Inter">
                  Add new Withdraw Method:
                </h3>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label>
                      Bank Name{" "}
                      <span className="text-red-500 font-Inter">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      required
                      value={bankInfo.bankName}
                      onChange={(e) =>
                        setBankInfo({ ...bankInfo, bankName: e.target.value })
                      }
                      id=""
                      placeholder="Enter your Bank name!"
                      className={`${styles.input} mt-2`}
                    />
                  </div>
                  <div className="pt-2">
                    <label>
                      Bank Country{" "}
                      <span className="text-red-500 font-Inter">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      value={bankInfo.bankCountry}
                      onChange={(e) =>
                        setBankInfo({
                          ...bankInfo,
                          bankCountry: e.target.value,
                        })
                      }
                      id=""
                      required
                      placeholder="Enter your bank Country!"
                      className={`${styles.input} mt-2`}
                    />
                  </div>
                  <div className="pt-2">
                    <label>
                      Bank Swift Code{" "}
                      <span className="text-red-500 font-Inter">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      id=""
                      required
                      value={bankInfo.bankSwiftCode}
                      onChange={(e) =>
                        setBankInfo({
                          ...bankInfo,
                          bankSwiftCode: e.target.value,
                        })
                      }
                      placeholder="Enter your Bank Swift Code!"
                      className={`${styles.input} mt-2`}
                    />
                  </div>

                  <div className="pt-2">
                    <label>
                      Bank Account Number{" "}
                      <span className="text-red-500 font-Inter">*</span>
                    </label>
                    <input
                      type="number"
                      name=""
                      id=""
                      value={bankInfo.bankAccountNumber}
                      onChange={(e) =>
                        setBankInfo({
                          ...bankInfo,
                          bankAccountNumber: e.target.value,
                        })
                      }
                      required
                      placeholder="Enter your bank account number!"
                      className={`${styles.input} mt-2`}
                    />
                  </div>
                  <div className="pt-2">
                    <label>
                      Bank Holder Name{" "}
                      <span className="text-red-500 font-Inter">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      required
                      value={bankInfo.bankHolderName}
                      onChange={(e) =>
                        setBankInfo({
                          ...bankInfo,
                          bankHolderName: e.target.value,
                        })
                      }
                      id=""
                      placeholder="Enter your bank Holder name!"
                      className={`${styles.input} mt-2`}
                    />
                  </div>

                  <div className="pt-2">
                    <label>
                      Bank Address{" "}
                      <span className="text-red-500 font-Inter">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      required
                      id=""
                      value={bankInfo.bankAddress}
                      onChange={(e) =>
                        setBankInfo({
                          ...bankInfo,
                          bankAddress: e.target.value,
                        })
                      }
                      placeholder="Enter your bank address!"
                      className={`${styles.input} mt-2`}
                    />
                  </div>

                  <button
                    type="submit"
                    className={`${styles.button} mb-3 text-white font-Inter`}
                  >
                    Add
                  </button>
                </form>
              </div>
            ) : (
              <>
                <h3 className="text-[22px] font-Inter">
                  Available Withdraw Methods:
                </h3>

                {seller && seller?.withdrawMethod ? (
                  <div>
                    <div className="800px:flex w-full justify-between items-center font-Inter">
                      <div className="800px:w-[50%]">
                        <h5>
                          Account Number:{" "}
                          {"*".repeat(
                            seller?.withdrawMethod.bankAccountNumber.length - 3
                          ) +
                            seller?.withdrawMethod.bankAccountNumber.slice(-3)}
                        </h5>
                        <h5>Bank Name: {seller?.withdrawMethod.bankName}</h5>
                      </div>
                      <div className="800px:w-[50%]">
                        <AiOutlineDelete
                          size={25}
                          className="cursor-pointer"
                          onClick={() => deleteHandler()}
                        />
                      </div>
                    </div>
                    <br />
                    <h4>
                      Available Balance: {formatCurrency(availableBalance)}
                    </h4>
                    <br />
                    <div className="800px:flex w-full items-center">
                      <input
                        type="number"
                        placeholder="Amount..."
                        value={withdrawAmount}
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                        className="800px:w-[100px] w-[full] border 800px:mr-3 p-1 rounded"
                      />
                      <div
                        className={`${styles.button} !h-[42px] text-white`}
                        onClick={withdrawHandler}
                      >
                        Withdraw
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="text-[18px] pt-2">
                      No Withdraw Methods available!
                    </p>
                    <div className="w-full flex items-center">
                      <div
                        className={`${styles.button} text-[#fff] text-[18px] mt-4`}
                        onClick={() => setPaymentMethod(true)}
                      >
                        Add new
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )} */}
    </div>
  );
};

export default WithdrawMoney;
