import axios from "axios";
import { server } from "../../server";

// create sacco
export const createSacco = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "saccoCreateRequest",
    });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${server}/sacco/create-sacco`,
      newForm,
      config
    );
    dispatch({
      type: "saccoCreateSuccess",
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: "saccoCreateFail",
      payload: error.response.data.message,
    });
  }
};

// get all saccos --- admin
export const getAllSaccos = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllSaccosRequest",
    });

    const { data } = await axios.get(`${server}/sacco/admin-all-saccos`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllSaccosSuccess",
      payload: data.saccos,
    });
  } catch (error) {
    dispatch({
      type: "getAllSaccosFailed",
      payload: error.response ? error.response.data.message : error.message,
    });
  }
};
