import React from "react";
import SaccoDashboardHeader from "../../components/Sacco/Layout/SaccoDashboardHeader";
import SaccoDashboardSideBar from "../../components/Sacco/Layout/SaccoDashboardSideBar";
import SaccoSettings from "../../components/Sacco/SaccoSettings";

const SaccoSettingsPage = () => {
  return (
    <div>
      <div className="fixed ml-[250px]" style={{ width: "calc(100% - 250px)" }}>
        <SaccoDashboardHeader />
      </div>
      <div className="flex justify-between w-full">
        <div className="w-[80px] 800px:w-[250px] fixed">
          <SaccoDashboardSideBar active={4} />
        </div>
        <div
          className="w-full justify-center flex ml-[250px] bg-white mt-[70px]"
          style={{ width: "calc(100% - 250px)" }}
        >
          <SaccoSettings />
        </div>
      </div>
    </div>
  );
};

export default SaccoSettingsPage;
