import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Button, Avatar } from "@material-ui/core";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { server } from "../../server";
import { toast } from "react-toastify";
import { getAllSellers } from "../../redux/actions/sellers";
import { Link } from "react-router-dom";
import { backend_url } from "../../server";
import {
  IoClose,
  IoCloseOutline,
  IoOpenOutline,
  IoTrashOutline,
} from "react-icons/io5";
import SmoothList from "react-smooth-list";
import { formatCurrency } from "../../Utils/formatCurrency";

const AllSellers = () => {
  const dispatch = useDispatch();
  const { sellers } = useSelector((state) => state.seller);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    dispatch(getAllSellers());
  }, [dispatch]);

  const handleDelete = async (id) => {
    await axios
      .delete(`${server}/shop/delete-seller/${id}`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
      });

    dispatch(getAllSellers());
  };

  const openMenu = () => {
    setOpen(true);
  };

  const openUser = (user) => {
    setSelectedUser(user);
  };

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setSelectedUser(null);
      setClosing(false);
    }, 300);
  };

  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part.charAt(0)).join("");
    return initials.toUpperCase();
  };

  const columns = [
    {
      field: "id",
      headerName: "Merchant ID",
      minWidth: 120,
      flex: 0.6,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            #{params.row.id.slice(0, 8).toUpperCase()}
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "Name",
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        const shop = params.row;
        const avatarUrl = shop.avatar ? `${backend_url}${shop.avatar}` : null;
        const initials = shop.name
          .split(" ")
          .map((n) => n[0])
          .join("");

        return (
          <div className="flex items-center ml-2">
            <Avatar
              src={avatarUrl} // Use the avatar URL if available
              alt={shop.name}
              style={{
                width: "34px",
                height: "34px",
                marginRight: "10px",
                borderRadius: "50px",
                backgroundColor: "#dfdfdf",
                padding: "5px",
              }}
            >
              {!avatarUrl && initials} {/* Display initials if no avatar */}
            </Avatar>
            <span>{shop.name}</span>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "address",
      headerName: "Merchant Address",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "joinedAt",
      headerName: "Created On",
      type: "number",
      minWidth: 100,
      flex: 0.7,
    },
    {
      field: "Preview",
      flex: 0.4,
      minWidth: 50,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => openUser(params.row)}
              className="group relative w-full h-[35px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-[50px] text-gray-700 bg-transparent hover:bg-gray-100 font-Inter"
            >
              <IoOpenOutline size={20} />
            </button>
          </>
        );
      },
    },
    {
      field: " ",
      flex: 0.3,
      minWidth: 30,
      headerName: " ",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => setUserId(params.id) || setOpen(true)}
              className="bg-transparent px-3"
            >
              <IoTrashOutline size={20} />
            </button>
          </>
        );
      },
    },
  ];

  const row = [];
  sellers &&
    sellers.forEach((item) => {
      row.push({
        id: item._id,
        name: item?.name,
        email: item?.email,
        avatar: item?.avatar,
        role: item?.role,
        zipCode: item?.zipCode,
        description: item?.description,
        phoneNumber: item?.phoneNumber,
        address: item?.address,
        availableBalance: formatCurrency(item?.availableBalance),
        transactions: item?.transections,
        withdrawMethod: item?.withdrawMethod,
        joinedAt: format(parseISO(item.createdAt), "dd MMM yyyy"),
        address: item.address,
      });
    });

  return (
    <div className="w-full px-8 pt-[80px] bg-white font-Inter h-[100vh]">
      <div>
        <div className="flex justify-between">
          <h3 className="text-[24px] font-Inter pb-10 font-semibold">
            Merchants
          </h3>
        </div>
        <div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            style={{ fontFamily: "Inter", color: "#000" }}
          />
        </div>
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[25%] min-h-[20vh] bg-white rounded-xl shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <IoCloseOutline size={20} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[18px] text-center py-5 font-Inter text-[#000000cb]">
                Are you sure you wanna delete this Merchant?
              </h3>
              <div className="w-full flex items-center justify-center">
                <button
                  onClick={() => setOpen(false)}
                  className="group relative w-[150px] h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                >
                  Cancel
                </button>
                <div className="m-1"></div>
                <button
                  onClick={() => setOpen(false) || handleDelete(userId)}
                  className="group relative font-Inter w-[170px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#ff0015] hover:bg-[#000]"
                >
                  Confirm Deletion
                </button>
              </div>
            </div>
          </div>
        )}

        {selectedUser && (
          <div
            className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
              closing ? "pointer-events-none" : ""
            }`}
            onClick={closeMenu}
          >
            <div
              className={`side-menu ${closing ? "closed" : ""}`}
              onClick={(e) => e.stopPropagation()}
              style={{
                marginTop: "10px",
                marginRight: "15px",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="w-full flex justify-end p-4">
                <IoClose
                  size={24}
                  className="cursor-pointer"
                  onClick={closeMenu}
                />
              </div>
              <div className="px-5">
                <div
                  className="w-full rounded-xl mt-5"
                  style={{ border: "2px solid #eee" }}
                >
                  <SmoothList delay={300}>
                    <div className="flex items-center justify-between px-4 py-4 rounded-lg bg-gray-50 m-2">
                      <div className="flex items-center">
                        {selectedUser.avatar ? (
                          <img
                            src={`${backend_url}${selectedUser.avatar}`}
                            className="w-[80px] h-[80px] rounded-full object-cover"
                            alt="profile img"
                          />
                        ) : (
                          <Avatar
                            alt={selectedUser.name}
                            style={{
                              width: "80px",
                              height: "80px",
                              marginRight: "10px",
                              borderRadius: "50px",
                              backgroundColor: "#dfdfdf",
                            }}
                          >
                            <span className="font-Inter p-2 text-[#000] text-[1.5rem] font-medium">
                              {getInitials(selectedUser.name)}
                            </span>
                          </Avatar>
                        )}
                        <div className="block ml-6 ">
                          <h5 className="font-Inter font-medium text-[1.2rem]">
                            {selectedUser.name}
                          </h5>
                          <h5 className="font-Inter font-normal text-[0.9rem] text-gray-500 capitalize">
                            {selectedUser.role}
                          </h5>
                        </div>
                      </div>
                      <div className="flex"></div>
                    </div>
                  </SmoothList>
                  <div className="block px-4 py-4 ">
                    <div>
                      <SmoothList delay={400}>
                        <div className="w-4/12">
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-2 text-gray-500">
                            Email address
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.email}
                          </h5>
                        </div>
                      </SmoothList>
                      <SmoothList delay={500}>
                        <div>
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-1 text-gray-500">
                            Phone
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            +254{selectedUser.phoneNumber}
                          </h5>
                        </div>
                      </SmoothList>
                    </div>
                    <div>
                      <SmoothList delay={600}>
                        <div className="w-4/12">
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-2 text-gray-500">
                            Address
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.address}
                          </h5>
                        </div>
                      </SmoothList>
                      <SmoothList delay={700}>
                        <div>
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-1 text-gray-500">
                            Postal Code
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.zipCode}
                          </h5>
                        </div>
                      </SmoothList>
                    </div>
                    <div>
                      <SmoothList delay={800}>
                        <div className="w-4/12">
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-2 text-gray-500">
                            Description
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.description}
                          </h5>
                        </div>
                      </SmoothList>
                      <SmoothList delay={900}>
                        <div>
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-1 text-gray-500">
                            Joined On
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.joinedAt}
                          </h5>
                        </div>
                      </SmoothList>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllSellers;
