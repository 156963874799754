import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllUsers } from "../../redux/actions/user";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineDelete } from "react-icons/ai";
import { Button, Avatar } from "@material-ui/core";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { server } from "../../server";
import { toast } from "react-toastify";
import {
  IoClose,
  IoCloseOutline,
  IoOpenOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { backend_url } from "../../server";
import SmoothList from "react-smooth-list";

const AllUsers = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const { saccos } = useSelector((state) => state.sacco);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleDelete = async (id) => {
    await axios
      .delete(`${server}/user/delete-user/${id}`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          theme: "light",
        });
      });

    dispatch(getAllUsers());
  };

  const openMenu = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setSelectedUser(null);
      setClosing(false);
    }, 300);
  };

  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part.charAt(0)).join("");
    return initials.toUpperCase();
  };

  // Find the sacco name by saccoId
  const getSaccoName = (saccoId) => {
    const sacco = saccos.find((s) => s._id === saccoId);
    return sacco ? sacco.name : "N/A";
  };

  const columns = [
    {
      field: "id",
      headerName: "User ID",
      minWidth: 80,
      flex: 0.4,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            #{params.row.id.slice(0, 8).toUpperCase()}
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "Name",
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        const user = params.row;
        const avatarUrl = user.avatar ? `${backend_url}${user.avatar}` : null;
        const initials = user.name
          .split(" ")
          .map((n) => n[0])
          .join("");

        return (
          <div className="flex items-center ml-2">
            <Avatar
              src={avatarUrl} // Use the avatar URL if available
              alt={user.name}
              style={{
                width: "34px",
                height: "34px",
                marginRight: "10px",
                borderRadius: "50px",
                backgroundColor: "#dfdfdf",
              }}
            >
              <span className="font-Inter p-2 text-[#000] text-[0.8rem] font-medium">
                {!avatarUrl && initials}
              </span>{" "}
              {/* Display initials if no avatar */}
            </Avatar>
            <span>{user.name}</span>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "role",
      headerName: "User Role",
      type: "text",
      minWidth: 100,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div>
            <span
              style={{
                fontSize: "12px",
                padding: "4px 8px 4px 8px",
                backgroundColor: "#f6e1f9",
                borderRadius: "20px",
                color: "#bf00ff",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              {params.row.role}
            </span>
          </div>
        );
      },
    },

    {
      field: "joinedAt",
      headerName: "Date Joined",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },
    {
      field: "Preview",
      flex: 0.4,
      minWidth: 50,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => openMenu(params.row)}
              className="group relative w-full h-[35px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-[50px] text-gray-700 bg-transparent hover:bg-gray-100 font-Inter"
            >
              <IoOpenOutline size={20} />
            </button>
          </>
        );
      },
    },
    {
      field: " ",
      flex: 0.2,
      minWidth: 40,
      headerName: " ",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => setUserId(params.id) || setOpenDelete(true)}
              className="bg-transparent"
            >
              <IoTrashOutline size={24} />
            </button>
          </>
        );
      },
    },
  ];

  const row = [];
  users &&
    users.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        email: item.email,
        role: item.role,
        avatar: item.avatar,
        phoneNumber: item.phoneNumber,
        membershipId: item.membershipId,
        saccoId: item.saccoId,
        joinedAt: format(parseISO(item.createdAt), "dd MMM yyyy"),
      });
    });

  return (
    <div className="w-full px-8  pt-[80px] bg-white font-Inter h-[100vh]">
      <div>
        <div className="flex justify-between">
          <h3 className="text-[24px] font-Inter pb-10 font-semibold">
            Customers
          </h3>
        </div>
        <div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            style={{ fontFamily: "Inter", color: "#000" }}
          />
        </div>
        {/* Delete User */}
        {openDelete && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[25%] min-h-[20vh] bg-white rounded-xl shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <IoCloseOutline
                  size={20}
                  onClick={() => setOpenDelete(false)}
                />
              </div>
              <h3 className="text-[16px] text-center py-5 font-Inter text-[#000000cb]">
                Are you sure you wanna delete this user?
              </h3>
              <div className="w-full flex items-center justify-center">
                <button
                  onClick={() => setOpenDelete(false)}
                  className="group relative w-[150px] h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                >
                  Cancel
                </button>
                <div className="m-1"></div>
                <button
                  onClick={() => setOpenDelete(false) || handleDelete(userId)}
                  className="group relative font-Inter w-[170px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#ff0015] hover:bg-[#000]"
                >
                  Confirm Deletion
                </button>
              </div>
            </div>
          </div>
        )}
        {/* Preview User */}
        {selectedUser && (
          <div
            className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
              closing ? "pointer-events-none" : ""
            }`}
            onClick={closeMenu}
          >
            <div
              className={`side-menu ${closing ? "closed" : ""}`}
              onClick={(e) => e.stopPropagation()}
              style={{
                marginTop: "10px",
                marginRight: "15px",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="w-full flex justify-end p-4">
                <IoClose
                  size={24}
                  className="cursor-pointer"
                  onClick={closeMenu}
                />
              </div>
              <div className="px-5">
                <div
                  className="w-full rounded-xl mt-5"
                  style={{ border: "2px solid #eee" }}
                >
                  <SmoothList delay={300}>
                    <div className="flex items-center justify-between px-4 py-4 rounded-lg bg-gray-50 m-2">
                      <div className="flex items-center">
                        {selectedUser.avatar ? (
                          <img
                            src={`${backend_url}${selectedUser.avatar}`}
                            className="w-[80px] h-[80px] rounded-full object-cover"
                            alt="profile img"
                          />
                        ) : (
                          <Avatar
                            alt={selectedUser.name}
                            style={{
                              width: "80px",
                              height: "80px",
                              marginRight: "10px",
                              borderRadius: "50px",
                              backgroundColor: "#dfdfdf",
                            }}
                          >
                            <span className="font-Inter p-2 text-[#000] text-[1.5rem] font-medium">
                              {getInitials(selectedUser.name)}
                            </span>
                          </Avatar>
                        )}
                        <div className="block ml-6 ">
                          <h5 className="font-Inter font-medium text-[1.2rem]">
                            {selectedUser.name}
                          </h5>
                          <h5 className="font-Inter font-normal text-[0.9rem] text-gray-500 capitalize">
                            {selectedUser.role}
                          </h5>
                        </div>
                      </div>
                      <div className="flex"></div>
                    </div>
                  </SmoothList>

                  <div className="block px-4 py-4 ">
                    <div>
                      <SmoothList delay={400}>
                        <div className="w-4/12">
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-2 text-gray-500">
                            Email address
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.email}
                          </h5>
                        </div>
                      </SmoothList>
                      <SmoothList delay={500}>
                        <div>
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-1 text-gray-500">
                            Phone
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            +254{selectedUser.phoneNumber}
                          </h5>
                        </div>
                      </SmoothList>
                    </div>
                    <div>
                      <SmoothList delay={600}>
                        <div className="w-4/12">
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-2 text-gray-500">
                            Sacco
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.saccoId
                              ? getSaccoName(selectedUser.saccoId)
                              : "N/A"}
                          </h5>
                        </div>
                      </SmoothList>
                      <SmoothList delay={700}>
                        <div>
                          <h5 className="font-Inter font-normal text-[0.9rem] pb-2 text-gray-500">
                            Sacco Member ID
                          </h5>
                          <h5 className="font-Inter font-medium text-[1rem] pb-2">
                            {selectedUser.membershipId
                              ? selectedUser.membershipId
                              : "N/A"}
                          </h5>
                        </div>
                      </SmoothList>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllUsers;
