import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

const Hero = () => {
  useEffect(() => {
    const img = new Image();
    img.src = "https://lipable.s3.amazonaws.com/media/banner.webp";
  }, []);
  return (
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      style={{
        backgroundImage: "url('../banner.webp')",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[73%]`}>
        <h1
          className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#000] font-[600] capitalize font-SplineSans`}
        >
          Buy Now. Pay Later. <br /> powered by your Sacco.
        </h1>
        <p className="pt-5 text-[16px] font-Inter font-[400] text-[#677471] pb-5">
          Shop from a vast network of stores and service providers. <br />{" "}
          Unlock new possibilities and shape a brighter financial future for
          <br />
          Sacco members everywhere
        </p>
        <Link to="/products" className="mt-5">
          <button className="group relative w-[170px] h-[50px] flex justify-center py-3 px-4 border border-transparent text-[16px] font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter">
            Shop Now
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
