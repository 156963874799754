import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight, AiOutlineMoneyCollect } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useParams } from "react-router-dom";
import { MdBorderClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { getAllProductsShop } from "../../redux/actions/product";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { formatCurrency } from "../../Utils/formatCurrency";
import { backend_url, server } from "../../server";
import { format, parseISO } from "date-fns";
import { IoClose, IoEllipsisVerticalSharp } from "react-icons/io5";
import axios from "axios";
import { toast } from "react-toastify";
import { BsFillBagFill } from "react-icons/bs";
import SmoothList from "react-smooth-list";

const DashboardHero = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [status, setStatus] = useState("");

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);

  useEffect(() => {
    if (selectedOrder) {
      setStatus(selectedOrder.status);
    }
  }, [selectedOrder]);

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setClosing(false);
      setSelectedOrder(null);
    }, 300);
  };

  const openMenu = (order) => {
    setSelectedOrder(order);
    setOpen(true);
  };

  const orderUpdateHandler = async () => {
    if (!selectedOrder) {
      console.error("No selected order to update"); // Debugging: Ensure selectedOrder is valid
      return;
    }

    try {
      await axios.put(
        `${server}/order/update-order-status/${selectedOrder.id}`,
        { status },
        { withCredentials: true }
      );
      toast.success("Order updated!");
      window.location.reload();
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update order");
    }
  };

  const refundOrderUpdateHandler = async () => {
    await axios
      .put(
        `${server}/order/order-refund-success/${selectedOrder.id}`,
        {
          status,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Order updated!");
        dispatch(getAllOrdersOfShop(seller._id));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  /*  is calculating the available balance of the seller and rounding it to 2 decimal places. */
  const availableBalance = seller?.availableBalance.toFixed(2);

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      minWidth: 100,
      flex: 0.6,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            #{params.row.id.slice(0, 8).toUpperCase()}
          </div>
        );
      },
    },
    {
      field: "user",
      headerName: "Customer",
      minWidth: 120,
      type: "string",
      flex: 0.6,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.user.name}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.getValue(params.id, "status") === "Delivered"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "cart",
      headerName: "Product",
      minWidth: 130,
      type: "string",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.cart.map((item, index) => (
              <img
                key={index}
                src={`${backend_url}${item.images[0]}`} // assuming you want to display the first image
                alt={item.name}
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "10px",
                  borderRadius: "8px",
                }}
              />
            ))}
          </div>
        );
      },
    },
    {
      field: "itemsQty",
      headerName: "Qty",
      type: "number",
      minWidth: 60,
      flex: 0.4,
    },
    {
      field: "paidAt",
      headerName: "Date",
      type: "number",
      minWidth: 60,
      flex: 0.6,
    },
    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 0.3,
      minWidth: 40,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => openMenu(params.row)}
              className="group relative w-full h-[35px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-[50px] text-gray-700 bg-transparent hover:bg-gray-100 font-Inter"
            >
              <IoEllipsisVerticalSharp size={16} />
            </button>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.reduce((acc, item) => acc + item.qty, 0),
        total: formatCurrency(item.totalPrice),
        status: item.status,
        user: item.user,
        cart: item.cart,
        shippingAddress: item.shippingAddress,
        paymentInfo: item.paymentInfo,
        paidAt: format(parseISO(item.paidAt), "dd MMM yyyy"),
        createdAt: format(parseISO(item.paidAt), "MMMM d, yyyy"),
        timeAt: format(parseISO(item.paidAt), "H:MM aa"),
      });
    });
  return (
    <>
      <div className="w-full pt-[90px] bg-white px-12">
        <h3 className="text-[24px] font-SplineSans pb-10 font-semibold">
          Overview
        </h3>
        <div className="w-full block 800px:flex items-center justify-between">
          <div className="w-full mb-4 800px:w-[24%] min-h-[20vh] bg-purple-100 rounded-lg px-2 py-5">
            <h2 className="pt-4 pl-[20px] text-[24px] font-[600] font-Inter">
              {formatCurrency(availableBalance)}
            </h2>
            <div className="block items-center pl-[20px] pt-2">
              <h5
                className={`${styles.productTitle} !text-[14px] leading-5 !font-[500] text-[#00000085] font-Inter`}
              >
                Account Balance{" "}
              </h5>
              <span className="text-[12px] font-Inter text-[#5a5a5a]">
                (with 10% service charge)
              </span>
            </div>
          </div>

          <div className="w-full mb-4 800px:w-[24%] min-h-[20vh] bg-blue-50 rounded-lg px-2 py-5">
            <h2 className="pt-4 pl-[20px] text-[24px] font-[600] font-Inter">
              {orders && orders.length}
            </h2>
            <div className="block items-center pl-[20px] pt-2">
              <h5
                className={`${styles.productTitle} !text-[14px] leading-5 !font-[500] text-[#00000085] font-Inter`}
              >
                All Orders
              </h5>
            </div>
          </div>

          <div className="w-full mb-4 800px:w-[24%] min-h-[20vh] bg-blue-50 rounded-lg px-2 py-5">
            <h2 className="pt-4 pl-[20px] text-[24px] font-[600] font-Inter">
              {products && products.length}
            </h2>
            <div className="block items-center pl-[20px] pt-2">
              <h5
                className={`${styles.productTitle} !text-[14px] leading-5 !font-[500] text-[#00000085] font-Inter`}
              >
                Total Inventory
              </h5>
            </div>
          </div>

          <div className="w-full mb-4 800px:w-[24%] min-h-[20vh] bg-blue-50 rounded-lg px-2 py-5">
            <h2 className="pt-4 pl-[20px] text-[24px] font-[600] font-Inter">
              {products && products.length}
            </h2>
            <div className="block items-center pl-[20px] pt-2">
              <h5
                className={`${styles.productTitle} !text-[14px] leading-5 !font-[500] text-[#00000085] font-Inter`}
              >
                Total Refunds
              </h5>
            </div>
          </div>
        </div>
        <br />
        <div className="w-full min-h-[45vh] bg-white rounded-lg">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            style={{ fontFamily: "Inter" }}
          />
        </div>
      </div>
      {open && (
        <div
          className={`fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-end transition-opacity ${
            closing ? "opacity-0" : "opacity-100"
          }`}
          onClick={closeMenu}
        >
          <div
            className="bg-white w-80 h-full p-4 overflow-y-auto side-menu-wide"
            onClick={(e) => e.stopPropagation()}
            style={{
              marginTop: "10px",
              marginRight: "15px",
              borderRadius: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className="w-full flex justify-end p-4">
              <IoClose
                size={24}
                className="cursor-pointer"
                onClick={closeMenu}
              />
            </div>
            {selectedOrder && (
              <div className="py-4 bg-white p-10 mb-20">
                <SmoothList delay={300} transitionDuration={600} visible>
                  <div className="w=full flex items-center justify-between">
                    <div className="flex items-center">
                      <h1 className=" text-[20px] font-Inter font-semibold">
                        Order ID:{" "}
                        <span className="pl-1 pr-1 font-semibold text-gray-900">
                          #{selectedOrder.id.slice(0, 8).toUpperCase()}
                        </span>{" "}
                      </h1>
                      <h5 className="text-[#00000084] font-Inter flex items-center">
                        {selectedOrder.status ? (
                          <span
                            style={{
                              fontSize: "12px",
                              padding: "3px 8px 3px 8px",
                              backgroundColor: "#fff0e1",
                              borderRadius: "20px",
                              color: "#ff7f00",
                              fontWeight: 500,
                            }}
                          >
                            {selectedOrder.status}
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "12px",
                              padding: "3px 8px 3px 8px",
                              backgroundColor: "#faf0f0",
                              borderRadius: "20px",
                              color: "red",
                              fontWeight: 500,
                            }}
                          >
                            Not Paid
                          </span>
                        )}
                      </h5>
                    </div>
                  </div>
                </SmoothList>
                <SmoothList delay={400} transitionDuration={600} visible>
                  <div className="w-full flex items-center justify-between pt-1">
                    <p className="text-[#00000084] font-Inter">
                      <span className="text-gray-600 text-[12px]">
                        {selectedOrder.createdAt} at {selectedOrder.timeAt}
                      </span>
                    </p>
                  </div>
                </SmoothList>
                <br />
                <br />
                <SmoothList delay={500} transitionDuration={600} visible>
                  <div className="flex justify-between items-center pb-4">
                    <h5 className="text-[#000000d0] font-Inter  font-medium ">
                      Order Items
                    </h5>
                    <div className="text-right">
                      <h5 className="font-Inter text-[1.2rem]">
                        Total: <strong>{selectedOrder.total}</strong>
                      </h5>
                    </div>
                  </div>
                </SmoothList>
                <SmoothList delay={600} transitionDuration={600} visible>
                  <ul className="rounded-xl bg-[#fbfbfb] p-2 border-2 mb-4">
                    {selectedOrder.cart.map((item, index) => (
                      <div className="w-full flex items-center mb-5">
                        <img
                          src={`${backend_url}/${item.images[0]}`}
                          alt="Product item order img"
                          className="w-[80x] h-[80px] rounded-lg"
                        />
                        <div className="w-full">
                          <h5 className="pl-5 text-[16px] font-Inter">
                            {item.name}
                          </h5>
                          <h5 className="pl-5 text-[16px] text-[#00000091] font-Inter">
                            {formatCurrency(item.discountPrice)} x {item.qty}
                          </h5>
                        </div>
                        {/* {!item.isReviewed && data?.status === "Delivered" ? (
                       <div
                         className={`${styles.button} text-[#fff] font-Inter`}
                         onClick={() => setOpen(true) || setSelectedItem(item)}
                       >
                         Write a review
                       </div>
                     ) : null} */}
                      </div>
                    ))}
                  </ul>
                </SmoothList>

                {/* Shipping Address */}
                <br />
                <SmoothList delay={700} transitionDuration={600} visible>
                  <h5 className="text-[#000000d0] font-Inter pb-4 font-medium ">
                    Delivery Address
                  </h5>
                </SmoothList>
                <SmoothList delay={800} transitionDuration={600} visible>
                  <div className="w-full 800px:flex items-start border-t-2 rounded-xl bg-[#ffffff] p-2 border-2 mb-4">
                    <div className="w-full 800px:w-[60%]">
                      <h4 className=" text-[14px] font-Inter">
                        Address 1: {selectedOrder.shippingAddress.address1}
                      </h4>
                      <h4 className="text-[14px] font-Inter">
                        Address 2: {selectedOrder.shippingAddress.address2}
                      </h4>
                      <h4 className="text-[14px] font-Inter">
                        Country: {selectedOrder.shippingAddress.country}
                      </h4>
                      <h4 className="text-[14px] font-Inter">
                        City: {selectedOrder.shippingAddress.city}
                      </h4>

                      <h4 className="text-[14px] font-Inter">
                        Phone: +254{selectedOrder.user.phoneNumber}
                      </h4>
                    </div>

                    <div className="w-full 800px:w-[40%]">
                      <h4 className="pl-5 text-[16px] font-Inter">
                        Payment Info:
                      </h4>
                      <h4 className="pl-5 text-[16px] font-Inter">
                        Status:{" "}
                        {/* /* The expression `{data?.paymentInfo?.status ? data?.paymentInfo?.status : "Not
                     Paid"}` is a conditional statement that checks if the `status` property exists
                     in the `paymentInfo` object within the `data` object. */}
                        {selectedOrder.paymentInfo.status ? (
                          <span
                            style={{
                              fontSize: "12px",
                              padding: "3px 8px 3px 8px",
                              backgroundColor: "#ebffee",
                              borderRadius: "20px",
                              color: "#05e66f",
                              fontWeight: 500,
                            }}
                          >
                            {selectedOrder.paymentInfo.status}
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "12px",
                              padding: "3px 8px 3px 8px",
                              backgroundColor: "#fff0e1",
                              borderRadius: "20px",
                              color: "#ff7f00",
                              fontWeight: 500,
                            }}
                          >
                            Pending
                          </span>
                        )}
                      </h4>
                    </div>
                  </div>
                </SmoothList>

                <br />
                <SmoothList delay={900} transitionDuration={600} visible>
                  <h5 className="text-[#000000d0] font-Inter pb-4 font-medium ">
                    Order Status
                  </h5>
                </SmoothList>
                <SmoothList delay={1000} transitionDuration={600} visible>
                  {selectedOrder.status !== "Processing refund" &&
                    selectedOrder.status !== "Refund Success" && (
                      <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="w-full mt-2 appearance-none font-Inter block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                      >
                        {[
                          "Processing",
                          "Transferred to delivery partner",
                          "Delivery",
                          "Received",
                          "On the way",
                          "Delivered",
                        ]
                          .slice(
                            [
                              "Processing",
                              "Transferred to delivery partner",
                              "Delivery",
                              "Received",
                              "On the way",
                              "Delivered",
                            ].indexOf(selectedOrder.status)
                          )
                          .map((option, index) => (
                            <option
                              value={option}
                              key={index}
                              className="font-Inter text-[0.9rem]"
                            >
                              {option}
                            </option>
                          ))}
                      </select>
                    )}

                  {selectedOrder.status === "Processing refund" ||
                  selectedOrder.status === "Refund Success" ? (
                    <select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      className="w-full mt-2 appearance-none font-Inter block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                    >
                      {["Processing refund", "Refund Success"]
                        .slice(
                          ["Processing refund", "Refund Success"].indexOf(
                            selectedOrder.status
                          )
                        )
                        .map((option, index) => (
                          <option
                            value={option}
                            key={index}
                            className="font-Inter text-[0.9rem]"
                          >
                            {option}
                          </option>
                        ))}
                    </select>
                  ) : null}

                  <button
                    onClick={
                      selectedOrder.status !== "Processing refund"
                        ? orderUpdateHandler
                        : refundOrderUpdateHandler
                    }
                    className="group relative w-[150px] h-[40px] mt-6 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
                  >
                    Update Status
                  </button>
                </SmoothList>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardHero;
