import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { backend_url, server } from "../../server";
import { AiOutlineCamera, AiOutlineEdit } from "react-icons/ai";
import styles from "../../styles/styles";
import axios from "axios";
import { loadSeller } from "../../redux/actions/user";
import { toast } from "react-toastify";
import {
  IoCamera,
  IoClose,
  IoLockClosedOutline,
  IoLockOpenOutline,
  IoStorefrontOutline,
} from "react-icons/io5";
import { format, parseISO } from "date-fns";
import SmoothList from "react-smooth-list";

const ShopSettings = () => {
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState(seller && seller.name);
  const [description, setDescription] = useState(
    seller && seller.description ? seller.description : ""
  );
  const [address, setAddress] = useState(seller && seller.address);
  const [phoneNumber, setPhoneNumber] = useState(seller && seller.phoneNumber);
  const [zipCode, setZipcode] = useState(seller && seller.zipCode);
  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  const closeMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setOpen(false);
      setClosing(false);
      setOpenPassword(false);
    }, 300);
  };

  const openMenu = (order) => {
    setOpen(true);
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const averageRating = totalRatings / totalReviewsLength || 0;

  // Image updated
  const handleImage = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setAvatar(file);

    const formData = new FormData();

    formData.append("image", e.target.files[0]);

    await axios
      .put(`${server}/shop/update-shop-avatar`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((res) => {
        dispatch(loadSeller());
        toast.success("Avatar updated successfully!");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/shop/update-seller-info`,
        {
          name,
          address,
          zipCode,
          phoneNumber,
          description,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Shop info updated succesfully!");
        dispatch(loadSeller());
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Password is updated");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="w-full bg-white pt-[90px]">
      <h3 className="text-[24px] font-SplineSans pb-10 font-semibold px-12">
        My Shop
      </h3>
      <div className="w-full">
        {/* <div className="w-full flex items-center justify-center">
          <div className="relative">
            <img
              src={
                avatar
                  ? URL.createObjectURL(avatar)
                  : `${backend_url}/${seller.avatar}`
              }
              alt=""
              className="w-[200px] h-[200px] rounded-full cursor-pointer"
            />
            <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[10px] right-[15px]">
              <input
                type="file"
                id="image"
                className="hidden"
                onChange={handleImage}
              />
              <label htmlFor="image">
                <AiOutlineCamera />
              </label>
            </div>
          </div>
        </div> */}
        <div className="w-full px-12">
          <div
            className="flex items-center justify-between px-4 py-4 rounded-xl bg-purple-50"
            style={{ border: "2px solid #eee" }}
          >
            <div className="flex items-center">
              <img
                src={
                  avatar
                    ? URL.createObjectURL(avatar)
                    : `${backend_url}/${seller.avatar}`
                }
                alt=""
                className="w-[80px] h-[80px] rounded-full cursor-pointer"
              />
              <div className="block ml-6 ">
                <h5 className="font-Inter font-medium text-[1.2rem] pb-1">
                  {seller && seller.name}
                </h5>
                <h5 className="font-Inter font-medium text-[0.9rem] text-gray-500">
                  {seller && seller.role}
                </h5>
                <h5 className="font-Inter font-normal text-[0.8rem] text-gray-400">
                  {seller && seller.address}
                </h5>
              </div>
            </div>
            <div className="flex">
              <button
                onClick={() => setOpenPassword(true)}
                className="text-[#fc5252] font-[400] flex justify-center items-center font-Inter text-[14px] w-[120px] h-[30px] border-2 border-[#d3d3d3] text-center rounded-[8px]"
              >
                Password <IoLockOpenOutline />
              </button>
            </div>
          </div>
        </div>
        {openPassword && (
          <div
            className={`fixed inset-0 bg-[#00000062] z-[20000] flex justify-end ${
              closing ? "pointer-events-none" : ""
            }`}
          >
            <div
              className={`side-menu ${closing ? "closed" : ""}`}
              onClick={(e) => e.stopPropagation()}
              style={{
                marginTop: "10px",
                marginRight: "15px",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="w-full flex justify-end p-4">
                <IoClose
                  size={24}
                  className="cursor-pointer"
                  onClick={closeMenu}
                />
              </div>
              <h5 className="text-[18px] font-Inter text-left pl-4 font-medium flex items-center">
                <IoLockClosedOutline size={15} />{" "}
                <span className="pl-2">Change Password</span>
              </h5>
              <div className="w-full">
                <form
                  aria-required
                  onSubmit={passwordChangeHandler}
                  className="p-4"
                >
                  <SmoothList delay={300} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Old password
                      </label>
                      <input
                        type="password"
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={400} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={500} transitionDuration={600} visible>
                    <div className="pt-3">
                      <label className="pb-2 font-Inter text-[0.9rem]">
                        Confirm new password
                      </label>
                      <input
                        type="password"
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={600} transitionDuration={600} visible>
                    <div className="pt-8">
                      <div className="flex">
                        <button
                          onClick={closeMenu}
                          type="reset"
                          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border-2 text-sm font-medium rounded-md text-black bg-[#FFF] hover:bg-[#ececec] font-Inter"
                        >
                          Cancel
                        </button>
                        <div className="m-1"></div>
                        <button
                          type="submit"
                          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter"
                        >
                          Change password
                        </button>
                      </div>
                    </div>
                  </SmoothList>
                </form>
              </div>
            </div>
          </div>
        )}
        <br />

        {/* shop info */}
        <div className="w-full px-12">
          <div
            className="px-4 py-4 rounded-xl bg-purple-50"
            style={{ border: "2px solid #eee" }}
          >
            <div className="flex justify-between">
              <h5 className="font-Inter font-semibold text-[1rem] pb-5">
                Merchant Information
              </h5>
              <button
                onClick={() => openMenu(true)}
                className="text-[#646464] font-[400] flex justify-center items-center font-Inter text-[14px] w-[80px] h-[30px] border-2 border-[#d3d3d3] text-center rounded-[8px]"
              >
                Edit <AiOutlineEdit />
              </button>
            </div>

            <div className="block">
              <div className="flex mb-4">
                <div className="w-4/12">
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Shop Name
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {seller && seller.name}
                  </h5>
                </div>
                <div>
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Email
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {seller && seller.email}
                  </h5>
                </div>
              </div>
              <div className="flex mb-4">
                <div className="w-4/12">
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Description
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {seller && seller.description}
                  </h5>
                </div>
                <div>
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Phone
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    +254 {seller && seller.phoneNumber}
                  </h5>
                </div>
              </div>
              <div className="flex mb-4">
                <div className="w-4/12">
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Shop Address
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {seller && seller.address}
                  </h5>
                </div>
                <div>
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Postal Code
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {seller && seller.zipCode}
                  </h5>
                </div>
              </div>
              <div className="flex mb-4">
                <div className="w-4/12">
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Joined On
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {format(
                      parseISO(seller && seller.createdAt),
                      "dd MMMM yyyy"
                    )}
                  </h5>
                </div>
                <div>
                  <h5 className="font-Inter font-medium text-[0.9rem] pb-1 text-gray-500">
                    Ratings
                  </h5>
                  <h5 className="font-Inter font-medium text-[1rem] pb-1">
                    {averageRating}/5
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        {open && (
          <div
            className={`fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-end transition-opacity ${
              closing ? "opacity-0" : "opacity-100"
            }`}
            onClick={closeMenu}
          >
            <div
              className="bg-white w-80 h-full p-4 overflow-y-auto side-menu"
              onClick={(e) => e.stopPropagation()}
              style={{
                marginTop: "10px",
                marginRight: "15px",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="w-full flex justify-end p-4">
                <IoClose
                  size={24}
                  className="cursor-pointer"
                  onClick={closeMenu}
                />
              </div>
              <h5 className="text-[18px] font-Inter text-left pl-4 font-medium flex items-center">
                <IoStorefrontOutline size={18} />{" "}
                <span className="pl-2">Update Shop</span>
              </h5>
              <SmoothList delay={300} transitionDuration={600} visible>
                <div className="w-full flex items-center justify-center">
                  <div className="relative">
                    <img
                      src={
                        avatar
                          ? URL.createObjectURL(avatar)
                          : `${backend_url}/${seller.avatar}`
                      }
                      alt=""
                      className="w-[100px] h-[100px] rounded-full cursor-pointer"
                    />
                    <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[-1px] right-[-3px]">
                      <input
                        type="file"
                        id="image"
                        className="hidden"
                        onChange={handleImage}
                      />
                      <label htmlFor="image" className="cursor-pointer">
                        <IoCamera />
                      </label>
                    </div>
                  </div>
                </div>
              </SmoothList>
              <div className="w-full px-5">
                <form aria-aria-required={true} onSubmit={updateHandler}>
                  <SmoothList delay={400} transitionDuration={600} visible>
                    <div className="mt-2">
                      <div className="w-full">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          Name
                        </label>
                      </div>
                      <input
                        type="name"
                        placeholder={`${seller.name}`}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={500} transitionDuration={600} visible>
                    <div className="mt-2">
                      <div className="w-full">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          Description
                        </label>
                      </div>
                      <textarea
                        cols="30"
                        required
                        rows="3"
                        type="text"
                        name="description"
                        value={description}
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={`${
                          seller?.description
                            ? seller.description
                            : "Enter your shop description"
                        }`}
                      ></textarea>
                    </div>
                  </SmoothList>
                  <SmoothList delay={600} transitionDuration={600} visible>
                    <div className="mt-2">
                      <div className="w-full">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          Address
                        </label>
                      </div>
                      <input
                        type="name"
                        placeholder={seller?.address}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={700} transitionDuration={600} visible>
                    <div className="mt-2">
                      <div className="w-full">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          Phone Number
                        </label>
                      </div>
                      <input
                        type="number"
                        placeholder={seller?.phoneNumber}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={800} transitionDuration={600} visible>
                    <div className="mt-2">
                      <div className="w-full">
                        <label className="pb-2 font-Inter text-[0.9rem]">
                          Postal Code
                        </label>
                      </div>
                      <input
                        type="number"
                        placeholder={seller?.zipCode}
                        value={zipCode}
                        onChange={(e) => setZipcode(e.target.value)}
                        className="mt-2 appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field bg-gray-50"
                        required
                      />
                    </div>
                  </SmoothList>
                  <SmoothList delay={900} transitionDuration={600} visible>
                    <div className="mt-10 pb-5">
                      <button
                        type="submit"
                        className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter"
                      >
                        Update Shop
                      </button>
                    </div>
                  </SmoothList>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopSettings;
